<div style="height: 60px">
  <app-header></app-header>
</div>
<div>
  <div fxLayoutGap="20px">
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <mat-card class="mat-elevation-z0" id="mat-card" style="background-color: rgba(0,0,0,0);">
        <mat-card-subtitle id="title-big">
          As melhores opções para você!
        </mat-card-subtitle>
      </mat-card>
      <mat-form-field dense id="filterContainer" appearance="outline">
        <mat-label>
          <mat-icon matSuffix>filter_list</mat-icon> Filtrar veículos
        </mat-label>
        <mat-select [(value)]="selected">
          <mat-option (click)="selectFilter(selected)">
            Remover filtros
          </mat-option>
          <mat-option value="high" (click)="selectFilter(selected)">Preços altos</mat-option>
          <mat-option value="low" (click)="selectFilter(selected)">Preços baixos</mat-option>
          <mat-optgroup label="Marcas">
            <mat-option *ngFor="let group of groupFiltered" [value]="group" (click)="selectFilter(selected)">
              {{group}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <h1 id="title-big-bold">
      <strong>Grupo {{titleGroupName}}</strong>
    </h1>
    <p id="title-small">Carros do grupo {{titleGroupName}} possuem {{group?.itensSerie?.join(', ')}}</p>
  </div>
  <div id="div" fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column wrap">
    <mat-card fxFlex="20" class="card" *ngFor="let vehicle of vehiclesFiltered" (click)="choiceVehicle(vehicle)">
      <mat-card-header id="cardTitle" fxLayoutAlign="center center">
        <mat-card-title>{{ vehicle.model }}</mat-card-title>
      </mat-card-header>
      <div class="imgContainer" fxLayoutAlign="center flex-end">
        <img mat-card-image [src]="vehicle.picture" [alt]="vehicle.model" />
      </div>
      <!-- <mat-card-content> -->
      <!-- <div fxLayout="row" fxLayoutAlign="end center">
        <mat-icon id="info" [matTooltip]="serialItems(vehicle)">
          info
        </mat-icon>
      </div> -->
      <!-- <div>
        <ul style="font-size: 10px;">
          <li *ngFor="let item of vehicle.protections">
            {{ item }}
          </li>
        </ul>
      </div> -->
      <!-- </mat-card-content> -->
      <mat-card-actions fxLayout="column" fxLayoutAlign="center center">
        <span style="margin-bottom: 4px;">A partir de:</span>
        <button mat-raised-button color="primary" id="button" (click)="choiceVehicle(vehicle)">
          <span>
            <strong style="font-size: 18px;">
              {{ vehicle.price | currency: "BRL" }}</strong>
            <span style="font-size: 11px;">
              /mês
            </span>
          </span>
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card class="contact-form-card" fxLayout="row" fxFlex="80" fxLayoutAlign="center center"
      *ngIf="showContactForm">
      <app-contact-form [unitID]="unitID" [companyID]="companyID"></app-contact-form>
    </mat-card>
  </div>
  <div mat-dialog-actions></div>
  <br>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-powered-by></app-powered-by>
  </div>
  <br>
</div>