import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { Firebase } from '../initialization/initialization.component';
import { ISocialConfig } from './social-config.factory';

export class SocialLoginFactory {
  private _socialConfig: ISocialConfig;
  constructor(socialConfig: ISocialConfig) {
    this._socialConfig = socialConfig;
  }

  get socialAuthServiceConfig() {
    return {
      autoLogin: true,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(this._socialConfig.googleId, {
            client_id: JSON.parse(
              localStorage.getItem(Firebase.GOOGLE_CLIENT_ID)
            ),
            cookiepolicy: 'single_host_origin',
            plugin_name: 'hello',
          }),
        },
      ],
    } as SocialAuthServiceConfig;
  }
}
