import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CitiesService } from 'src/app/main/services/cities.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-select-unit',
  templateUrl: './select-unit.component.html',
  styleUrls: ['./select-unit.component.scss'],
})
export class SelectUnitComponent implements OnInit {
  @Input() showUnitNotFound: boolean = false;
  @Input('statesSelectUnit') states: Array<any>;
  @Output() loadCitiesEvent = new EventEmitter<string>();
  @Output() completeEvent = new EventEmitter<string>();
  @Output() unitSelectedEvent = new EventEmitter<string>();
  @Input() units: any;

  public form: FormGroup;
  filteredOptions!: Observable<any[]>;
  descricaoControl = new FormControl();
  obj: any;
  public cidades = <any>[];

  constructor(
    private formBuilder: FormBuilder,
    private citiesService: CitiesService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.buildForm();
    this.onChanges();
    this.filteredOptions = this.descricaoControl.valueChanges.pipe(
      map((name) =>
        name
          ? this._filter(name)?.length >= 1
            ? this._filter(name)
            : this.showNotFoundMessage()
          : this?.cidades?.municipio?.slice()
      )
    );
    this.citiesService.getCities().subscribe((i: any) => {
      this.cidades = i.data.results;
    });
    const clienteLocalStorage = localStorage.getItem('d41d8cd98f00b204e9800998ecf8427g'); //key da lokamig
    const clienteLogado = JSON.parse(clienteLocalStorage);
    if (clienteLogado === "lokamig") {
      this.titleService.setTitle('Lokamig MyCar – Carro por Assinatura');
    } else {
      this.titleService.setTitle('CarroPorAssinatura');
    }
  }

  private _filter(name: string): any {
    this.showUnitNotFound = false;
    return this.cidades.filter((i: any) =>
      i.municipio.toLowerCase().includes(name.toLocaleLowerCase())
    );
  }

  showNotFoundMessage() {
    this.units = null;
    this.showUnitNotFound = true;
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      unit: new FormControl(null),
      groups: new FormControl(null, Validators.required),
    });
  }

  private onChanges(): void {}

  public optionClick(obj: any): void {
    this.form.patchValue({ unit: null });
    this.obj = obj;
    this.descricaoControl.setValue(obj.municipio);
    this.loadCitiesEvent.emit(obj);
  }

  public confirmSelection(): void {
    this.unitSelectedEvent.emit(this.form.controls['unit'].value);
  }
}
