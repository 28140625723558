import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { validate } from 'gerador-validador-cpf';
import { LeadsService } from 'src/app/main/services/leads.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  public form: FormGroup;
  public title: string =
    'Não encontrou o seu carro? Entre em contato com nosso time';
  public subtitle: string =
    'É só preencher o formulário abaixo, que iremos te ajudar';
  phoneMask = '(00) 0000-00009';

  @Input('companyID') public companyID: number;
  @Input('unitID') public unitID: number;

  constructor(
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private leadsService: LeadsService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.onChanges();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      email: [null, [Validators.required, Validators.email]],
      cpf: [null, Validators.required],
      phone: [null, Validators.required],
      brand: [null, Validators.required],
      model: [null, Validators.required],
      version: [null, Validators.required],
    });

    this.form.controls['phone'].valueChanges.subscribe((value) => {
      value.length > 10
        ? (this.phoneMask = '(00) 0 0000-0000')
        : (this.phoneMask = '(00) 0000-00009');
    });
  }

  private onChanges(): void {
    this.form.get('cpf').valueChanges.subscribe((res: any) => {
      if (res.toString()?.length === 11) {
        if (!validate(res.toString())) {
          this.form.get('cpf').setErrors({ incorrect: true });
        }
      }
    });
  }

  public submit(): void {
    if (this.form.valid) {
      const formData = this.form.getRawValue();
      this.leadsService
        .post({
          nome: formData.name,
          email: formData.email,
          cpf: formData.cpf,
          telefone: formData.phone,
          marca: formData.brand,
          modelo: formData.model,
          versao: formData.version,
          codigoUnidade: this.unitID,
          codigoEmpresa: this.companyID,
        })
        .subscribe((res: any) => {
          this.form.reset();
          this.form.get('name').setErrors(null);
          this.form.get('email').setErrors(null);
          this.form.get('cpf').setErrors(null);
          this.form.get('phone').setErrors(null);
          this.form.get('brand').setErrors(null);
          this.form.get('model').setErrors(null);
          this.form.get('version').setErrors(null);
          this.matSnackBar.open('Mensagem enviada com sucesso.', null, {
            duration: 6000,
          });
        });
    }
  }
}
