import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
  providedIn: 'root',
})
export class UserPaymentData {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) { }

  getUserPaymentData(id: string): Observable<any> {
    let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
    return this.http.get<any>(`${environment.api.base_url}/assinantes/pagamento/${id}`, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-API-Key": `${this.localStorageService.token}`,
      })
    });
  }
}
