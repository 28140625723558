<div (window:resize)="onResize($event)">
  <mat-toolbar class='navbar'>
    <!-- <button mat-icon-button (click)="sideNav.toggle()" class="example-icon"
      aria-label="Example icon-button with menu icon">
      <mat-icon color="white">menu</mat-icon>
    </button> -->
    <a routerLink='' class="logo">
      <img class="img-responsive" [src]="img" alt="logotipo">
    </a>

    <div fxLayoutAlign="center center">
      <a *ngIf="!userPersonalData" [routerLink]="['/signIn']" fxLayoutAlign="center center">
        <mat-card-subtitle class="rightText" fxLayoutAlign="center center">
          <img src="../../../../assets/login.png" alt="google" height="18px" width="18px" class="rightImg">Entrar
        </mat-card-subtitle>
      </a>

      <a *ngIf="userPersonalData" [routerLink]="['/root/dashboard']" fxLayoutAlign="center center">
        <mat-card-subtitle class="rightText" fxLayoutAlign="center center" style="margin-bottom: 0px !important;"
          *ngIf="userPersonalData?.nomeCompleto?.split(' ')?.length > 1" class="drawerText">{{
          userPersonalData?.nomeCompleto?.split(' ')[0] + ' ' +
          userPersonalData?.nomeCompleto?.split(' ')[userPersonalData?.nomeCompleto?.split('
          ')?.length - 1 ]}}
        </mat-card-subtitle>
        <mat-card-subtitle class="rightText" fxLayoutAlign="center center"
          *ngIf="userPersonalData?.nomeCompleto?.split(' ')?.length > 0 && 2 > userPersonalData?.nomeCompleto?.split(' ')?.length"
          class="drawerText">{{
          userPersonalData?.nomeCompleto?.split(' ')[0]}}
        </mat-card-subtitle>
      </a>
    </div>
  </mat-toolbar>
</div>