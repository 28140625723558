import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storage: Storage;
  private readonly MESSAGE_AUTH_CONTROL = 'd41d8cd98f00b204e9800998ecf8427e';
  private readonly LOGO_EMPRESA = 'd41d8cd98f00b204e9800998ecf8427f';
  private readonly CODIGO_IDENTIFICADOR = 'd41d8cd98f00b204e9800998ecf8427g';
  private readonly FIREBASE_DATA = 'd41d8cd98f00b204e9800998ecf8427i';
  private readonly CODIGO_EMPRESA = 'd41d8cd98f00b204e9800998ecf8427h';
  private readonly CODIGO_UNIDADE = 'd41d8cd98f00b204e9800998ecf8443h';
  private readonly KEY_TOKEN = '66ca0ba2978e652389948804f045b9f2';
  private readonly FIREBASE_TOKEN = '66ca0ba2978e652389948804f045b9c3';
  private readonly LAST_VEHICLE = '66ca0ba2978e652389948804f045b9f1';

  constructor() {
    this.storage = window.localStorage;
  }

  set token(val) {
    const now = new Date();
    const item = {
      value: val,
      expiry: now.getTime() + 3600000,
    }
    this.set(this.KEY_TOKEN, item);
  }

  get token() {
    const itemStr = localStorage.getItem(this.KEY_TOKEN);
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(this.KEY_TOKEN)
      return null
    }
    return item.value
  }

  set fireToken(val) {
    const now = new Date();
    const item = {
      value: val,
      expiry: now.getTime() + 3600000,
    }
    this.set(this.FIREBASE_TOKEN, item);
  }

  get fireToken() {
    const itemStr = localStorage.getItem(this.FIREBASE_TOKEN);
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(this.FIREBASE_TOKEN)
      return null
    }
    return item.value
  }

  set messageAuthControl(val) {
    this.set(this.MESSAGE_AUTH_CONTROL, val);
  }

  get messageAuthControl() {
    return JSON.parse(this.storage.getItem(this.MESSAGE_AUTH_CONTROL))
  }

  set logoEmpresa(val) {
    this.set(this.LOGO_EMPRESA, val);
  }

  get logoEmpresa() {
    return JSON.parse(this.storage.getItem(this.LOGO_EMPRESA))
  }

  set codigoIdentificador(val) {
    this.set(this.CODIGO_IDENTIFICADOR, val);
  }

  get codigoIdentificador() {
    return JSON.parse(this.storage.getItem(this.CODIGO_IDENTIFICADOR))
  }

  set firebaseData(val) {
    this.set(this.FIREBASE_DATA, val);
  }

  get firebaseData() {
    return JSON.parse(this.storage.getItem(this.FIREBASE_DATA))
  }

  set codigoEmpresa(val) {
    this.set(this.CODIGO_EMPRESA, val);
  }

  get codigoEmpresa() {
    return JSON.parse(this.storage.getItem(this.CODIGO_EMPRESA))
  }

  set codigoUnidade(val) {
    this.set(this.CODIGO_UNIDADE, val);
  }

  get codigoUnidade() {
    return JSON.parse(this.storage.getItem(this.CODIGO_UNIDADE))
  }

  set lastVehicleSelected(val) {
    this.set(this.LAST_VEHICLE, val);
  }

  get lastVehicleSelected() {
    return JSON.parse(this.storage.getItem(this.LAST_VEHICLE))
  }

  set(key: string, value: any): boolean {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  get(key: string): any {
    if (this.storage) {
      return JSON.parse(this.storage.getItem(key));
    }
    return null;
  }

  remove(key: string): boolean {
    if (this.storage) {
      this.storage.removeItem(key);
      return true;
    }
    return false;
  }

  clear(): boolean {
    if (this.storage) {
      this.storage.clear();
      return true;
    }
    return false;
  }
}