<div style="height: 60px">
  <app-header></app-header>
</div>
<div id="container">
  <div fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <mat-card-subtitle id="title-big">
        Escolha suas marcas de preferência
      </mat-card-subtitle>
    </div>
    <mat-card-subtitle id="title-big-bold">
      Você pode selecionar mais de uma opção
    </mat-card-subtitle>
  </div>
  <div fxLayout="row" fxLayoutAlign='center center'>
    <div id="div" fxFlex="75" fxLayoutAlign="flex-start flex-start" fxLayout="column wrap" fxLayoutGap="2px"
      fxLayout.xs="column wrap">
      <div class="card" *ngFor="let group of groupFiltered; let i = index" [value]="group"
        (click)="choiceBrand(i, group)">
        <div>
          <mat-card-subtitle id="cardTitle" [ngStyle]="index[0]?.index == i || index[1]?.index == i || index[2]?.index == i || index[3]?.index == i || index[4]?.index == i || index[5]?.index == i || index[6]?.index == i || index[7]?.index == i || index[8]?.index == i || index[9]?.index == i || index[10]?.index == i || index[11]?.index == i || index[12]?.index == i || index[13]?.index == i || index[14]?.index == i || index[15]?.index == i 
          ? {'font-weight': '1000', 'color': '#000', 'letter-spacing': '0.7px'} : {'font-weight': '400'}"
            class="brandName"> {{
            group }}
          </mat-card-subtitle>
        </div>
      </div>
      <!-- <mat-card-content> -->
      <!-- <div fxLayout="row" fxLayoutAlign="end center">
        <mat-icon id="info" [matTooltip]="serialItems(vehicle)">
          info
        </mat-icon>
      </div> -->
      <!-- <div>
        <ul style="font-size: 10px;">
          <li *ngFor="let item of vehicle.protections">
            {{ item }}
          </li>
        </ul>
      </div> -->
      <!-- </mat-card-content> -->
    </div>
    <div fxFlex="25" fxLayoutAlign="center center" style="padding-right: 6%;">
      <div *ngIf="ind >= 1" id="mat-icon" (click)="routeScreen()">
        <mat-icon style="width: 48px; height: 48px; font-size: 48px;" color="black">keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>
</div>