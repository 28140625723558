import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
  providedIn: 'root'
})
export class UserVehicleDataForPost {

  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
  ) { }

  postUserVehicleData(body) {
    let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
    return this.httpClient.post<any>(`${environment.api.base_url}/veiculos`, body, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-API-Key": `${this.localStorageService.token}`,
      })
    })
  }
}
