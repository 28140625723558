import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CanActivateChildGuard } from './CanActivateChildGuard';
import { InitializationModule } from './initialization/initialization.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    InitializationModule,
    BrowserAnimationsModule
  ],
  providers: [CanActivateChildGuard],
  bootstrap: [AppComponent],
})
export class AppModule { }
