import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SocialLoginModule } from 'angularx-social-login';
import { MatCurrencyFormatModule } from 'mat-currency-format';
import { NgxMaskModule } from 'ngx-mask';
import { CanActivateChildGuard } from '../CanActivateChildGuard';
import { httpInterceptorProviders } from '../interceptors';
import { LoadingService } from '../loading.service';
import { MaterialModule } from '../material.module';
import { PageConnectionComponent } from './components/page-connection/page-connection.component';
import { ProgressSpinnerModule } from './components/progress-spinner/progress-spinner.module';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { AuthService } from './services/auth.service';
import { MessagingService } from './services/messaging-push-firebase.service';
import { TermGeneralConditionsComponent } from './shared/term-general-conditions/term-general-conditions.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SignatureStepsModule } from './views/signature-steps/signature-steps.module';
@NgModule({
  declarations: [
    MainComponent,
    PageConnectionComponent,
    ScrollTopComponent,
    TermGeneralConditionsComponent,
    DashboardComponent,
  ],
  imports: [
    MainRoutingModule,
    CommonModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    SignatureStepsModule,
    ProgressSpinnerModule,
    SocialLoginModule,
    AngularFireModule.initializeApp({}),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    IvyCarouselModule,
    MatCurrencyFormatModule,
  ],
  exports: [MainComponent],
  providers: [
    CanActivateChildGuard,
    LoadingService,
    MessagingService,
    AuthService,
    ...httpInterceptorProviders,
  ],
})
export class MainModule {}
