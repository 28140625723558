import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  postAuth(email, pass) {
    const body = { email: email, password: pass, codigoIdentificador: this.localStorageService.codigoIdentificador };
    return this.httpClient.post(`${environment.api.base_url}/auth`, body);
  }
}
