import { Injectable, NgZone } from '@angular/core';
import { User } from "./../../interfaces/user.interface";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { LocalStorageService } from './localservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class FirestoreAuthService {
  userData: any;
  user: User;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private localStorageService: LocalStorageService,
    private _snackBar: MatSnackBar,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

    this.afAuth.authState.subscribe(result => {
      if (result) {
        this.user = {
          provider: result?.providerId,
          emailVerified: null,
          uid: result?.uid,
          id: null,
          email: result?.email,
          name: null,
          photoUrl: null,
          firstName: null,
          lastName: null,
          authToken: null,
          idToken: null,
          authorizationCode: null,
          response: null,
        }
        localStorage.setItem('user', JSON.stringify(this.user));
        // JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        // JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  SignIn(email, password) {
    const pass = String(password)
    return this.afAuth.signInWithEmailAndPassword(email, pass)
      .then((result) => {
        this.ngZone.run(() => {
          this.user = {
            provider: result?.user?.providerId,
            emailVerified: null,
            uid: result?.user?.uid,
            id: null,
            email: result?.user?.email,
            name: null,
            photoUrl: null,
            firstName: null,
            lastName: null,
            authToken: null,
            idToken: null,
            authorizationCode: null,
            response: null,
          }
          // this.afAuth.currentUser.getIdToken().then((token) => {
          //   if (token) {
          //     this.localStorageService.clear();
          //     this.localStorageService.token = token
          //   }
          // });
        });
        this.localStorageService.fireToken = result?.user?.uid;
      }).catch((error) => {
        this._snackBar.open(error.message, '', { duration: 3000 });
      });
  }

  SignInWithCustomToken(token) {
    return this.afAuth.signInWithCustomToken(token)
      .then((result) => {
        this.ngZone.run(() => {
          this.user = {
            provider: result?.user?.providerId,
            emailVerified: null,
            uid: result?.user?.uid,
            id: null,
            email: result?.user?.email,
            name: null,
            photoUrl: null,
            firstName: null,
            lastName: null,
            authToken: null,
            idToken: null,
            authorizationCode: null,
            response: null,
          }
          // this.afAuth.currentUser.getIdToken().then((token) => {
          //   if (token) {
          //     this.localStorageService.clear();
          //     this.localStorageService.token = token
          //   }
          // });
        });
        // this.SetUserData(result.user);
      }).catch((error) => {
        this._snackBar.open(error.message, '', { duration: 3000 });
      });
  }

  //sign up function
  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.user = {
          provider: result?.user?.providerId,
          emailVerified: null,
          uid: result?.user?.uid,
          id: null,
          email: result?.user?.email,
          name: null,
          photoUrl: null,
          firstName: null,
          lastName: null,
          authToken: null,
          idToken: null,
          authorizationCode: null,
          response: null,
        }
        /* Call the SendVerificaitonMail() function when new user sign up and returns promise */
        // this.SetUserData(result.user);
      }).catch((error) => {
        this._snackBar.open(error.message, '', { duration: 3000 });
      });
  }

  //Reset Password Function
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this._snackBar.open('Um link para redefinição de senha foi enviado para o seu email.', '', { duration: 8000 });
      }).catch((error) => {
        this._snackBar.open(error.message, '', { duration: 8000 });
      });
  }

  //check user login
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  //set full user data we get
  SetUserData(user, profilePicture) {
    const userRef: AngularFirestoreDocument = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      provider: null,
      emailVerified: user.emailVerified,
      uid: user.uid,
      id: null,
      email: user.email,
      name: null,
      photoUrl: profilePicture,
      firstName: null,
      lastName: null,
      authToken: null,
      idToken: null,
      authorizationCode: null,
      response: null,
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out function
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
    });
  }
}