import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { LocalStorageService } from 'src/app/main/services/localservice.service';
import { UserVehicleDataForPost } from 'src/app/main/services/post-user-vehicle.service';
import { VehicleService } from 'src/app/main/services/vehicle.service';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit {
  private totalValueOptionals: number = 0;

  vehicleId: string;
  optionalsSelected: Array<any> = [];

  public form: FormGroup;

  public contractDurationMonths: Array<any> = [];
  public franchises: Array<number> = [];

  public periodoList: any[] = [];

  public priceVehicle: number = 0;
  public vehicle: IVehicle;
  public vehicleDiversosPorDiaria: any;
  public vehicleDiversosTaxaUnica: any;

  public vehiclesFiltered: any;
  public companyID: number;
  public unitID: number;
  public groups;

  public isChecked: any;
  public isCheckedName: any;
  public breakpoint: any;

  public addDiversos: boolean = false;
  public diversosObject: any[] = [];
  public diversosPrice: number = 0;

  constructor(
    private vehicleService: VehicleService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private socialAuthService: SocialAuthService,
    public userVehicleData: UserVehicleDataForPost,
    private localStorageService: LocalStorageService
  ) {}

  back() {
    this.router.navigate(['veiculos']);
  }

  ngOnInit(): void {
    this.unitID = history.state.data.unitID;
    this.companyID = history.state.data.companyID;
    this.vehicle = history.state.data.vehicle;
    this.groups = history.state.data.groups;
    this.vehiclesFiltered = history.state.data.group.filter(
      (item) => item != this.vehicle
    );
    this.breakpoint = window.innerWidth <= 400 ? 1 : 2;
    this.loadVehicle();
    this.buildForm();
    this.onChanges();
    // this.form.patchValue({
    //   contractDuration: 0,
    //   monthKmFranchise: 0
    // })
    // this.calcPriceVehicle()
  }

  onResize(event) {
    this.breakpoint = event.target.innerWidth <= 400 ? 1 : 2;
  }

  onSelectCheckbox(hex: string) {
    this.vehicle.colors.forEach((x) => {
      if (x.hex !== hex) {
        x.hex = !x.hex;
      }
    });
  }

  public choiceVehicle(vehicle: IVehicle): void {
    this.router.navigate(['/root/vehicle-details'], {
      state: {
        data: {
          vehicle: vehicle,
          groups: this.groups,
          group: history.state.data.group,
          unitID: this.unitID,
          companyID: this.companyID,
        },
      },
    });
    this.redirectTo('/root/vehicle-details', vehicle);
  }

  redirectTo(uri: string, vehicle: IVehicle) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigate([uri], {
          state: {
            data: {
              vehicle: vehicle,
              groups: this.groups,
              group: history.state.data.group,
              unitID: this.unitID,
              companyID: this.companyID,
            },
          },
        })
      );

    this.scrollTo();
  }

  private calcPriceVehicle() {
    if (
      this.form?.get('contractDuration')?.value !== null &&
      this.form?.get('monthKmFranchise')?.value !== null
    ) {
      const tariff = this.periodoList.find(
        (item) =>
          item.descricaoPeriodoTarifa ===
            this.form.get('contractDuration').value &&
          item.codigoTarifa ===
            this.form.get('monthKmFranchise').value.codigoTarifa
      );

      if (tariff) {
        this.priceVehicle =
          tariff.valorPeriodoVeiculo + this.totalValueOptionals;
        return;
      }
    }

    this.priceVehicle = 0;
  }

  private onChanges() {
    this.form.get('contractDuration').valueChanges.subscribe((value) => {
      if (value) {
        this.franchises = this.periodoList.filter(
          (i: any) => i.descricaoPeriodoTarifa === value
        );
      }
      this.form.get('monthKmFranchise').reset();
    });

    this.form
      .get('monthKmFranchise')
      .valueChanges.subscribe(() => this.calcPriceVehicle());
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      contractDuration: new FormControl(null, Validators.required),
      monthKmFranchise: new FormControl(null, Validators.required),
      color: new FormControl(null, Validators.required),
    });
  }

  private loadVehicle(): void {
    console.log(this.vehicle);
    this.vehicleService
      .getVehicleModelsByID(this.vehicle.id)
      .subscribe((res: any) => {
        res = res.data.results;
        console.log(res);
        this.vehicle = {
          id: res.codigoModelo,
          thumbnail: res.imagem,
          picture: res.imagem,
          name: res.nome,
          brand: res.marca,
          model: res.modelo,
          version: res.versao,
          price: res.valor,
          colors: res.cores.map((color: any) => {
            return {
              id: color.id,
              name: color.nome,
              hex: color.hex,
            };
          }),
          diversos: res?.diversos.map((_item) => _item),
          serialItems: res.itensSerie,
          descricaoTarifaAssinatura: res?.descricaoTarifaAssinatura,
          descricaoEquipamento: res.descricaoEquipamento,
          protections: res.protecoes,
          tariffs: res?.tarifas?.map((item) => ({
            period: item.periodo,
            franchise: item.franquiakm,
            price: item.valor,
            codigoTarifa: item.codigoTarifa,
          })),
          period: res.periodo,
          franchise: res.franquiakm,
        };
        console.log(this.vehicle);
        this.vehicleDiversosPorDiaria = this.vehicle.diversos.filter(
          (i: any) => i.calculoPorDiarias === 'S'
        );
        this.vehicleDiversosTaxaUnica = this.vehicle.diversos.filter(
          (i: any) => i.calculoPorDiarias === 'N'
        );
        this.contractDurationMonths = Array.from([
          ...new Set(
            res.periodoList.map((item) => item?.descricaoPeriodoTarifa)
          ),
        ]);
        this.periodoList = res.periodoList;
        this.form.get('contractDuration').setValue(this.vehicle.period);
        this.form.get('monthKmFranchise').setValue(this.vehicle.franchise);
      });
  }

  validateInputs() {
    if (!this.form.controls['contractDuration']?.value) {
      this.form.controls['contractDuration'].markAsTouched();
      this.form.get('contractDuration').markAllAsTouched();
    }
    if (!this.form.controls['monthKmFranchise']?.value) {
      this.form.controls['monthKmFranchise'].markAsTouched();
    }
    if (!this.form.controls['color']?.value) {
      this.form.controls['color'].markAsTouched();
    }
  }

  public continue(): void {
    this.validateInputs();
    if (this.form.valid) {
      const json = this.form.getRawValue();
      this.vehicle.price = this.priceVehicle;
      if (this?.localStorageService?.token) {
        this.userVehicleData
          .postUserVehicleData({
            codigoUnidade: this.unitID,
            codigoEmpresa: this.companyID,
            codigoFirebase: this.localStorageService.fireToken,
            veiculo: {
              optionals: this.optionalsSelected,
              codigoModelo: this?.vehicle?.id,
              codigoTarifa: parseInt(json.monthKmFranchise.codigoTarifa),
              codigoCor: json.color?.id,
              codigoGrupo: this?.groups?.id,
            },
            diversos: this.diversosObject,
          })
          .subscribe((i) => {
            this.router.navigate(['/root/dashboard']);
            return this._snackBar.open(
              i.data.message[0].toUpperCase() +
                i.data.message.toLowerCase().slice(1),
              '',
              { duration: 3000 }
            );
          });
      } else {
        this.localStorageService.lastVehicleSelected = {
          codigoUnidade: this.unitID,
          codigoEmpresa: this.companyID,
          codigoFirebase: this.localStorageService.fireToken,
          veiculo: {
            optionals: this.optionalsSelected,
            codigoModelo: this?.vehicle?.id,
            codigoTarifa: parseInt(json.monthKmFranchise.codigoTarifa),
            codigoCor: json.color?.id,
            codigoGrupo: this?.groups?.id,
          },
          diversos: this.diversosObject,
        };
        this.router.navigate(['/root/register'], {
          state: {
            data: {
              vehicle: this.vehicle,
              groups: this.groups,
              selectedCar: {
                contract: json.contractDuration,
                color: json.color,
                optionals: this.optionalsSelected,
                monthKmFranchise: json.monthKmFranchise,
                diversos: this.diversosObject,
              },
              unitID: this.unitID,
              companyID: this.companyID,
            },
          },
        });
      }
    }
  }

  scrollTo() {
    var elmntToView = document.getElementById('top');
    elmntToView.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }

  public checkClick(event, optional): void {
    if (!event.checked) {
      const index = this.optionalsSelected.findIndex(
        (item) => item.id === optional.id
      );
      this.optionalsSelected.splice(index, 1);
      this.totalValueOptionals -= optional.price;
    } else {
      this.optionalsSelected.push(optional);
      this.totalValueOptionals += optional.price;
    }

    this.calcPriceVehicle();
  }

  public addDiversosFunction() {
    this.validateInputs();
    if (this.form.valid) {
      this.addDiversos = !this.addDiversos;
    }
  }

  public addDiversoInObject(diverso: any, index: number) {
    console.log(diverso);
    diverso.checked = true;
    const data = this.diversosObject.filter(
      (i: any) => i.descricao === diverso.descricao
    );
    if (data?.length > 0) {
      this.diversosObject = this.diversosObject.filter(
        (i: any) => i.descricao !== diverso.descricao
      );
    } else {
      this.diversosObject.push({
        valor: diverso.valor,
        descricao: diverso.descricao,
        permanente: diverso.permanente,
        calculoPorDiarias: diverso.calculoPorDiarias,
        checked: diverso.checked,
        codigoServicoAdicional: diverso.codigoServicoAdicional,
      });
    }
    this.diversosPrice = 0;
    this.diversosObject.forEach((item: any) => {
      if (item?.calculoPorDiarias === 'S') {
        this.diversosPrice = item?.valor * 30 + this.diversosPrice;
      } else {
        this.diversosPrice = this.diversosPrice + item?.valor;
      }
      // if (item?.permanente === 'S') {
      //   this.diversosPrice = this.diversosPrice + item?.valor;
      // }
    });
    this.diversosObject = this.diversosObject.filter(
      (i: any) => i.checked === true
    );
  }
}
