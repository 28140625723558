<div style="height: 60px">
  <app-header></app-header>
</div>
<div id="div" *ngIf="show === true">
  <mat-card-subtitle id="title-big"> Para você </mat-card-subtitle>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
    fxLayout.xs="column wrap"
  >
    <mat-card
      fxFlex="20"
      class="card"
      *ngFor="let vehicle of vehicles"
      (click)="choiceVehicle(vehicle)"
    >
      <mat-card-header id="cardTitle">
        <mat-card-title>{{ vehicle.model }}</mat-card-title>
        <mat-card-subtitle>{{ vehicle.version }}</mat-card-subtitle>
      </mat-card-header>

      <img mat-card-image [src]="vehicle.picture" [alt]="vehicle.model" />

      <!-- <mat-card-content> -->
      <!-- <div fxLayout="row" fxLayoutAlign="end center">
      <mat-icon id="info" [matTooltip]="serialItems(vehicle)">
        info
      </mat-icon>
    </div> -->
      <!-- <div>
      <ul style="font-size: 10px;">
        <li *ngFor="let item of vehicle.protections">
          {{ item }}
        </li>
      </ul>
    </div> -->
      <!-- </mat-card-content> -->
      <mat-card-actions fxLayout="column" fxLayoutAlign="center center">
        <span style="margin-bottom: 4px">A partir de:</span>
        <button
          mat-raised-button
          color="primary"
          id="button"
          (click)="choiceVehicle(vehicle)"
        >
          <span>
            <strong style="font-size: 18px">
              {{ vehicle.price | currency : "BRL" }}</strong
            >
            <span style="font-size: 11px"> /mês </span>
          </span>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <br /><br />
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-powered-by></app-powered-by>
  </div>
  <br /><br /><br /><br /><br />
</div>
