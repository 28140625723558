import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import IGroup from 'src/app/interfaces/group.interface';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { VehicleService } from '../../../services/vehicle.service';

@Component({
  selector: 'app-second-route',
  templateUrl: './second-route.component.html',
  styleUrls: ['./second-route.component.scss']
})
export class SecondRouteComponent implements OnInit {
  form: FormGroup;
  pressed: boolean;
  unitID: number;
  companyID: number;
  groups: any;
  groupsLength: number;
  vehicles: IVehicle[] = [];

  config = {
    lazy: true,
    navigation: true,
    breakpoints: {
      '480': {
        slidesPerView: 1,
      },
      '675': {
        slidesPerView: 2,
      },
      '950': {
        slidesPerView: 3,
      },
      '1280': {
        slidesPerView: 4,
      },
    },
  };

  constructor(
    private vehicleService: VehicleService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.unitID = history.state.unitID;
    if (this.unitID != null) {
      this.companyID = history.state.companyID;
      this.groups = JSON.parse(history.state.groups);
      this.groupsLength = this.groups?.length;
      const id = this.groups.map(item => item.id);
      // this.vehicleService.getVehicleModelsByGrupo(id).subscribe((res) => {
      //   res.forEach((item) => {
      //     const vehicle: IVehicle = {
      //       id: item.id,
      //       name: item.nome,
      //       thumbnail: null,
      //       picture: null,
      //       brand: null,
      //       model: null,
      //       version: null,
      //       price: null,
      //       colors: null,
      //       serialItems: null,
      //       optionals: null,
      //       protections: null,
      //     };
      //     this.vehicles.push(vehicle);
      //   })
      // });

    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      controlStepper: new FormControl(null, Validators.required),
    });
  }

  private routeScreen(group: IGroup): void {
    this.router.navigate(['/root/second-vehicle'], { state: { group: JSON.stringify(group), unitID: this.unitID, companyID: this.companyID } });
  }

  public choiceGroup(group: IGroup): void {
    this.routeScreen(group);
  }

  public routeBack(): void {
    this.router.navigate(['/root/business-group'], { state: { groups: JSON.stringify(this.groups), unitID: this.unitID, companyID: this.companyID } });
  }

  public showMore(): void {
    this.pressed = true;
  }
}
