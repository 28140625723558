import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { MaterialModule } from 'src/app/material.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [DialogComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
  ],
})
export class DialogModule { }
