import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import SwiperCore, { Navigation, Autoplay } from 'swiper/core';

SwiperCore.use([Navigation, Autoplay]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input()
  data: any[];

  @Input()
  selectItem: Function;

  @Input()
  config: any[];
}
