// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api: {
    base_url: 'https://apimycar.lokamig.com.br/api/v1',
  },
  // api: {
  //   base_url: 'http://carroassinatura.infosistemas.com.br:6022/api/v1',
  //   username: 'user',
  //   password: '123'
  // },
  geolocationApi: {
    base_url: 'https://nominatim.openstreetmap.org/reverse.php?format=jsonv2',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
