import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { LoadingOverlayRef, LoadingService } from 'src/app/loading.service';

@Component({
  selector: 'app-vehicles-results',
  templateUrl: './vehicles-results.component.html',
  styleUrls: ['./vehicles-results.component.scss']
})
export class VehiclesResultsComponent implements OnInit {
  vehicles: any;
  show: boolean = false;
  minValue: number;
  maxValue: number;
  public companyID: number;
  public unitID: number;
  public groups: any;

  constructor(
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    let loadingRef: LoadingOverlayRef;
    loadingRef = this.loadingService.open();
    setTimeout(() => {
      loadingRef.close();
      this.show = true;
      this.minValue = history?.state?.data?.lowerPrice;
      this.maxValue = history?.state?.data?.highPrice;
      this.unitID = history.state.data.unitID;
      this.groups = history.state.data.groups;
      this.companyID = history.state.data.companyID;
      this.vehicles = history.state.data.vehicles.filter(i => i.price <= this.maxValue && i.price >= this.minValue);
    }, 1200)
  }


  private routeScreen(vehicle: IVehicle): void {
    this.router.navigate(['/root/vehicle-details'], { state: { data: { vehicle: vehicle, group: this.vehicles, groups: this.groups, unitID: this.unitID, companyID: this.companyID } } });
  }

  public choiceVehicle(vehicle: IVehicle): void {
    this.routeScreen(vehicle);
  }
}
