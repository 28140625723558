import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
    providedIn: 'root',
})
export class UserNotificationDataForPatch {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
    ) { }

    patchUserNotificationData(id) {
        let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
        this.http.patch(`${environment.api.base_url}/assinantes/notificacao/visualizacao/${id}/`, {},
            {
                params: params,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    "X-API-Key": `${this.localStorageService.token}`,
                })
            }).subscribe()
    }
}
