import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CardComponent } from './card.component';
import { MaterialModule } from 'src/app/material.module';
import { SliderModule } from '../slider/slider.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule, MaterialModule, SliderModule],
  exports: [CardComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
  ],
})
export class CardModule {}
