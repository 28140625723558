<div class="cardListContainer">
  <div class="cardListWrapper">
    <ng-container *ngFor="let vehicle of currentItems">
      <mat-card class="vehicleCard" (click)="choiceVehicle(vehicle)">
        <mat-card-header>
          <mat-card-title>{{ vehicle.model }}</mat-card-title>
          <mat-card-subtitle>{{ vehicle.version }}</mat-card-subtitle>
        </mat-card-header>

        <img mat-card-image [src]="vehicle.picture" [alt]="vehicle.model" />

        <mat-card-content>
          <div class="priceWrapper">
            <span>A PARTIR DE</span>
            <span class="price">
              <strong>{{ vehicle.price | currency: "BRL" }}</strong
              >/mês</span
            >
          </div>

          <div class="slider">
            <app-slider [config]="config" [data]="vehicle.serialItems"></app-slider>
          </div>

          <div class="protectionsWrapper">
            <ul>
              <li *ngFor="let item of vehicle.protections">
                {{ item }}
              </li>
            </ul>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <button mat-raised-button>
            EU QUERO ESTE
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </div>

  <button
    mat-stroked-button
    (click)="handleLoadMore()"
    class="loadMoreBtn"
    *ngIf="!isHidden"
  >
    VER MAIS VEÍCULOS
  </button>
</div>
