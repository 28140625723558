import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureStepsComponent } from './signature-steps.component';
import { MaterialModule } from 'src/app/material.module';
import { SignatureStepsRoutingModule } from './signature-steps-routing.module';
import { SelectUnitComponent } from './select-unit/select-unit.component';
import { BusinessGroupComponent } from './business-group/business-group.component';
import { CardModule } from '../../shared/card/card.module';
import { RegisterComponent } from './register/register.component';
import { VehiclesComponent } from './business-group/vehicles/vehicles.component';
import { DialogModule } from '../../shared/dialog/dialog.module';
import { VehicleDetailsComponent } from './business-group/vehicles/vehicle-details/vehicle-details.component';
import { ContactFormComponent } from './business-group/vehicles/contact-form/contact-form.component';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { PoweredByComponent } from '../../shared/powered-by/powered-by.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { TextMaskModule } from 'angular2-text-mask';
import { SuccessScreenComponent } from './register/sign-success-screen/success-screen.component';
import { SecondRouteComponent } from './second-route/second-route.component';
import { SecondVehiclesComponent } from './second-route/vehicles/second-vehicles.component';
import { SecondSliderComponent } from './second-route/vehicles/second-slider/second-slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { VehiclesResultsComponent } from './second-route/vehicles/second-slider/vehicles-results/vehicles-results.component';
import { HeaderComponent } from '../../components/header/header.component';
import { getIdentificador } from 'src/app/config/social-config.factory';
import { SocialLoginFactory } from 'src/app/config/social-login.factory';
import { LocalStorageService } from '../../services/localservice.service';

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    SignatureStepsComponent,
    SelectUnitComponent,
    HeaderComponent,
    BusinessGroupComponent,
    VehiclesComponent,
    RegisterComponent,
    VehicleDetailsComponent,
    ContactFormComponent,
    PoweredByComponent,
    SuccessScreenComponent,
    SecondRouteComponent,
    SecondVehiclesComponent,
    SecondSliderComponent,
    VehiclesResultsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SignatureStepsRoutingModule,
    CardModule,
    DialogModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    MatButtonToggleModule,
    TextMaskModule,
    NgxSliderModule
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: 'SocialAuthServiceConfig',
      deps: [LocalStorageService],
      useFactory: (dep: LocalStorageService) => {
        const url = dep.firebaseData;
        const socialConfig = getIdentificador(url);
        const factoryService = new SocialLoginFactory(socialConfig);
        return factoryService.socialAuthServiceConfig;
      },
    },
  ]
})
export class SignatureStepsModule { }
