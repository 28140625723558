import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { SliderComponent } from './slider.component';
import { MaterialModule } from 'src/app/material.module';


@NgModule({
  declarations: [SliderComponent],
  imports: [
    CommonModule,
    SwiperModule,
    MaterialModule
  ],
  exports: [SliderComponent]
})
export class SliderModule { }
