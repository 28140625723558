import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { Options, LabelType } from "@angular-slider/ngx-slider";
@Component({
  selector: 'app-second-slider',
  templateUrl: './second-slider.component.html',
  styleUrls: ['./second-slider.component.scss'],
})
export class SecondSliderComponent implements OnInit {
  vehicles: IVehicle[] = [];
  minValue: number = 100;
  maxValue: number = 1000;
  public companyID: number;
  public unitID: number;
  public groups: any;

  options: Options = {
    floor: history.state.data.lowerPrice,
    ceil: history.state.data.highPrice,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "R$" + value;
        case LabelType.High:
          return "R$" + value;
        default:
          return "";
      }
    }
  };

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.vehicles = history.state.data.vehicles;
    this.minValue = history.state.data.lowerPrice;
    this.maxValue = history.state.data.highPrice;
    this.unitID = history.state.data.unitID;
    this.companyID = history.state.data.companyID;
    this.groups = history.state.data.group;
  }

  public routeScreen(): void {
    this.router.navigate(['/root/vehicles-results'],
      {
        state: {
          data: {
            lowerPrice: this.minValue,
            highPrice: this.maxValue,
            vehicles: this.vehicles,
            unitID: this.unitID,
            companyID: this.companyID,
            groups: this.groups
          }
        }
      }
    );
  }
}