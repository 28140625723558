<div class="swiperWrapper">
  <swiper [config]="config">
    <ng-container *ngFor="let vehicle of data">
      <ng-template swiperSlide class="swiperSlider">
        <div class="checkboxWrapper" *ngIf="vehicle.thumbImage">
          <img
            *ngIf="vehicle.thumbImage"
            [src]="vehicle.thumbImage"
            (click)="
              checkbox.checked = !checkbox.checked;
              checkbox.checked
                ? selectItem(vehicle, true)
                : selectItem(vehicle, false)
            "
          />
          <mat-checkbox
            class="checkboxItem"
            #checkbox
            (change)="
              checkbox.checked
                ? selectItem(vehicle, true)
                : selectItem(vehicle, false)
            "
          >
            {{ vehicle.title }}
          </mat-checkbox>
        </div>

        <div *ngIf="!vehicle.thumbImage">
          <ng-container>
            <span>{{ vehicle }}</span>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </swiper>
</div>
