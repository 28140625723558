import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import IDealer from 'src/app/interfaces/dealer.interface';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { VehicleService } from '../../../../services/vehicle.service';

interface IImage {
  image: string;
  thumbImage: string;
  title: string;
  alt: string;
}
@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent implements OnInit {
  selected = 'none';
  form: FormGroup;
  images: IImage[] = [];
  vehicles: IVehicle[] = [];
  vehiclesFiltered: IVehicle[] = [];
  dealers: IDealer[] = [];
  selectedVehicles: IVehicle[] = [];
  vehiclesProfile: IImage[] = [];
  public titleGroupName: string = ``;
  public showContactForm: boolean = false;
  public companyID: number;
  public unitID: number;
  public group: any;
  public groupFiltered: any;

  config = {
    lazy: true,
    navigation: true,
    breakpoints: {
      '480': {
        slidesPerView: 1,
      },
      '675': {
        slidesPerView: 2,
      },
      '950': {
        slidesPerView: 3,
      },
      '1280': {
        slidesPerView: 4,
      },
    },
  };

  constructor(
    private vehicleService: VehicleService,
    private formBuilder: FormBuilder,
    private router: Router,
    // private dialogRefVehiclesComponent: MatDialogRef<VehiclesComponent>,
  ) { }

  private routeScreen(vehicle: IVehicle): void {
    this.router.navigate(['/root/vehicle-details'], { state: { data: { vehicle: vehicle, groups: this.group, group: this.vehicles, unitID: this.unitID, companyID: this.companyID } } });
    // const dialogRef = this.dialog.open(VehicleDetailsComponent, {
    //   height: '100%',
    //   width: '100vw',
    //   maxWidth: '100vw',
    //   panelClass: 'full-screen-modal',
    //   data: vehicle,
    //   closeOnNavigation: false,
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result?.continue) {
    //     this.dialogRefVehiclesComponent.close({
    //       continue: true, ...result
    //     })
    //   }
    // });
  }

  handleSelectItem(vehicle: IImage, selected: boolean) {
    this.vehicles.forEach((item) => {
      if (vehicle.title == item?.name) {
        if (!selected) {
          const models = this.selectedVehicles.filter(
            ({ name }) => name == item?.name
          );
          this.selectedVehicles.forEach((item, index) => {
            models.forEach((it) => {
              if (item?.name == it.name) {
                this.selectedVehicles.splice(index, 1);
              }
            });
          });
        } else {
          this.selectedVehicles.push(item);
        }
      }
    });
    return this.selectedVehicles;
  }

  selectFilter(selected) {
    if (selected == 'high') {
      this.vehiclesFiltered.sort((low, high) => high.price - low.price)
    } else if (selected == 'low') {
      this.vehiclesFiltered.sort((low, high) => low.price - high.price)
    } else if (selected == null) {
      this.vehiclesFiltered = this.vehicles;
    } else if (selected != 'none') {
      this.vehiclesFiltered = this.vehicles.filter((item) => item?.brand.includes(selected));
    }
  }

  ngOnInit() {
    this.unitID = history.state.unitID;
    if (this.unitID != null) {
      this.companyID = history.state.companyID;
      this.group = JSON.parse(history.state.group);
    }
    this.titleGroupName = this?.group?.descricao;
    this.form = this.formBuilder.group({
      controlStepper: new FormControl(null, [Validators.required])
    });
    this.vehicleService.getVehicleModelsByGrupo(this.group.id).subscribe((res) => {
      console.log(res)
      res.data.results.forEach((item) => {
        const vehicle: IVehicle = {
          id: item?.codigoModelo,
          thumbnail: item?.imagem,
          picture: item?.imagem,
          name: item?.nome,
          brand: item?.marca,
          model: item?.modelo,
          version: item?.versao,
          price: item?.valor,
          diversos: null,
          colors: null,
          descricaoTarifaAssinatura: item?.descricaoTarifaAssinatura,
          descricaoEquipamento: item?.descricaoEquipamento,
          serialItems: item?.itensSerie.map((_item) => _item),
          protections: item?.protecoes
        };
        this.vehicles.push(vehicle);
        this.vehiclesFiltered.push(vehicle);
      })
      this.groupFiltered = Array.from([...new Set(this.vehicles.map(item => item?.brand))]);
      this.images = res.data.results.map((dt) => {
        return {
          image: dt.imagem,
          thumbImage: dt.imagem,
          title: dt.nome,
          alt: dt.modelo,
        };
      });
      this.images.forEach((_, index) => {
        if (this.images[index + 1]) {
          if (this.images[index].title !== this.images[index + 1].title) {
            this.vehiclesProfile.push(this.images[index]);
          }
        }
      });
      this.vehiclesProfile.push(this.images[this.images?.length - 1]);
      this.showContactForm = true;
    });
  }

  public serialItems(vehicle: IVehicle): string {
    return vehicle.serialItems.join(' | ');
  }

  public choiceVehicle(vehicle: IVehicle): void {
    this.routeScreen(vehicle);
  }
}
