import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { MainComponent } from '../main/main.component';
import { InitializationComponent } from './initialization.component';
const routes: Routes = [
  { path: 'portaldocliente/:nomeLocadora', component: InitializationComponent },
  { path: 'main', component: MainComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
})

export class InitializationRoutingModule { }
