import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import IDealer from 'src/app/interfaces/dealer.interface';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { VehicleService } from '../../../../services/vehicle.service';

interface IImage {
  image: string;
  thumbImage: string;
  title: string;
  alt: string;
}
@Component({
  selector: 'app-second-vehicles',
  templateUrl: './second-vehicles.component.html',
  styleUrls: ['./second-vehicles.component.scss'],
})
export class SecondVehiclesComponent implements OnInit {
  selected = 'none';
  form: FormGroup;
  index: Array<{ index: number }> = [];
  ind = 0;
  brandSelected: Array<{ index: number, brand: string }> = [];
  images: IImage[] = [];
  vehicles: IVehicle[] = [];
  vehiclesSelected: IVehicle[] = [];
  vehiclesSelectedLowerPrice: any;
  vehiclesSelectedHighPrice: any;
  vehiclesFiltered: IVehicle[] = [];
  dealers: IDealer[] = [];
  selectedVehicles: IVehicle[] = [];
  vehiclesProfile: IImage[] = [];
  public titleGroupName: string = ``;
  public showContactForm: boolean = false;
  public companyID: number;
  public unitID: number;
  public group: any;
  public groupFiltered: any;

  config = {
    lazy: true,
    navigation: true,
    breakpoints: {
      '480': {
        slidesPerView: 1,
      },
      '675': {
        slidesPerView: 2,
      },
      '950': {
        slidesPerView: 3,
      },
      '1280': {
        slidesPerView: 4,
      },
    },
  };

  constructor(
    private vehicleService: VehicleService,
    private formBuilder: FormBuilder,
    private router: Router,
    // private dialogRefVehiclesComponent: MatDialogRef<VehiclesComponent>,
  ) { }

  public routeScreen(): void {
    this.brandSelected.map(item => {
      this.vehiclesSelected.splice(0, 0, ...this.vehicles.filter(it => it.brand == item?.brand));
    })
    this.vehiclesSelectedLowerPrice = this.vehiclesSelected.map((low => low.price)).sort((low, high) => low - high);
    this.vehiclesSelectedHighPrice = this.vehiclesSelected.map((low => low.price)).sort((low, high) => high - low);
    if (this.vehiclesSelectedHighPrice[0] == this.vehiclesSelectedLowerPrice[0]) {
      this.router.navigate(['/root/vehicles-results'],
        {
          state: {
            data: {
              lowerPrice: this.vehiclesSelectedLowerPrice[0],
              highPrice: this.vehiclesSelectedHighPrice[0],
              vehicles: this.vehiclesSelected,
              unitID: this.unitID,
              companyID: this.companyID,
              groups: this.group,
            }
          }
        }
      );
    } else {
      this.router.navigate(['/root/second-slider'],
        {
          state: {
            data: {
              lowerPrice: this.vehiclesSelectedLowerPrice[0],
              highPrice: this.vehiclesSelectedHighPrice[0],
              vehicles: this.vehiclesSelected,
              unitID: this.unitID,
              companyID: this.companyID,
              groups: this.group,
            }
          }
        }
      );
    }
  }

  ngOnInit() {
    this.unitID = history.state.unitID;
    this.companyID = history.state.companyID;
    this.group = JSON.parse(history.state.group);

    this.form = this.formBuilder.group({
      controlStepper: new FormControl(null, [Validators.required])
    });
    this.vehicleService.getVehicleModelsByGrupo(this?.group?.id).subscribe((res) => {
      res = res.data.results;
      res.forEach((item) => {
        const vehicle: IVehicle = {
          id: item?.codigoModelo,
          thumbnail: item?.imagem,
          picture: item?.imagem,
          name: item?.nome,
          brand: item?.marca,
          model: item?.modelo,
          descricaoEquipamento: item?.descricaoEquipamento,
          descricaoTarifaAssinatura: item?.descricaoTarifaAssinatura,
          version: item?.versao,
          diversos: item?.diversos?.map((_item) => _item),
          price: item?.valor,
          colors: null,
          serialItems: item?.itensSerie?.map((_item) => _item),
          protections: item?.protecoes
        };
        this.vehicles.push(vehicle);
        this.vehiclesFiltered.push(vehicle);
      })
      this.groupFiltered = Array.from([...new Set(this.vehicles.map(item => item?.brand))]);
      this.images = res.map((dt) => {
        return {
          image: dt.imagem,
          thumbImage: dt.imagem,
          title: dt.nome,
          alt: dt.modelo,
        };
      });
      this.images.forEach((_, index) => {
        if (this.images[index + 1]) {
          if (this.images[index].title !== this.images[index + 1].title) {
            this.vehiclesProfile.push(this.images[index]);
          }
        }
      });
      this.vehiclesProfile.push(this.images[this.images?.length - 1]);
      this.showContactForm = true;
    });
  }

  public serialItems(vehicle: IVehicle): string {
    return vehicle.serialItems.join(' | ');
  }

  public choiceBrand(index: number, brandSelected: string): void {
    const indexFiltered = this.index.filter(i => i.index == index);
    if (indexFiltered?.length < 1) {
      this.index.push({ index: index });
      this.ind = this.ind + 1;
    } else {
      this.index = this.index.filter(i => i.index !== index);
      this.ind = this.ind - 1;
    }
    const currentIndex = this.brandSelected.filter(i => i.index == index);
    if (currentIndex?.length > 0) {
      this.brandSelected = this.brandSelected.filter(i => i.index !== index);
    } else {
      if (this.brandSelected.filter(i => i.index !== index)) {
        this.brandSelected.push({ index: index, brand: brandSelected })
      }
    }
  }
}
