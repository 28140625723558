import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { CanActivateChildGuard } from '../CanActivateChildGuard';
import { InitializationComponent } from '../initialization/initialization.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { BusinessGroupComponent } from './views/signature-steps/business-group/business-group.component';
import { VehicleDetailsComponent } from './views/signature-steps/business-group/vehicles/vehicle-details/vehicle-details.component';
import { VehiclesComponent } from './views/signature-steps/business-group/vehicles/vehicles.component';
import { RegisterComponent } from './views/signature-steps/register/register.component';
import { SuccessScreenComponent } from './views/signature-steps/register/sign-success-screen/success-screen.component';
import { SecondRouteComponent } from './views/signature-steps/second-route/second-route.component';
import { SecondSliderComponent } from './views/signature-steps/second-route/vehicles/second-slider/second-slider.component';
import { VehiclesResultsComponent } from './views/signature-steps/second-route/vehicles/second-slider/vehicles-results/vehicles-results.component';
import { SecondVehiclesComponent } from './views/signature-steps/second-route/vehicles/second-vehicles.component';
import { SignatureStepsComponent } from './views/signature-steps/signature-steps.component';

const routes: Routes = [
  {
    path: 'root', canActivateChild: [CanActivateChildGuard],
    children: [
      { path: 'signature', component: SignatureStepsComponent },
      { path: 'business-group', component: BusinessGroupComponent, },
      { path: 'register', component: RegisterComponent },
      { path: 'vehicle', component: VehiclesComponent },
      { path: 'vehicle-details', component: VehicleDetailsComponent },
      { path: 'success-screen', component: SuccessScreenComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'second-route', component: SecondRouteComponent },
      { path: 'second-vehicle', component: SecondVehiclesComponent },
      { path: 'second-slider', component: SecondSliderComponent },
      { path: 'vehicles-results', component: VehiclesResultsComponent}
    ]
  },
  { path: 'signIn', loadChildren: () => import('./views/signature-steps/sign-in/sign-in.module').then(m => m.SignInModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
})

export class MainRoutingModule { }
