import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import ICities from 'src/app/interfaces/cities.interface';
import IGroup from 'src/app/interfaces/group.interface';
import IUnit from 'src/app/interfaces/unit.interface';
import { CitiesService } from '../../services/cities.service';
import { GroupService } from '../../services/group.service';
import { LocalStorageService } from '../../services/localservice.service';
import { SubscribersService } from '../../services/subscribers.service';
import { BusinessGroupComponent } from './business-group/business-group.component';
import { RegisterComponent } from './register/register.component';
import { SelectUnitComponent } from './select-unit/select-unit.component';

@Component({
  selector: 'app-signature-steps',
  templateUrl: './signature-steps.component.html',
  styleUrls: ['./signature-steps.component.scss'],
})
export class SignatureStepsComponent implements OnInit {
  stepIndex = {
    selectUnit: 0,
    businessGroup: 1,
    vehicle: 2,
    sign: 3,
    complete: 4,
  };
  isLinear: boolean = true;
  lastIndexStep: number = 0;
  vehicleFormGroup: FormGroup;
  subscriberDataFormGroup: FormGroup;

  cities: Array<ICities>;
  states: any[];
  showUnitNotFound: boolean;

  groups: IGroup[] = [];

  city: any = '';
  group: any = '';
  vehicle: any = '';

  public unitID: number;
  public companyID: number;
  public units: any;

  @ViewChild('selectUnit') selectUnitComponent: SelectUnitComponent;
  @ViewChild('businessGroup') businessGroupComponent: BusinessGroupComponent;
  @ViewChild('register') registerComponent: RegisterComponent;
  @ViewChild('stepper') private stepper: MatStepper;

  get unitStepControl() {
    return this.selectUnitComponent ? this.selectUnitComponent.form : {};
  }

  get businessStepControl() {
    return this.businessGroupComponent ? this.businessGroupComponent.form : {};
  }

  get signStepControl() {
    return this.registerComponent ? this.registerComponent.form : {};
  }

  get unitNameAndAddress() {
    const selectUnit = this.selectUnitComponent?.form?.getRawValue()?.unit;
    return `${selectUnit?.description} - ${selectUnit?.neighborhood || ''} - ${
      selectUnit?.city
    }/${selectUnit?.uf}`;
  }

  get groupDescription() {
    const businessGroup =
      this.businessGroupComponent?.form?.getRawValue()?.controlStepper?.group;
    return businessGroup?.descricao;
  }

  get vehicleContent() {
    const vehicle =
      this.businessGroupComponent?.form?.getRawValue()?.controlStepper?.vehicle;
    const contract =
      this.businessGroupComponent?.form?.getRawValue()?.controlStepper?.selected
        ?.contract;
    const color =
      this.businessGroupComponent?.form?.getRawValue()?.controlStepper?.selected
        ?.color;
    const optionals =
      this.businessGroupComponent?.form?.getRawValue()?.controlStepper?.selected
        ?.optionals;
    return [
      `Modelo: ${vehicle?.model}`,
      `Custo Mensal: R$ ${vehicle?.price?.toFixed(2)}`,
      `Período: ${contract ? `${contract} meses` : ''}`,
      `Cor: ${color?.name}`,
      `Opcionais: ${
        optionals ? optionals.map((item: any) => `${item.name}; `) : ''
      }`,
      `Franquia de KM Mensal: ${
        this.businessGroupComponent?.form?.getRawValue()?.controlStepper
          ?.selected?.monthKmFranchise
      }`,
    ];
  }

  constructor(
    private citiesService: CitiesService,
    private groupService: GroupService,
    private subscribersService: SubscribersService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.localStorageService.lastVehicleSelected = null;
  }

  private loadStates(): void {
    const states = this.citiesService.getStates();
    this.states = states;
    this.loadCurrentPosition();
  }

  private loadCurrentPosition(): void {
    navigator.geolocation.getCurrentPosition((res) => {
      // res.coords.latitude, res.coords.longitude
      this.citiesService
        .take_city([res.coords.latitude, res.coords.longitude])
        .subscribe((nextcity) => {
          const state = this.states.find(
            (item) => item.descricao === nextcity['address']?.state
          );
          if (state) {
            this.selectUnitComponent.form.get('uf').setValue(state.uf);
          }
        });
    });
  }

  public loadCitiesEvent(event): void {
    const data = {
      codigoMunicipio: event?.codigoMunicipio,
      municipio: event?.municipio,
      uf: event?.uf,
      codigoEmpresa: event?.unidades?.codigoEmpresa,
      codigoUnidade: event?.unidades?.codigoUnidade,
      descricaoUnidade: event?.unidades?.descricaoUnidade,
      logotipo: event?.unidades?.logotipo,
      unidades: event?.unidades,
    };
    this.units = data;
    console.log(event);
    this.showUnitNotFound = this.units?.length === 0 ? true : false;
  }

  public unitSelectedEvent(event: IUnit): void {
    if (event) {
      console.log(event);
      this.unitID = event.codigoUnidade;
      this.companyID = event.codigoEmpresa;
      this.localStorageService.codigoUnidade = event.codigoUnidade;
      this.localStorageService.codigoEmpresa = event.codigoEmpresa;
      this.groupService.getAll().subscribe((res) => {
        console.log(res);
        this.groups = res?.data?.results;
        this.selectUnitComponent.form.get('groups').setValue(res);
        if (this.groups?.length === 0) {
          this.matSnackBar.open(
            'Nenhum grupo foi retornado para a unidade selecionada.',
            null,
            { duration: 6000 }
          );
        }
        if (this.groups?.length > 0) {
          this.localStorageService.logoEmpresa = event.logotipo;
          this.router.navigate(['/root/business-group'], {
            state: {
              groups: JSON.stringify(this.groups),
              unitID: this.unitID,
              companyID: this.companyID,
            },
          });
        }
      });
    }
  }
}
