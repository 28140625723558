import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/development.env";
import { LocalStorageService } from "./localservice.service";

@Injectable({
    providedIn: 'root',
})

export class ValidCPF {

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService
    ) {

    }

    testCPF(strCPF) {
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000" || strCPF === "11111111111" ||
            strCPF === "22222222222" || strCPF === "33333333333" ||
            strCPF === "44444444444" || strCPF === "55555555555" ||
            strCPF === "66666666666" || strCPF === "77777777777" ||
            strCPF === "88888888888" || strCPF === "99999999999"
        ) return false;

        for (let index = 1; index <= 9; index++) {
            let endString = index - 1;
            Soma = Soma + parseInt(strCPF.substring(index - 1, index)) * (11 - index);
        }

        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        let aux = parseInt(strCPF.substring(9, 10));
        if (Resto !== aux) return false;

        Soma = 0;

        for (let index = 1; index <= 10; index++) {
            Soma = Soma + parseInt(strCPF.substring(index - 1, index)) * (12 - index);
        }

        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        aux = parseInt(strCPF.substring(10, 11));
        if (Resto !== aux) return false;
        return true;
    }
    get(cpf: any) {
        let params = new HttpParams();
        params = params.append('cpf', cpf).append('codigoIdentificador', this.localStorageService.codigoIdentificador);
        return this.http.get(`${environment.api.base_url}/valida-cpf`, {
            params: params,
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "X-API-Key": `${this.localStorageService.token}`,
            })
        });
    }
}