import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignatureStepsComponent } from './signature-steps.component';

const routes: Routes = [
    { path: '', component: SignatureStepsComponent }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SignatureStepsRoutingModule { }
