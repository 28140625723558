import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import ICities from 'src/app/interfaces/cities.interface';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
    providedIn: 'root',
})
export class CitiesService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
    ) { }

    take_city(atual) {
        return this.http.get(`${environment.geolocationApi.base_url}` + "&lat=" + atual[0] + "&lon=" + atual[1]);
    }

    getCities(): Observable<[ICities]> {
        let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
        return this.http.get<[any]>(`${environment.api.base_url}/municipios-geral`, {
            params: params,
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "X-API-Key": `${this.localStorageService.token}`,
            })
        });
    }

    getStates() {
        return [
            { descricao: 'Acre', uf: 'AC' },
            { descricao: 'Alagoas', uf: 'AL' },
            { descricao: 'Amapá', uf: 'AP' },
            { descricao: 'Amazonas', uf: 'AM' },
            { descricao: 'Bahia', uf: 'BA' },
            { descricao: 'Ceará', uf: 'CE' },
            { descricao: 'Distrito Federal', uf: 'DF' },
            { descricao: 'Espírito Santo', uf: 'ES' },
            { descricao: 'Goiás', uf: 'GO' },
            { descricao: 'Maranhão', uf: 'MA' },
            { descricao: 'Mato Grosso', uf: 'MT' },
            { descricao: 'Mato Grosso do Sul', uf: 'MS' },
            { descricao: 'Minas Gerais', uf: 'MG' },
            { descricao: 'Pará', uf: 'PA' },
            { descricao: 'Paraíba', uf: 'PB' },
            { descricao: 'Paraná', uf: 'PR' },
            { descricao: 'Pernambuco', uf: 'PE' },
            { descricao: 'Piauí', uf: 'PI' },
            { descricao: 'Rio de Janeiro', uf: 'RJ' },
            { descricao: 'Rio Grande do Norte', uf: 'RN' },
            { descricao: 'Rio Grande do Sul', uf: 'RS' },
            { descricao: 'Rondônia', uf: 'RO' },
            { descricao: 'Roraima', uf: 'RR' },
            { descricao: 'Santa Catarina', uf: 'SC' },
            { descricao: 'São Paulo', uf: 'SP' },
            { descricao: 'Sergipe', uf: 'SE' },
            { descricao: 'Tocantins', uf: 'TO' },
        ]
    }
}