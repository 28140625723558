import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from '../main/services/localservice.service';
import { environment } from 'src/environments/development.env';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private readonly internalServerError = 'Erro inesperado, tente novamente.';

    constructor(
        private _snackBar: MatSnackBar,
        private router: Router,
        private localStorageService: LocalStorageService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = this.internalServerError;
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else if (error.status === 401) {
                        this.router.navigateByUrl('');
                    }

                    if (error.url === `${environment.api.base_url}/auth`) {
                        this.localStorageService.messageAuthControl = true;
                    }

                    this._snackBar.open(error.error.message, null, { duration: 6000 });
                    return throwError(error.error.message);
                })
            )
    }
}