import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { SocialAuthService } from 'angularx-social-login';
import { PersonalData } from 'src/app/interfaces/user-data.interface';
import { UserData } from '../../services/get-user.service';
import { LocalStorageService } from '../../services/localservice.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mode: string;
  img: string;
  public userPersonalData: PersonalData;

  constructor(
    private socialAuthService: SocialAuthService,
    public afAuth: AngularFireAuth,
    public userData: UserData,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this.mode = (window.innerWidth <= 1400) ? "over" : "side";
    this.getUserData()
    this.img = this.localStorageService.logoEmpresa
  }

  onResize(event) {
    this.mode = (event.target.innerWidth <= 1400) ? "over" : "side";
  }

  public signOut(): void {
    this.socialAuthService.signOut().catch(error => console.log(error));
    this.afAuth.signOut().catch(error => console.log(error));
  }


  getUserData(): void {
    if (this.localStorageService.fireToken) {
      this.userData.getUserData(this.localStorageService.fireToken).subscribe((res: any) => {
        res = res.data;
        this.userPersonalData = {
          nomeCompleto: res?.informacoesPessoais?.nomeCompleto,
          cpf: res?.informacoesPessoais?.cpf,
          rg: res?.informacoesPessoais?.rg,
          codigoCliente: res?.informacoesPessoais?.codigoCliente,
          codigoFirebase: res?.informacoesPessoais?.codigoFirebase,
          dataEmissaoIdentidade: res?.informacoesPessoais?.dataEmissaoIdentidade,
          orgaoEmissorIdentidade: res?.informacoesPessoais?.orgaoEmissorIdentidade,
          dataNascimento: res?.informacoesPessoais?.dataNascimento,
          sexo: res?.informacoesPessoais?.sexo,
          estadoCivil: res?.informacoesPessoais?.estadoCivil,
          profissao: res?.informacoesPessoais?.profissao,
          renda: res?.informacoesPessoais?.renda,
          telefone: res?.informacoesPessoais?.telefone
        };
      });
    }
  }
}
