import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitializationComponent } from './initialization.component';
import { MaterialModule } from '../material.module';
import { MainModule } from '../main/main.module';
import { InitializationRoutingModule } from './initialization-routing';

@NgModule({
  declarations: [
    InitializationComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    InitializationRoutingModule,
    MainModule,
  ],
  exports: [InitializationComponent],
})
export class InitializationModule { }
