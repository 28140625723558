<h1 mat-dialog-title>Condições Gerais de Locação</h1>

<div mat-dialog-content>
    <p>
        O processo de contratação se inicia com a escolha do veículo, condições de aluguel e recebimento dos dados pessoais do solicitante.
        O Barigui Signature, carro por assinatura da Barigui Locadora, é destinado a pessoas físicas com idade mínima de 21 anos e com pelo menos 2 anos de experiência como condutor (condutores com Carteira Nacional de Habilitação – CNH válida e emitida a pelo menos 2 anos).
    </p>
    
    
    <p>
        Informações sobre o processo de contratação:
    </p>
    
    <ul>
        <li>A solicitação do veículo a ser locado, modelo, cor e opcionais, está associada a disponibilidade de estoque e será analisada juntamente com os documentos enviados, que estão sujeitos à aprovação;</li>
        <li>Uma vez aprovada a solicitação, todas as parcelas serão pagas exclusivamente através de cartão de crédito;</li>
        <li>O valor da mensalidade do serviço está atrelado à quilometragem, tempo de locação do veículo e serviços adicionais solicitados. O valor dos serviços adicionais, quando disponíveis, serão divididos pela quantidade de parcelas de aluguel e cobrados juntamente com a mensalidade da locação. Caso a quilometragem final contratada seja excedida, o valor será cobrado ao final do contrato, conforme plano contratado, e diretamente em cartão de crédito;</li>
        <li>O contrato de locação será enviado para assinatura após análise e aprovação de crédito;</li>
        <li>Os dados pessoais coletados serão utilizados para prévia análise de crédito e na confecção do contrato de locação;</li>
        <li>O prazo de análise de crédito é de até 72 horas, contados a partir da disponibilização das informações e documentos requisitados. O retorno ao solicitante será dado por telefone;</li>
        <li>A retirada do veículo locado deverá ser realizada no local previamente selecionado na solicitação, após a assinatura do contrato de locação e em prazo a ser informado pela locadora;</li>
        <li>As revisões periódicas, assim como as manutenções não cobertas pelos planos contratados e eventuais reparos, devem obrigatoriamente ser realizados na rede de concessionárias autorizadas da marca, para que o veículo não perca a garantia de fábrica. O pré-agendamento de qualquer serviço deve ser realizado por meio do telefone 0800 960 6060;</li>
        <li>As condições da Proteção de Riscos incluem cobertura de casco e danos a terceiros - RCF-V (Responsabilidade Civil Facultativa de Veículos).</li>
    </ul>
    
    <p>
        Nossa equipe está à disposição para esclarecimentos, ou informações, de segunda a sexta das 8h às 18h através do telefone (41) 3411 4700.
    </p>
</div>
