<form [formGroup]="form">
  <p class="formTitle">{{ title }}</p>
  <p class="formSubtitle">{{ subtitle }}</p>

  <div fxLayout="row wrap">
    <mat-form-field appearance="legacy" fxFlex="100">
      <mat-label>Nome</mat-label>
      <input
        matInput
        type="text"
        placeholder="Digite seu nome"
        formControlName="name"
      />
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-between">
    <mat-form-field appearance="legacy" fxFlex="32">
      <mat-label>E-mail</mat-label>
      <input
        matInput
        type="email"
        placeholder="Digite seu e-mail"
        formControlName="email"
      />
    </mat-form-field>
    <mat-form-field appearance="legacy" fxFlex="32">
      <mat-label>CPF</mat-label>
      <input
        matInput
        type="text"
        placeholder="Digite seu CPF"
        mask="000.000.000-00"
        formControlName="cpf"
      />
    </mat-form-field>
    <mat-form-field appearance="legacy" fxFlex="32">
      <mat-label>Telefone</mat-label>
      <input
        matInput
        type="text"
        placeholder="Digite seu telefone"
        [mask]="phoneMask"
        formControlName="phone"
      />
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-between">
    <mat-form-field appearance="legacy" fxFlex="32">
      <mat-label>Marca</mat-label>
      <input
        matInput
        type="email"
        placeholder="Digite a marca do veículo"
        formControlName="brand"
      />
    </mat-form-field>
    <mat-form-field appearance="legacy" fxFlex="32">
      <mat-label>Modelo</mat-label>
      <input
        matInput
        type="text"
        placeholder="Digite o modelo do veículo"
        formControlName="model"
      />
    </mat-form-field>
    <mat-form-field appearance="legacy" fxFlex="32">
      <mat-label>Versão</mat-label>
      <input
        matInput
        type="text"
        placeholder="Digite a versão do modelo do veículo"
        formControlName="version"
      />
    </mat-form-field>
  </div>

  <div class="submitWrapper" fxLayoutAlign="flex-end center">
    <button mat-raised-button color="primary" id="button" (click)="submit()">
      <span> Enviar </span>
    </button>
  </div>
</form>
