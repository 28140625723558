<div fxLayoutAlign="center center" fxLayout="column" style="height: 80vh;">
    <div id="div" fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutAlign="center center">
        <mat-card class="mat-elevation-z0" id="titleContainer" fxFlex="60" fxLayout="column">
            <mat-card-subtitle id="mat-card-subtitle-big">
                Parabéns! Sua conta foi criada com sucesso. Vamos continuar?
            </mat-card-subtitle>
            <br><br>
        </mat-card>
    </div>
    <mat-card-actions fxLayout="row wrap" fxLayoutAlign="center center">
        <button fxLayoutAlign="center center" mat-raised-button color="primary" id="button"
            (click)="buttonSelected('home')">
            <span>
                Voltar a ver carros
            </span>
        </button>
        <button fxLayoutAlign="center center" mat-raised-button color="primary" id="button"
            (click)="buttonSelected('dashboard')">
            <span>
                Acessar meu perfil
            </span>
        </button>
    </mat-card-actions>
</div>
<div id="footer">
    <div fxLayoutAlign="center center">
        <div style="position: fixed; bottom: 0;" fxLayout="row">
            <app-powered-by></app-powered-by>
        </div>
    </div>
</div>