import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Router, Params } from '@angular/router';
import IGroup from 'src/app/interfaces/group.interface';
import { VehiclesComponent } from './vehicles/vehicles.component';

@Component({
  selector: 'app-business-group',
  templateUrl: './business-group.component.html',
  styleUrls: ['./business-group.component.scss']
})
export class BusinessGroupComponent implements OnInit {
  form: FormGroup;

  unitID: number;
  companyID: number;
  groups: IGroup[] = [];

  config = {
    lazy: true,
    navigation: true,
    breakpoints: {
      '480': {
        slidesPerView: 1,
      },
      '675': {
        slidesPerView: 2,
      },
      '950': {
        slidesPerView: 3,
      },
      '1280': {
        slidesPerView: 4,
      },
    },
  };

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.unitID = history.state.unitID;
    console.log(history.state.unitID)
    if (this.unitID != null) {
      this.companyID = history.state.companyID;
      this.groups = JSON.parse(history.state.groups);
      console.log(history.state.groups) 
      console.log(this.groups)
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      controlStepper: new FormControl(null, Validators.required),
    });
  }

  private routeScreen(group: IGroup): void {
    this.router.navigate(['/root/vehicle'], { state: { group: JSON.stringify(group), unitID: this.unitID, companyID: this.companyID } });
  }

  public routeSecondWay(): void {
    this.router.navigate(['/root/second-route'], { state: { groups: JSON.stringify(this.groups), unitID: this.unitID, companyID: this.companyID } });
  }

  public choiceGroup(group: IGroup): void {
    this.routeScreen(group);
  }
}
