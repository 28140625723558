import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import * as firebase from 'firebase';
import { catchError } from 'rxjs/operators';
import Role from 'src/app/interfaces/role.interface';
import { User } from 'src/app/interfaces/user.interface';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { FirestoreAuthService } from 'src/app/main/services/auth-firebase.service';
import { AuthService } from 'src/app/main/services/auth.service';
import { CitiesService } from 'src/app/main/services/cities.service';
import { LocalStorageService } from 'src/app/main/services/localservice.service';
import { SubscribersService } from 'src/app/main/services/subscribers.service';
import { ValidCPF } from 'src/app/main/services/valid-cpf.service';
import { TermGeneralConditionsComponent } from 'src/app/main/shared/term-general-conditions/term-general-conditions.component';

export interface Field {
  title: string;
  name: string;
  placeholder: string;
  required: boolean;
  type: string;
  hint?: string;
  options?: Array<string>;
  mask?: string;
  accept?: string;
}

export interface Label {
  cad: string;
  docs: string;
  pay: string;
  sign: string;
  done: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
@HostListener('window:resize', ['$event'])
export class RegisterComponent implements OnInit {
  selectedCar: any;
  public vehicle: IVehicle;
  public companyID: number;
  public unitID: number;
  public user: User;
  public groups;
  public googleToken;

  docsfields: Array<Field>;

  docsfieldsnames: Object;

  contactform: FormGroup;
  docsform: FormGroup;

  labels: Label;
  isBig: boolean;
  checkTerm: boolean = false;

  rownum: number;
  colsnum: number;
  spacecolnum: number;
  centerspacecolnum: number;
  bpbtnspace: number;
  bpbtncols: number;

  roles: Array<Role>;

  form: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  confirmationResult: any;
  states: Array<any> = [];
  genders: Array<string> = ['Masculino', 'Feminino'];
  nationalities: Array<string> = ['Brasileiro', 'Estrangeiro'];
  maritalStatus: Array<string> = [
    'Solteiro',
    'Casado',
    'Divorciado',
    'Viúvo',
    'Separado',
  ];
  hide: boolean = true;
  hide2: boolean = true;
  public proofHomeAddressFile = { name: '', base64: null };
  public cnhFile = { name: '', base64: null };
  public proofIncomeFile = { name: '', base64: null };
  public step: number = 0;
  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  };
  public recaptchaVerifier: any;
  public recaptchaVerifier2: any;
  public passwordMessage: string;
  public sent: boolean = false;
  public sent2: boolean = false;
  public hideResentButton: boolean = false;
  minAge: any;
  isAdultByDate: boolean;
  isValidPin: boolean;
  hide3: boolean = true;
  count = 59;
  timeout: any;
  public isMobile: boolean = false;
  phoneMask = '(00) 0000-0000';

  constructor(
    private _formBuilder: FormBuilder,
    private citiesService: CitiesService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private _snackBar: MatSnackBar,
    private subscribersService: SubscribersService,
    public afAuth: AngularFireAuth,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private firestoreAuthService: FirestoreAuthService,
    public ngZone: NgZone,
    public userCPFValidation: ValidCPF
  ) {}

  ngOnInit() {
    // firebase.initializeApp(environment.firebaseConfig);
    //this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    this.unitID = history?.state?.data?.unitID;
    if (this?.unitID) {
      this.companyID = history?.state?.data?.companyID;
      this.vehicle = history?.state?.data?.vehicle;
      this.selectedCar = history?.state?.data?.selectedCar;
      this.groups = history?.state?.data?.groups;
      if (!this.companyID && !this.companyID) {
        this.router.navigate(['/root/signature']);
        this.openSnackBar(
          'O código empresa e unidade não foram encontrados. Tente novamente. '
        );
      }
    }
    if (window.innerWidth < 960) this.isMobile = true;
    this.buildForm();
    this.loadSates();
    this.calculateMinAge();
  }

  dispatch(input: HTMLInputElement) {
    if (input) {
      input.dispatchEvent(new Event('input'));
    }
  }

  // async onSubmit(phone) {
  //   this.sent = false;
  //   const phoneNumberString = phone.toString();
  //   this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
  //   await firebase.auth().signInWithPhoneNumber('+55' + phoneNumberString, this.recaptchaVerifier)
  //     .then(result => {
  //       this.sent = true;
  //       this.confirmationResult = result;
  //     }).catch(res => {
  //     })
  // };

  async onSubmitAgain(phone) {
    this.sent2 = false;
    this.hideResentButton = true;
    const phoneNumberString = phone.toString();
    this.recaptchaVerifier2 = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container2'
    );
    await firebase
      .auth()
      .signInWithPhoneNumber('+55' + phoneNumberString, this.recaptchaVerifier2)
      .then((result) => {
        this.sent2 = true;
        this.confirmationResult = result;
      })
      .catch((res) => {});
  }

  registerNewUser() {
    if (
      this.secondForm.controls['password'].value !==
      this.secondForm.controls['confirmPassword'].value
    ) {
      this.openSnackBar('As senhas devem ser iguais');
    } else if (this.secondForm.valid) {
      if (!this.companyID && !this.companyID) {
        this.router.navigate(['/root/signature']);
        this.openSnackBar(
          'O código empresa e unidade não foram encontrados. Tente novamente. '
        );
      }
      this.afAuth
        .createUserWithEmailAndPassword(
          this.form.controls['email'].value,
          this.secondForm.controls['password'].value
        )
        .then((result) => {
          this.isValidPin = true;
          this.user = {
            provider: result?.user?.providerId,
            emailVerified: null,
            uid: result?.user?.uid,
            id: null,
            email: result?.user?.email,
            name: null,
            photoUrl: null,
            firstName: null,
            lastName: null,
            authToken: null,
            idToken: null,
            authorizationCode: null,
            response: null,
          };

          const body = this.onSubmitNode(result?.user?.uid);
          this.subscribersService
            .post(body)
            .pipe(
              catchError((error) => {
                this.afAuth.currentUser.then((user) => {
                  user?.delete();
                });

                throw error;
              })
            )
            .subscribe((i: any) => {
              this._snackBar.open(
                i.data.message[0].toUpperCase() +
                  i.data.message.toLowerCase().slice(1),
                '',
                { duration: 3000 }
              );
              this.authService
                .postAuth(
                  this.form.controls['email'].value,
                  this.secondForm.controls['password'].value
                )
                .subscribe((it: any) => {
                  console.log(it);
                  if (it?.data?.token) {
                    let img = this.localStorageService.logoEmpresa;
                    let codigoIdentificador =
                      this.localStorageService.codigoIdentificador;
                    let codigoEmpresa = this.localStorageService.codigoEmpresa;
                    this.localStorageService.clear();
                    this.localStorageService.codigoEmpresa = codigoEmpresa;
                    this.localStorageService.codigoIdentificador =
                      codigoIdentificador;
                    this.localStorageService.logoEmpresa = img;
                    this.localStorageService.token = it?.data?.token;
                    this.localStorageService.fireToken = result?.user?.uid;
                    this.router.navigate(['root/success-screen']);
                  }
                });
            });
        })
        .catch((e) => {
          if (e?.code === 'auth/email-already-in-use') {
            this._snackBar.open('O e-mail já está em uso por outra conta', '', {
              duration: 8000,
            });
          } else {
            this._snackBar.open('Erro ao registrar usuário', '', {
              duration: 8000,
            });
          }
        });
      // this.router.navigate(['/root/vehicle-details'], { state: { data: { vehicle: vehicle, group: this.vehicles } } });
    }
  }

  onSubmitNode(codigo) {
    const subscriptionData = {
      codigoEmpresa: this.companyID,
      codigoUnidade: this.unitID,
      codigoFirebase: String(codigo),
      veiculo: {
        descricaoModelo: this?.vehicle?.model,
        codigoModelo: this?.vehicle?.id,
        codigoTarifa: parseInt(
          this.selectedCar?.monthKmFranchise?.codigoTarifa
        ),
        codigoCor: parseInt(this.selectedCar?.color?.id),
        tempoContratoMeses: parseInt(
          this.selectedCar?.monthKmFranchise?.prazoTarifa
        ),
        codigoGrupo: parseInt(this?.groups?.id),
      },
      diversos: this.selectedCar.diversos,
      assinante: {
        nome: this.form.controls['name'].value,
        cpf: this.form.controls['cpf'].value,
        dataNascimento: new Date(
          this.form.controls['birthDate'].value.split('/').reverse().join('/')
        ).toISOString(),
        email: this.form.controls['email'].value,
        telefone: this.form.controls['phone'].value,
      },
    };

    return subscriptionData;
  }

  onSubmitNodeBySocialLogin(user, uid) {
    const subscriptionData = {
      codigoEmpresa: this.companyID,
      codigoUnidade: this.unitID,
      codigoFirebase: uid,
      veiculo: {
        descricaoModelo: this?.vehicle?.model,
        codigoModelo: this?.vehicle?.id,
        codigoTarifa: parseInt(
          this.selectedCar?.monthKmFranchise?.codigoTarifa
        ),
        codigoCor: parseInt(this.selectedCar?.color?.id),
        tempoContratoMeses: parseInt(
          this.selectedCar?.monthKmFranchise?.prazoTarifa
        ),
        codigoGrupo: this?.groups?.id,
      },
      diversos: this.selectedCar.diversos,
      assinante: {
        nome: user?.name,
        email: user?.email,
      },
    };
    this.subscribersService.post(subscriptionData).subscribe((i) => {
      this._snackBar.open(
        i.data.message[0].toUpperCase() + i.data.message.toLowerCase().slice(1),
        '',
        { duration: 3000 }
      );
    });
  }

  private buildForm(): void {
    this.form = this._formBuilder.group({
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
      ]),
      birthDate: new FormControl(null, [Validators.required]),
      birthDate1: new FormControl(null),
      cpf: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required]),
    });
    this.secondForm = this._formBuilder.group({
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[0-9]).{8,}'),
      ]),
      confirmPassword: new FormControl(null, [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[0-9]).{8,}'),
      ]),
    });
    this.thirdForm = this._formBuilder.group({
      pinCode: new FormControl(null, [Validators.required]),
    });

    this.form.controls['phone'].valueChanges.subscribe((value) => {
      value.length > 10
        ? (this.phoneMask = '(00) 0 0000-0000')
        : (this.phoneMask = '(00) 0000-00009');
    });
  }

  private loadSates() {
    this.states = this.citiesService.getStates();
  }

  public dateValidation() {
    const date = new Date(
      this.form.controls['birthDate'].value.split('/').reverse().join('/')
    );
    if (date.getFullYear() > this.minAge.getFullYear()) {
      this.isAdultByDate = false;
      this.form.controls['birthDate1'].setErrors({ isNotAdult: true });
    } else {
      this.form.get('birthDate1').setErrors(null);
      this.isAdultByDate = true;
    }
  }

  public backStep() {
    if (this.step != 0) this.step--;
  }

  public nextStep(): void {
    const date = new Date(
      this.form.controls['birthDate'].value.split('/').reverse().join('/')
    );
    if (date.getFullYear() > this.minAge.getFullYear()) {
      this.isAdultByDate = false;
      this.form.controls['birthDate1'].setErrors({ isNotAdult: true });
      this.openSnackBar('Você deve ter no minimo 18 anos para prosseguir');
    } else {
      if (this.form.valid) {
        this.isAdultByDate = true;
        this.step++;
        if (!this.companyID && !this.companyID) {
          this.router.navigate(['/root/signature']);
          this.openSnackBar(
            'O código empresa e unidade não foram encontrados. Tente novamente. '
          );
        }
        // this.onSubmit(this.form.controls['phone'].value);
      }
    }
  }

  timeoutForResendSMS() {
    this.timeout = setInterval(() => {
      if (this.count > 0) {
        this.count -= 1;
      } else {
        clearInterval(this.timeout);
      }
    }, 1000);
  }

  openSnackBar(value) {
    if (value?.length > 1) this._snackBar.open(value, '', { duration: 3000 });
  }

  calculateMinAge(): void {
    var today = new Date();
    var minAge = 18;
    this.minAge = new Date(
      today.getFullYear() - minAge,
      today.getMonth(),
      today.getDate()
    );
  }

  private routeScreen(): void {
    this.router.navigate(['root/success-screen']);
  }

  public viewPassword() {
    this.hide = !this.hide;
  }

  public viewPassword2() {
    this.hide2 = !this.hide2;
  }

  public viewPassword3() {
    this.hide3 = !this.hide3;
  }

  validCPF(cpf) {
    if (this.userCPFValidation.testCPF(cpf)) {
      this.form.get('cpf').setErrors(null);
    } else {
      this.form.controls['cpf'].setErrors({ incorrect: true });
    }
    if (this.form.controls['cpf']?.value?.length >= 11)
      this.userCPFValidation
        .get(this.form.controls['cpf']?.value)
        .subscribe((i: any) => {
          if (!i?.data?.isUnique) {
            // this.form.controls['cpf'].setErrors({ isNotUnique: true });
          }
        });
  }

  public openTermGeneralCondition(): void {
    this.dialog.open(TermGeneralConditionsComponent, {
      width: '700px',
    });
  }

  public signInWithGoogle(): void {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        this.user = {
          provider: user?.provider,
          emailVerified: null,
          uid: null,
          id: user?.id,
          email: user?.email,
          name: user?.name,
          photoUrl: user?.photoUrl,
          firstName: user?.firstName,
          lastName: user?.lastName,
          authToken: user?.authToken,
          idToken: user?.idToken,
          authorizationCode: user?.authorizationCode,
          response: user?.response,
        };
        this.afAuth
          .createUserWithEmailAndPassword(user?.email, user?.id)
          .then((result) => this.authSocialLogin(user, result?.user?.uid))
          .catch((res) => {
            this.afAuth
              .signInWithEmailAndPassword(user?.email, user?.id)
              .then(result => this.authSocialLogin(user, result?.user?.uid))
          });
      });
  }

  public signOut(): void {
    this.socialAuthService.signOut();
  }

  public authSocialLogin(user: SocialUser, uid: string): void {
    this.onSubmitNodeBySocialLogin(user, uid);
    this.authService
      .postAuth(user?.email, user?.id)
      .subscribe((it: any) => {
        if (it?.data?.token) {
          let img = this.localStorageService.logoEmpresa;
          let codigoIdentificador = this.localStorageService.codigoIdentificador;
          let codigoEmpresa = this.localStorageService.codigoEmpresa;

          this.localStorageService.clear();

          this.localStorageService.codigoEmpresa = codigoEmpresa;
          this.localStorageService.codigoIdentificador = codigoIdentificador;
          this.localStorageService.logoEmpresa = img;
          this.localStorageService.token = it?.data?.token;

          this.firestoreAuthService.SignIn(user?.email, user?.id);

          this.routeScreen();
        }
      });
  }
}
