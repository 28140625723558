import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/main/services/localservice.service';


@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss'],
})
export class SuccessScreenComponent implements OnInit {
  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
  }

  private routeHomeScreen(): void {
    this.router.navigate(['/root/signature']);
  }

  private routeDashboardScreen(): void {
    this.router.navigate(['/root/dashboard']);
  }

  public buttonSelected(option): void {
    if (option == 'home') {
      if (this.localStorageService.fireToken !== null && this.localStorageService.token !== null) {
        this.routeHomeScreen();
      } else {
        this.localStorageService.clear()
        this._snackBar.open('Houve um erro durante o cadastro. Tente novamente.', null, { duration: 6000 });
        this.routeHomeScreen();
      }
    } else if (option === 'dashboard') {
      if (this.localStorageService.fireToken !== null && this.localStorageService.token !== null) {
        this.routeDashboardScreen();
      } else {
        this.localStorageService.clear()
        this._snackBar.open('Houve um erro durante o cadastro. Tente novamente.', null, { duration: 6000 });
        this.routeHomeScreen();
      }
    }
  }

}
