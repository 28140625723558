<div class="dialogWrapper">
  <span mat-dialog-title>{{ optional.name }}</span>

  <mat-dialog-content>
    <ul>
      <li *ngFor="let item of optional.items">
        {{ item }}
      </li>
    </ul>
  </mat-dialog-content>

  <mat-dialog-actions align="start">
    <button mat-flat-button mat-dialog-close>Fechar</button>
  </mat-dialog-actions>
</div>
