<div style="height: 60px">
  <app-header></app-header>
</div>
<form [formGroup]="form">
  <input formControlName="controlStepper" type="hidden" />
</form>
<div id="div">
  <mat-card-subtitle id="mat-card-subtitle-big">
    Escolha uma categoria
  </mat-card-subtitle>
  <div fxLayout="column" style="justify-content: flex-start">
    <div *ngFor="let group of groups; let i = index">
      <div *ngIf="!pressed">
        <div *ngIf="i == 0">
          <mat-card class="card" fxLayout="row wrap" (click)="choiceGroup(group)">
            <mat-card-header fxLayout="column" fxFlex='60' style="justify-content: center;">
              <mat-card-title class="vehicleTitle">{{ group.descricao?.toUpperCase() }}</mat-card-title>
              <mat-card-header class="textsTitle">
                <mat-card-subtitle>{{group.subDescricao}}</mat-card-subtitle>
              </mat-card-header>
              <!-- <mat-card-header class="textsTitle">
                <mat-card-subtitle>Exemplos: {{group.exemplos}}</mat-card-subtitle>
              </mat-card-header> -->
            </mat-card-header>
            <img fxFlex='40' class='img' mat-card-image [src]="group.imagem" [alt]="group.descricao" />
          </mat-card>
          <mat-card-subtitle *ngIf="groupsLength > 1" class="text" style="cursor: pointer;" fxLayoutAlign="center center" (click)="showMore()">
            Ver mais
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="groupsLength == 1" (click)="routeBack()" class="text" id="textBottom"
            fxLayoutAlign="center center">
            SAIR DA JORNADA FÁCIL
          </mat-card-subtitle>
        </div>
      </div>
      <div *ngIf="pressed">
        <mat-card class="card" fxLayout="row wrap" (click)="choiceGroup(group)">
          <mat-card-header fxLayout="column" fxFlex='60' style="justify-content: center;">
            <mat-card-title class="vehicleTitle">{{ group.descricao?.toUpperCase() }}</mat-card-title>
            <mat-card-header class="textsTitle">
              <mat-card-subtitle>{{group.subDescricao}}</mat-card-subtitle>
            </mat-card-header>
            <!-- <mat-card-header class="textsTitle">
              <mat-card-subtitle>Exemplos: {{group.exemplos}}</mat-card-subtitle>
            </mat-card-header> -->
          </mat-card-header>
          <img fxFlex='40' class='img' mat-card-image [src]="group.imagem" [alt]="group.descricao" />
        </mat-card>

        <mat-card-subtitle *ngIf="i == groupsLength - 1" (click)="routeBack()" class="text" id="textBottom" fxLayoutAlign="center center">
          SAIR DA JORNADA FÁCIL
        </mat-card-subtitle>
      </div>
      <!-- <mat-card-content>
        <ul style="font-size: 10px">
          <li *ngFor="let item of group.itensSerie">
            {{ item }}
          </li>
        </ul>
      </mat-card-content> -->
    </div>
  </div>
</div>
<br><br><br><br><br>