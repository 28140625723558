import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import IVehicle from 'src/app/interfaces/veiculo.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  currentIndex = 6;
  allVehicles = [];
  currentItems = [];
  selectedItems = [];
  isHidden = false;
  constructor(private crd: ChangeDetectorRef) { }

  @Input()
  vehicles: IVehicle[];

  @Input()
  selectedVehicles: any;

  @Input()
  choiceVehicle: Function;

  config = {
    lazy: true,
    centeredSlides: true,
    direction: 'vertical',
    height: 32,
    loop: true,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    spaceBetween: 0,
  };

  handleLoadMore() {
    this.currentIndex += 6;
    this.currentItems = this.vehicles.slice(0, this.currentIndex);

    if (this.currentItems?.length == this.vehicles?.length) {
      this.isHidden = true;
    }

    this.crd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vehicles) {
      this.allVehicles = changes.vehicles.currentValue;
      this.currentItems = changes.vehicles.currentValue.slice(
        0,
        this.currentIndex
      );
    }
    if (this.selectedItems?.length > 0) {
      this.isHidden = true;
      this.currentItems = this.selectedItems;
    } else {
      this.isHidden = false;
      if (this.currentItems?.length == this.vehicles?.length) {
        this.isHidden = true;
      }
      this.currentItems = this.allVehicles.slice(0, this.currentIndex);
    }
  }

  ngOnInit() {
    this.selectedItems = this.selectedVehicles();
  }
}
