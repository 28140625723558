import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CEPData {
    constructor(
        private http: HttpClient,
    ) { }

    getCEPData(cep: string): Observable<any> {
        return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }
}
