import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import IVehicle from 'src/app/interfaces/veiculo.interface';
import { Observable } from 'rxjs';
import IVehicleStep from 'src/app/interfaces/vehicle-step';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) { }

  getVehicleModelsByGrupo(groupID): any {
    let params = new HttpParams()
    .set("codigoIdentificador", this.localStorageService.codigoIdentificador)
    .set("codigoEmpresa", this.localStorageService.codigoEmpresa)
    .set("codigoUnidade", this.localStorageService.codigoUnidade);
    return this.http.get<any[]>(`${environment.api.base_url}/modelos/grupo/${groupID}`, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-API-Key": `${this.localStorageService.token}`,
      })
    });
  }
  
  getVehicleModelsByID(id: number): Observable<any> {
    let params = new HttpParams()
    .set("codigoIdentificador", this.localStorageService.codigoIdentificador)
    .set("codigoEmpresa", this.localStorageService.codigoEmpresa)
    .set("codigoUnidade", this.localStorageService.codigoUnidade);
    return this.http.get<any>(`${environment.api.base_url}/modelos/${id}`, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-API-Key": `${this.localStorageService.token}`,
      })
    });
  }
}
