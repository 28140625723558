<form [formGroup]="form">
  <div id="div">
    <mat-card-subtitle id="mat-card-subtitle-big"
      >Vamos te ajudar a encontrar o seu carro perfeito!
    </mat-card-subtitle>
    <br />
    <br />
    <div fxLayout="row" fxLayoutAlign="space-between">
      <!-- <mat-form-field appearance="outline" fxFlex="40">
                    <mat-label>Estado (UF)</mat-label>
                    <mat-select formControlName="uf">
                        <mat-option *ngFor="let state of states" [value]="state.uf">
                            {{state.descricao}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
      <mat-form-field appearance="legacy">
        <input
          type="text"
          placeholder="Digite o nome do município"
          aria-label="Number"
          matInput
          [formControl]="descricaoControl"
          [matAutocomplete]="auto"
        />
      </mat-form-field>

      <div
        *ngIf="obj"
        fxLayoutAlign="center center"
        id="mat-icon"
        (click)="confirmSelection()"
      >
        <mat-icon
          style="width: 48px; height: 48px; font-size: 48px"
          color="black"
          >keyboard_arrow_right
        </mat-icon>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option.nome"
        (onSelectionChange)="optionClick(option)"
      >
        {{ option.municipio }} - {{ option.uf }}
      </mat-option>
    </mat-autocomplete>

    <mat-radio-group
      *ngIf="units"
      formControlName="unit"
      aria-label="Escolha a unidade"
    >
      <mat-card-subtitle>Lista de Unidades</mat-card-subtitle>

      <div *ngFor="let unidade of units.unidades">
        <mat-radio-button
          [value]="unidade"
          style="margin: 0; padding: 0; padding-top: 12px"
        >
          {{ unidade?.cidadeAtendida }}
        </mat-radio-button>
        <p style="margin: 0; padding: 0; padding-top: 4px">
          Em: {{ unidade?.descricaoUnidade }}
        </p>
      </div>
    </mat-radio-group>

    <div fxLayoutAlign="center center" *ngIf="showUnitNotFound">
      <mat-card-subtitle
        >NENHUMA UNIDADE FOI LOCALIZADA PARA O MUNICÍPIO
        SELECIONADO.</mat-card-subtitle
      >
    </div>
  </div>
</form>
