import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  scrollTop = 0;
  isHidden = true;
  mode: string;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any) {
    const scrollY = window.scrollY;

    if (scrollY >= 300) {
      this.isHidden = false;
    } else {
      this.isHidden = true;
    }
  }

  onResize(event) {
    this.mode = (event.target.innerWidth <= 1400) ? "over" : "side";
  }

  ngOnInit(): void { }
}
