export interface ISocialConfig {
  googleId: string;
}
/**
 *
 * @param url
 * @returns {ISocialConfig}
 */
export function getIdentificador(data: any): ISocialConfig {
  let googleId: string;

  googleId = data.google.clientId;

  return { googleId };
}
