<div (window:resize)="onResize($event)" style="height: 60px" id="top">
  <mat-toolbar class="navbar">
    <div fxLayoutAlign="flex-start center">
      <button
        mat-icon-button
        (click)="drawer.toggle()"
        class="example-icon"
        aria-label="Example icon-button with menu icon"
      >
        <mat-icon color="accent">menu</mat-icon>
      </button>
      <a routerLink="" fxLayoutAlign="flex-start center">
        <img class="img-responsive" [src]="img" alt="logotipo" />
      </a>
    </div>
    <!-- <div fxLayoutAlign="center center">
            <mat-card-subtitle style="font-size: 18px; cursor: context-menu;" fxLayoutAlign="center center">
                {{ userPersonalData?.nomeCompleto?.split(' ')[0] + ' ' +
                userPersonalData?.nomeCompleto?.split(' ')[userPersonalData?.nomeCompleto?.split('
                ')?.length -
                1] }}
            </mat-card-subtitle>
        </div> -->
  </mat-toolbar>
</div>

<mat-drawer-container>
  <mat-drawer #drawer mode="side" opened>
    <div class="jumbotron bg-transparent text-center">
      <div>
        <br /><br />
        <div fxLayoutAlign="center center">
          <img
            *ngIf="user?.photoUrl && !userAnexos?.imagem?.base64"
            class="drawerImg"
            style="height: 100px; width: 100px"
            src="{{ user?.photoUrl }}"
          />
          <img
            *ngIf="!user?.photoUrl && !userAnexos?.imagem?.base64"
            class="drawerImg"
            style="height: 100px; width: 100px"
            src="../../../../assets/empty-user.png"
          />
          <img
            *ngIf="userAnexos?.imagem?.base64"
            class="drawerImg"
            style="height: 100px; width: 100px"
            src="{{ 'data:image/jpg;base64,' + userAnexos?.imagem?.base64 }}"
          />
        </div>
        <div fxLayoutAlign="center center">
          <div>
            <p
              *ngIf="userPersonalData?.nomeCompleto?.split(' ')?.length > 1"
              class="drawerText"
            >
              {{
                userPersonalData?.nomeCompleto?.split(" ")[0] +
                  " " +
                  userPersonalData?.nomeCompleto?.split(" ")[
                    userPersonalData?.nomeCompleto?.split(
                      "
                            "
                    )?.length - 1
                  ]
              }}
            </p>
            <p
              *ngIf="
                userPersonalData?.nomeCompleto?.split(' ')?.length > 0 &&
                2 > userPersonalData?.nomeCompleto?.split(' ')?.length
              "
              class="drawerText"
            >
              {{ userPersonalData?.nomeCompleto?.split(" ")[0] }}
            </p>
          </div>
        </div>
        <br />
      </div>
    </div>
    <div class="drawerOption">
      <div
        class="drawerOptionText"
        (click)="setStep(0)"
        [ngStyle]="
          step == 0 && {
            'border-width': '2px',
            'border-color': '#fff',
            'border-style': 'solid'
          }
        "
      >
        <p class="drawerText" fxLayoutAlign="flex-start">
          <mat-icon class="drawerIcon" matSuffix>home</mat-icon>Página inicial
        </p>
      </div>
    </div>
    <div class="drawerOption">
      <div
        class="drawerOptionText"
        (click)="setStep(1)"
        [ngStyle]="
          step == 1 && {
            'border-width': '2px',
            'border-color': '#fff',
            'border-style': 'solid'
          }
        "
      >
        <p class="drawerText" fxLayoutAlign="flex-start">
          <mat-icon class="drawerIcon" matSuffix>person</mat-icon>Meus dados
        </p>
      </div>
    </div>
    <div class="drawerOption">
      <div
        class="drawerOptionText"
        (click)="setStep(2)"
        [ngStyle]="
          step == 2 && {
            'border-width': '2px',
            'border-color': '#fff',
            'border-style': 'solid'
          }
        "
      >
        <p class="drawerText" fxLayoutAlign="flex-start">
          <mat-icon class="drawerIcon" matSuffix>directions_car</mat-icon>Minhas
          assinaturas
        </p>
      </div>
    </div>
    <div class="drawerOption">
      <div
        class="drawerOptionText"
        (click)="setStep(3)"
        [ngStyle]="
          step == 3 && {
            'border-width': '2px',
            'border-color': '#fff',
            'border-style': 'solid'
          }
        "
      >
        <p class="drawerText" fxLayoutAlign="flex-start">
          <mat-icon class="drawerIcon" matSuffix>assignment</mat-icon>Contratos
        </p>
      </div>
    </div>
    <div class="drawerOption">
      <div
        class="drawerOptionText"
        (click)="setStep(4)"
        [ngStyle]="
          step == 4 && {
            'border-width': '2px',
            'border-color': '#fff',
            'border-style': 'solid'
          }
        "
      >
        <p class="drawerText" fxLayoutAlign="flex-start">
          <mat-icon class="drawerIcon" matSuffix>credit_card</mat-icon
          >Pagamentos
        </p>
      </div>
    </div>
    <div class="drawerOption">
      <div
        class="drawerOptionText"
        (click)="setStep(5)"
        [ngStyle]="
          step == 5 && {
            'border-width': '2px',
            'border-color': '#fff',
            'border-style': 'solid'
          }
        "
      >
        <p
          *ngIf="showNotificationRedCircle === true"
          class="drawerText"
          fxLayoutAlign="flex-start"
        >
          <mat-icon class="drawerIcon" matSuffix
            >notifications_active
            <div
              style="
                position: relative;
                top: -18px;
                left: 10px;
                width: 6px;
                height: 6px;
                border-radius: 32px;
                background-color: red;
              "
            ></div> </mat-icon
          >Notificações
        </p>
        <p
          *ngIf="showNotificationRedCircle === false"
          class="drawerText"
          fxLayoutAlign="flex-start"
        >
          <mat-icon class="drawerIcon" matSuffix>notifications</mat-icon
          >Notificações
        </p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="drawerOption">
      <div class="drawerOptionText" (click)="signOut()">
        <p class="drawerText" fxLayoutAlign="flex-start">Sair</p>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div *ngIf="step == 0">
      <mat-card-subtitle id="mat-card-subtitle-big">
        Meu perfil
      </mat-card-subtitle>
      <mat-card-subtitle id="mat-card-subtitle-medium">
        Página Principal
      </mat-card-subtitle>
      <br />
      <mat-card
        class="mat-elevation-z0"
        id="card"
        fxLayout="row wrap"
        fxLayoutAlign="space-between"
      >
        <div fxLayout="column">
          <div fxLayout="row">
            <div *ngIf="user" fxLayout="column">
              <img
                *ngIf="user?.photoUrl && !userAnexos?.imagem?.base64"
                class="drawerImg"
                src="{{ user?.photoUrl }}"
              />
              <img
                *ngIf="!user?.photoUrl && !userAnexos?.imagem?.base64"
                class="drawerImg"
                src="../../../../assets/empty-user.png"
              />
              <img
                *ngIf="userAnexos?.imagem?.base64"
                class="drawerImg"
                src="{{
                  'data:image/jpg;base64,' + userAnexos?.imagem?.base64
                }}"
              />
            </div>
            <div fxLayoutAlign="center center">
              <div
                style="padding-left: 10px; font-weight: 600"
                *ngIf="userPersonalData?.nomeCompleto?.split(' ')?.length > 1"
              >
                {{
                  userPersonalData?.nomeCompleto?.split(" ")[0] +
                    " " +
                    userPersonalData?.nomeCompleto?.split(" ")[
                      userPersonalData?.nomeCompleto?.split(
                        "
                                "
                      )?.length - 1
                    ]
                }}
                <p *ngIf="age" style="font-weight: 400">{{ age }} anos</p>
              </div>
              <div
                style="padding-left: 10px; font-weight: 600"
                *ngIf="
                  userPersonalData?.nomeCompleto?.split(' ')?.length > 0 &&
                  2 > userPersonalData?.nomeCompleto?.split(' ')?.length
                "
              >
                {{ userPersonalData?.nomeCompleto?.split(" ")[0] }}
                <p *ngIf="age" style="font-weight: 400">{{ age }} anos</p>
              </div>
            </div>
          </div>
          <form [formGroup]="userPersonalDataForm">
            <div
              *ngIf="!this.profilePicture.name"
              class="button"
              style="width: fit-content"
              (click)="profilePicture.click()"
            >
              <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
                <mat-icon class="buttonIcon" matSuffix>add_a_photo</mat-icon>
                Alterar foto
              </mat-card-subtitle>
            </div>
            <input
              style="display: none"
              accept=".pdf,.jpeg,.jpg,.png"
              type="file"
              #profilePicture
              (change)="processFile($event)"
            />
          </form>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="button" (click)="setStep(1)">
            <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
              <mat-icon class="buttonIcon" matSuffix>edit</mat-icon>Editar meus
              dados
            </mat-card-subtitle>
          </div>
          <div class="buttonRed" (click)="signOut()">
            <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
              <mat-icon class="buttonIcon" matSuffix
                >power_settings_new</mat-icon
              >Sair da minha conta
            </mat-card-subtitle>
          </div>
        </div>
      </mat-card>
      <br /><br />
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="margin-bottom: 16px"
      >
        <div style="height: 16px">
          <mat-card-subtitle id="mat-card-subtitle-medium">
            Suas assinaturas
          </mat-card-subtitle>
        </div>
        <div class="button" style="width: auto; margin: 0" [routerLink]="['/']">
          <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
            <mat-icon class="buttonIcon" matSuffix>add_circle</mat-icon>Nova
            assinatura
          </mat-card-subtitle>
        </div>
      </div>
      <mat-table firstTable [dataSource]="dataSource">
        <ng-container matColumnDef="nomeCarro" class="colored">
          <mat-header-cell *matHeaderCellDef> Nome do carro </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.modelo }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tempoContrato">
          <mat-header-cell *matHeaderCellDef>
            Tempo do Contrato
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.periodoTarifa }} meses</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="quilometragem">
          <mat-header-cell *matHeaderCellDef> Quilometragem </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.franquiaKmRodado }}km
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cor">
          <mat-header-cell *matHeaderCellDef> Cor </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.cor }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="modelo">
          <mat-header-cell *matHeaderCellDef> Modelo </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <img
              style="height: 64px; width: 128px"
              [src]="element?.urlImagemModelo"
            />
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validade">
          <mat-header-cell *matHeaderCellDef> Validade </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.validade | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          [ngClass]="{ colored: user.email == user.email }"
          *matRowDef="let user; columns: displayedColumns"
        >
        </mat-row>
      </mat-table>
    </div>

    <!-- Segunda opção de seleção do drawer -->

    <div *ngIf="step == 1">
      <mat-card-subtitle id="mat-card-subtitle-big">
        Meus dados
      </mat-card-subtitle>
      <br />
      <mat-card
        class="mat-elevation-z0"
        id="card"
        style="padding-bottom: 20px"
        fxLayout="column"
      >
        <!-- Primeira seção -->

        <form [formGroup]="userPersonalDataForm">
          <div *ngIf="!editDataFirstBool" class="editDataMainContainer">
            <div
              class="editDataHeader"
              fxLayout="row"
              fxLayoutAlign="space-between"
            >
              <mat-card-subtile fxLayoutAlign="center center">
                <mat-icon class="editDataHeaderIcon" matSuffix>person</mat-icon>
                <strong class="editDataHeaderText">Informações pessoais</strong>
              </mat-card-subtile>
              <div class="button" style="width: auto" (click)="editDataFirst()">
                <mat-card-subtitle
                  class="buttonText"
                  fxLayoutAlign="flex-start"
                >
                  <mat-icon class="buttonIcon" matSuffix>edit</mat-icon>Editar
                  dados
                </mat-card-subtitle>
              </div>
            </div>
            <div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Nome completo:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.nomeCompleto }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Profissão:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="60"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.profissao }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Data de nascimento:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{
                        userPersonalData?.dataNascimento | date : 'dd/MM/yyyy'
                      }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Renda mensal:</mat-card-subtitle
                  >
                  <mat-form-field
                    *ngIf="userPersonalData?.renda > 0"
                    fxFlex="60"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      id="changeProgramatic"
                      type="text"
                      mvndrMatCurrencyFormat
                      [allowNegative]="false"
                      [currencyCode]="'BRL'"
                      value="{{ userPersonalData?.renda }}"
                      (change)="updateUSAmount($event)"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                  <mat-form-field
                    *ngIf="userPersonalData?.renda === 0"
                    fxFlex="60"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.renda }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
              </div>

              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">RG:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      readonly="true"
                      placeholder="-"
                      value="{{ userPersonalData?.rg }}"                     
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Telefone de Contato:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      readonly="true"
                      placeholder="-"                      
                      value="{{ userPersonalData?.telefone }}"
                    />
                  </mat-form-field>
                </mat-card>              
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">CPF:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"                      
                      readonly="true"
                      placeholder="-"
                      value="{{ userPersonalData?.cpf }}"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Comprovante de renda:</mat-card-subtitle
                  >
                  <div
                    *ngIf="userAnexos?.comprovanteRenda.extensao === '.pdf'"
                    class="button"
                    style="width: auto; margin-top: -0.4em"
                    (click)="onClickDownloadPdf('renda')"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_download</mat-icon
                      >Baixar arquivo
                    </mat-card-subtitle>
                  </div>
                  <div
                    *ngIf="
                      userAnexos?.comprovanteRenda.extensao !== '.pdf' &&
                      userAnexos?.comprovanteRenda.nome
                    "
                    class="button"
                    style="width: auto; margin-top: -0.4em"
                    (click)="onClickDownloadImage('renda')"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_download</mat-icon
                      >Baixar arquivo
                    </mat-card-subtitle>
                  </div>
                  <div *ngIf="!userAnexos?.comprovanteRenda.nome">
                    <mat-card-subtitle> - </mat-card-subtitle>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </form>

        <!-- Primeira seção com dados editaveis  -->

        <form [formGroup]="userPersonalDataForm">
          <div *ngIf="editDataFirstBool" class="editDataMainContainer">
            <div
              class="editDataHeader"
              fxLayout="row"
              fxLayoutAlign="space-between"
            >
              <mat-card-subtile fxLayoutAlign="center center">
                <mat-icon class="editDataHeaderIcon" matSuffix>person</mat-icon>
                <strong class="editDataHeaderText">Informações pessoais</strong>
              </mat-card-subtile>
              <div
                class="button"
                style="width: auto"
                (click)="editDataFirstPatch()"
              >
                <mat-card-subtitle
                  class="buttonText"
                  fxLayoutAlign="flex-start"
                >
                  <mat-icon class="buttonIcon" matSuffix>save</mat-icon>Salvar
                  dados
                </mat-card-subtitle>
              </div>
            </div>
            <div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Nome completo:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.nomeCompleto }}"
                      formControlName="nomeCompleto"                      
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Profissão:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="60"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.profissao }}"
                      formControlName="profissao"                      
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Data de nascimento:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      #dataNascimento
                      id="hiddenInput"
                      (focus)="maskedInput.focus()"
                      matInput
                      [matDatepicker]="picker"
                      max="{{ minAge | date : 'yyyy-MM-dd' }}"
                      value="{{ userPersonalData?.dataNascimento }}"
                    />
                    <input
                      #maskedInput
                      [ngClass]="{ empty: maskedInput.value == '__/__/____' }"
                      [(ngModel)]="dataNascimento.value"
                      (input)="dispatch(dataNascimento)"
                      class="mat-input-element mat-datepicker-input"
                      [dropSpecialCharacters]="false"
                      [showMaskTyped]="true"
                      mask="00/00/0000"
                      formControlName="dataNascimento"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Renda mensal:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="60"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.renda }}"
                      mvndrMatCurrencyFormat
                      [allowNegative]="false"
                      [currencyCode]="'BRL'"
                      [value]="usAmount"
                      (input)="updateUSAmount($event)"
                      formControlName="renda"                      
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">RG:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.rg }}"
                      mask="00.000.000-000||00.000.000-00||00.000.000-0||00.000.000"
                      formControlName="rg"                      
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Telefone de Contato:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPersonalData?.telefone }}"
                      mask="(00) 0 0000-0000"
                      formControlName="telefone"
                    />
                  </mat-form-field>
                </mat-card>                
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">CPF:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      mask="000.000.000-00"
                      formControlName="cpf"
                      (input)="
                        validCPF(userPersonalDataForm.controls['cpf'].value)
                      "
                      value="{{ userPersonalData?.cpf }}"                      
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Comprovante de renda:</mat-card-subtitle
                  >
                  <div fxLayout="column">
                    <div
                      *ngIf="!this.proofIncomeFile.name"
                      [ngClass]="
                        proofIncomeFileValidateBool === true
                          ? 'buttonError'
                          : 'button'
                      "
                      style="width: auto; margin-top: -0.4em"
                      (click)="proofIncomeInput.click()"
                    >
                      <mat-card-subtitle
                        class="buttonText"
                        fxLayoutAlign="flex-start"
                      >
                        <mat-icon class="buttonIcon" matSuffix
                          >cloud_upload</mat-icon
                        >
                        Adicionar arquivo
                      </mat-card-subtitle>
                    </div>
                    <input
                      style="display: none"
                      accept=".pdf,.jpeg,.jpg,.png"
                      type="file"
                      #proofIncomeInput
                      (change)="onFileSelected($event, 'PROOF_INCOME_FILE')"
                    />
                    <div
                      *ngIf="this.proofIncomeFile.name"
                      class="button"
                      style="width: auto; margin-top: -0.4em"
                      (click)="proofIncomeInput.click()"
                    >
                      <mat-card-subtitle
                        class="buttonText"
                        fxLayoutAlign="flex-start"
                      >
                        <mat-icon class="buttonIcon" matSuffix
                          >cloud_done</mat-icon
                        >
                        {{ this.proofIncomeFile.name }}
                      </mat-card-subtitle>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </form>

        <!-- Segunda seção -->

        <form [formGroup]="userAddressForm">
          <div *ngIf="!editDataSecondBool" class="editDataMainContainer">
            <div
              class="editDataHeader"
              fxLayout="row"
              fxLayoutAlign="space-between"
            >
              <mat-card-subtile fxLayoutAlign="center center">
                <mat-icon class="editDataHeaderIcon" matSuffix
                  >location_on</mat-icon
                >
                <strong class="editDataHeaderText">Endereço</strong>
              </mat-card-subtile>
              <div
                class="button"
                style="width: auto"
                (click)="editDataSecond()"
              >
                <mat-card-subtitle
                  class="buttonText"
                  fxLayoutAlign="flex-start"
                >
                  <mat-icon class="buttonIcon" matSuffix>edit</mat-icon>Editar
                  dados
                </mat-card-subtitle>
              </div>
            </div>
            <div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">CEP:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.cep }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Bairro:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.bairro }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Logradouro:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.logradouro }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Cidade:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.cidade }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Complemento:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.complemento }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Estado:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.estado }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Número:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="none"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="number"
                      value="{{ userAddress?.numero }}"
                      readonly="true"
                      placeholder="-"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="50"
                    >Comprovante de residência:</mat-card-subtitle
                  >
                  <div
                    *ngIf="
                      userAnexos?.comprovanteResidencia.extensao === '.pdf'
                    "
                    class="button"
                    style="width: auto; margin-top: -0.4em"
                    (click)="onClickDownloadPdf('residencia')"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_download</mat-icon
                      >Baixar arquivo
                    </mat-card-subtitle>
                  </div>
                  <div
                    *ngIf="
                      userAnexos?.comprovanteResidencia.extensao !== '.pdf' &&
                      userAnexos?.comprovanteResidencia.nome
                    "
                    class="button"
                    style="width: auto; margin-top: -0.4em"
                    (click)="onClickDownloadImage('residencia')"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_download</mat-icon
                      >Baixar arquivo
                    </mat-card-subtitle>
                  </div>
                  <div *ngIf="!userAnexos?.comprovanteResidencia.nome">
                    <mat-card-subtitle> - </mat-card-subtitle>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </form>
        <!-- Segunda seção com dados editaveis  -->

        <form [formGroup]="userAddressForm">
          <div *ngIf="editDataSecondBool" class="editDataMainContainer">
            <div
              class="editDataHeader"
              fxLayout="row"
              fxLayoutAlign="space-between"
            >
              <mat-card-subtile fxLayoutAlign="center center">
                <mat-icon class="editDataHeaderIcon" matSuffix
                  >location_on</mat-icon
                >
                <strong class="editDataHeaderText">Endereço</strong>
              </mat-card-subtile>
              <div
                class="button"
                style="width: auto"
                (click)="editDataSecondPatch()"
              >
                <mat-card-subtitle
                  class="buttonText"
                  fxLayoutAlign="flex-start"
                >
                  <mat-icon class="buttonIcon" matSuffix>save</mat-icon>Salvar
                  dados
                </mat-card-subtitle>
              </div>
            </div>
            <div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">CEP:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.cep }}"
                      mask="00000-000"
                      (input)="
                        searchCEP(
                          userAddressForm.controls['cep'].value,
                          'userData'
                        )
                      "
                      formControlName="cep"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Bairro:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.bairro }}"
                      formControlName="bairro"
                      maxlength="30"                      
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Logradouro:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.logradouro }}"
                      formControlName="logradouro"
                      maxlength="30"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Cidade:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.cidade }}"
                      formControlName="cidade"
                      maxlength="30"
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Complemento:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.complemento }}"
                      formControlName="complemento"
                      maxlength="30"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Estado:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userAddress?.estado }}"
                      formControlName="estado"
                      maxlength="2"                      
                    />
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">Número:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="number"
                      value="{{ userAddress?.numero }}"
                      formControlName="numero"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="50"
                    >Comprovante de residência:</mat-card-subtitle
                  >
                  <div
                    *ngIf="!this.proofHomeAddressFile.name"
                    [ngClass]="
                      proofHomeAddressFileValidateBool === true
                        ? 'buttonError'
                        : 'button'
                    "
                    style="width: auto; margin-top: -0.4em"
                    (click)="proofHomeAddress.click()"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_upload</mat-icon
                      >
                      Adicionar arquivo
                    </mat-card-subtitle>
                  </div>
                  <input
                    style="display: none"
                    accept=".pdf,.jpeg,.jpg,.png"
                    type="file"
                    #proofHomeAddress
                    (change)="onFileSelected($event, 'PROOF_HOME_ADDRESS_FILE')"
                  />
                  <div
                    *ngIf="this.proofHomeAddressFile.name"
                    class="button"
                    style="width: auto; margin-top: -0.4em"
                    (click)="proofHomeAddress.click()"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_done</mat-icon
                      >
                      {{ this.proofHomeAddressFile.name }}
                    </mat-card-subtitle>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </form>

        <!-- Terceira seção -->

        <div *ngIf="!editDataThirdBool">
          <div
            class="editDataHeader"
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <mat-card-subtile fxLayoutAlign="center center">
              <mat-icon class="editDataHeaderIcon" matSuffix
                >assignment_ind</mat-icon
              >
              <strong class="editDataHeaderText">CNH</strong>
            </mat-card-subtile>
            <div class="button" style="width: auto" (click)="editDataThird()">
              <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
                <mat-icon class="buttonIcon" matSuffix>edit</mat-icon>Editar
                dados
              </mat-card-subtitle>
            </div>
          </div>
          <div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxFlex="50"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40">N° da CNH:</mat-card-subtitle>
                <mat-form-field
                  fxFlex="50"
                  appearance="none"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userCnh?.numero }}"
                    readonly="true"
                    placeholder="-"
                  />
                </mat-form-field>
              </mat-card>
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxFlex="50"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40"
                  >Carteira de motorista:</mat-card-subtitle
                >
                <div
                  *ngIf="userAnexos?.cnh.extensao === '.pdf'"
                  class="button"
                  style="width: auto; margin-top: -0.4em"
                  (click)="onClickDownloadPdf('cnh')"
                >
                  <mat-card-subtitle
                    class="buttonText"
                    fxLayoutAlign="flex-start"
                  >
                    <mat-icon class="buttonIcon" matSuffix
                      >cloud_download</mat-icon
                    >Baixar arquivo
                  </mat-card-subtitle>
                </div>
                <div
                  *ngIf="
                    userAnexos?.cnh.extensao !== '.pdf' && userAnexos?.cnh.nome
                  "
                  class="button"
                  style="width: auto; margin-top: -0.4em"
                  (click)="onClickDownloadImage('cnh')"
                >
                  <mat-card-subtitle
                    class="buttonText"
                    fxLayoutAlign="flex-start"
                  >
                    <mat-icon class="buttonIcon" matSuffix
                      >cloud_download</mat-icon
                    >Baixar arquivo
                  </mat-card-subtitle>
                </div>
                <div *ngIf="!userAnexos?.cnh.nome">
                  <mat-card-subtitle> - </mat-card-subtitle>
                </div>
              </mat-card>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxFlex="50"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40"
                  >Validade da CNH:</mat-card-subtitle
                >
                <mat-form-field
                  fxFlex="50"
                  appearance="none"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userCnh?.validade | date : 'dd/MM/yyyy' }}"
                    readonly="true"
                    placeholder="-"
                  />
                </mat-form-field>
              </mat-card>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxFlex="50"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40"
                  >Data da 1° CNH:</mat-card-subtitle
                >
                <mat-form-field
                  fxFlex="50"
                  appearance="none"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userCnh?.primeiraData | date : 'dd/MM/yyyy' }}"
                    readonly="true"
                    placeholder="-"
                  />
                </mat-form-field>
              </mat-card>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between">
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxFlex="50"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40"
                  >Categoria da CNH:</mat-card-subtitle
                >
                <mat-form-field
                  fxFlex="50"
                  appearance="none"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userCnh?.categoria }}"
                    readonly="true"
                    placeholder="-"
                  />
                </mat-form-field>
              </mat-card>
            </div>
          </div>
        </div>

        <!-- Terceira seção com dados editaveis  -->

        <form [formGroup]="userCnhForm">
          <div *ngIf="editDataThirdBool">
            <div
              class="editDataHeader"
              fxLayout="row"
              fxLayoutAlign="space-between"
            >
              <mat-card-subtile fxLayoutAlign="center center">
                <mat-icon class="editDataHeaderIcon" matSuffix
                  >assignment_ind</mat-icon
                >
                <strong class="editDataHeaderText">CNH</strong>
              </mat-card-subtile>
              <div
                class="button"
                style="width: auto"
                (click)="editDataThirdPatch()"
              >
                <mat-card-subtitle
                  class="buttonText"
                  fxLayoutAlign="flex-start"
                >
                  <mat-icon class="buttonIcon" matSuffix>save</mat-icon>Salvar
                  dados
                </mat-card-subtitle>
              </div>
            </div>
            <div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">N° da CNH:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      maxlength="10"
                      type="text"
                      value="{{ userCnh?.numero }}"
                      formControlName="numero"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Carteira de motorista:</mat-card-subtitle
                  >
                  <div
                    *ngIf="!this.cnhFile.name"
                    [ngClass]="
                      cnhFileValidateBool === true ? 'buttonError' : 'button'
                    "
                    style="width: auto; margin-top: -0.4em"
                    (click)="cnhFileInput.click()"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_upload</mat-icon
                      >
                      Adicionar arquivo
                    </mat-card-subtitle>
                  </div>
                  <input
                    style="display: none"
                    accept=".pdf,.jpeg,.jpg,.png"
                    type="file"
                    #cnhFileInput
                    (change)="onFileSelected($event, 'CNH_FILE')"
                  />
                  <div
                    *ngIf="this.cnhFile.name"
                    class="button"
                    style="width: auto; margin-top: -0.4em"
                    (click)="cnhFileInput.click()"
                  >
                    <mat-card-subtitle
                      class="buttonText"
                      fxLayoutAlign="flex-start"
                    >
                      <mat-icon class="buttonIcon" matSuffix
                        >cloud_done</mat-icon
                      >
                      {{ this.cnhFile.name }}
                    </mat-card-subtitle>
                  </div>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Validade da CNH:</mat-card-subtitle
                  >

                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      #validade
                      id="hiddenInput"
                      (focus)="maskedInput.focus()"
                      matInput
                      [matDatepicker]="picker2"
                      value="{{ userCnh?.validade }}"
                    />
                    <input
                      #maskedInput
                      [ngClass]="{ empty: maskedInput.value == '__/__/____' }"
                      [(ngModel)]="validade.value"
                      (input)="dispatch(validade)"
                      class="mat-input-element mat-datepicker-input"
                      [dropSpecialCharacters]="false"
                      [showMaskTyped]="true"
                      mask="00/00/0000"
                      formControlName="validade"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker2"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Data da 1° CNH:</mat-card-subtitle
                  >

                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      #dataPrimeira
                      id="hiddenInput"
                      (focus)="maskedInput3.focus()"
                      matInput
                      [matDatepicker]="picker3"
                      value="{{ userCnh?.primeiraData }}"
                    />
                    <input
                      #maskedInput3
                      [ngClass]="{ empty: maskedInput.value == '__/__/____' }"
                      [(ngModel)]="dataPrimeira.value"
                      (input)="dispatch(dataPrimeira)"
                      class="mat-input-element mat-datepicker-input"
                      [dropSpecialCharacters]="false"
                      [showMaskTyped]="true"
                      mask="00/00/0000"
                      formControlName="dataPrimeira"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker3"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </mat-form-field>
                </mat-card>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between">
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Categoria da CNH:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userCnh?.categoria }}"
                      formControlName="categoria"
                    />
                  </mat-form-field>
                </mat-card>
              </div>
            </div>
          </div>
        </form>
      </mat-card>
      <br /><br />
    </div>

    <!-- Terceira opção de seleção do drawer -->

    <div *ngIf="step == 2">
      <div fxLayoutAlign="space-between">
        <mat-card-subtitle id="mat-card-subtitle-big">
          Minhas assinaturas
        </mat-card-subtitle>
        <div
          *ngIf="mode == 'side'"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          style="margin-bottom: 16px"
        >
          <div
            *ngIf="!showCarousel"
            class="button"
            style="width: auto; margin: 0"
            (click)="setShowCarousel(true)"
          >
            <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
              <mat-icon class="buttonIcon" matSuffix>view_carousel</mat-icon
              >Intercalar modo de exibição
            </mat-card-subtitle>
          </div>
          <div
            *ngIf="showCarousel"
            class="button"
            style="width: auto; margin: 0"
            (click)="setShowCarousel(false)"
          >
            <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
              <mat-icon class="buttonIcon" matSuffix> table_chart</mat-icon
              >Intercalar modo de exibição
            </mat-card-subtitle>
          </div>
        </div>
      </div>
      <br />

      <!-- Tabela de assinantes -->

      <ng-container *ngIf="!showCarousel">
        <mat-card
          class="mat-elevation-z0"
          id="table-header"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div *ngIf="mode == 'over'" fxFlex="100">
            <mat-form-field fxFlex="100">
              <input
                matInput
                (keyup)="applyFilter($event.target.value)"
                placeholder="Procurar"
              />
            </mat-form-field>
          </div>
          <div *ngIf="mode == 'side'" fxFlex="80">
            <mat-form-field fxFlex="100">
              <input
                matInput
                (keyup)="applyFilter($event.target.value)"
                placeholder="Procurar"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="mode == 'side'"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            style="margin-bottom: 16px"
            [routerLink]="['/']"
          >
            <div class="button" style="width: auto; margin: 0">
              <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
                <mat-icon class="buttonIcon" matSuffix>add_circle</mat-icon>Nova
                assinatura
              </mat-card-subtitle>
            </div>
          </div>
        </mat-card>
        <mat-table
          secondTable
          style="
            border-top-width: 0px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          "
          [dataSource]="dataSource"
        >
          <ng-container matColumnDef="nomeCarro" class="colored">
            <mat-header-cell *matHeaderCellDef> Nome do carro </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.modelo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tempoContrato">
            <mat-header-cell *matHeaderCellDef>
              Tempo do Contrato
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.periodoTarifa }} meses</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="quilometragem">
            <mat-header-cell *matHeaderCellDef> Quilometragem </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.franquiaKmRodado }}km
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="cor">
            <mat-header-cell *matHeaderCellDef> Cor </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.cor }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="modelo">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div
                *ngIf="element.status.letra === 'A'"
                class="tableVehicleStatus"
                id="tableVehicleStatusA"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>loyalty</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'C'"
                class="tableVehicleStatus"
                id="tableVehicleStatusC"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>done</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'F'"
                class="tableVehicleStatus"
                id="tableVehicleStatusF"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>done</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'G'"
                class="tableVehicleStatus"
                id="tableVehicleStatusG"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>done</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'M'"
                class="tableVehicleStatus"
                id="tableVehicleStatusM"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>done</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'N'"
                class="tableVehicleStatus"
                id="tableVehicleStatusN"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffixreport
                  >report</mat-icon
                >
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'O'"
                class="tableVehicleStatus"
                id="tableVehicleStatusO"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>done</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'P'"
                class="tableVehicleStatus"
                id="tableVehicleStatusP"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>done</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'R'"
                class="tableVehicleStatus"
                id="tableVehicleStatusR"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>done</mat-icon>
                {{ element.status.descricao }}
              </div>
              <div
                *ngIf="element.status.letra === 'X'"
                class="tableVehicleStatus"
                id="tableVehicleStatusX"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <mat-icon class="buttonIconStatus" matSuffix>report</mat-icon>
                {{ element.status.descricao }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="validade">
            <mat-header-cell *matHeaderCellDef> Validade </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.validade | date : "dd/MM/yyyy" }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            [ngClass]="{ colored: user.email == user.email }"
            *matRowDef="let user; columns: displayedColumns"
          >
          </mat-row>
        </mat-table>
      </ng-container>

      <!-- Carousel de assinantes -->

      <div *ngIf="showCarousel" class="carousel-container">
        <carousel
          [cellsToShow]="1"
          [loop]="true"
          [dots]="true"
          [cellWidth]="'100%'"
          [height]="340"
          [dots]="true"
          [loop]="true"
          [arrowsOutside]="true"
        >
          <div
            *ngFor="let slide of userVehicleData; let ind = index"
            class="carousel-cell"
            fxLayout="column"
          >
            <p class="carouselTitle" style="margin-bottom: 0">
              {{ slide.modelo }}
            </p>
            <p
              class="carouselTitle"
              style="font-size: 14px; font-weight: 500; margin-top: 4px"
            >
              {{ slide.validade | date : "dd/MM/yyyy" }}
            </p>
            <mat-card
              id="card"
              class="mat-elevation-z0"
              fxLayout="row wrap"
              style="
                width: 96%;
                margin: 0;
                padding-bottom: 30px;
                margin-left: 20px;
                margin-right: 20px;
              "
            >
              <div fxFlex="60" fxLayout="column" fxLayoutAlign="center">
                <!-- <div fxLayout="row">
                                    <p fxFlex='50'> <strong>Status da assinatura:</strong>
                                    </p>
                                    <p fxFlex='50'> {{slide.status.descricao}} </p>
                                </div> -->
                <div fxLayout="row">
                  <p fxFlex="50"><strong>Cor:</strong></p>
                  <p fxFlex="50">{{ slide.cor }}</p>
                </div>
                <div fxLayout="row">
                  <p fxFlex="50"><strong>Tempo:</strong></p>
                  <p fxFlex="50">{{ slide.periodoTarifa }} meses</p>
                </div>
                <div fxLayout="row">
                  <p fxFlex="50"><strong>Quilometragem:</strong></p>
                  <p fxFlex="50">{{ slide.franquiaKmRodado }}km</p>
                </div>
                <div fxLayout="row">
                  <p fxFlex="50"><strong>Status do contrato:</strong></p>
                  <p fxFlex="50">{{ slide.status.descricao }}</p>
                </div>
              </div>
              <div fxFlex="40" fxLayoutAlign="center center">
                <img fxFlex="70" [src]="slide.urlImagemModelo" />
              </div>
            </mat-card>
          </div>
        </carousel>
      </div>
    </div>

    <!-- Quarta opção de seleção do drawer -->

    <div *ngIf="step == 3">
      <mat-card-subtitle id="mat-card-subtitle-big">
        Contratos
      </mat-card-subtitle>
      <br />
      <div>
        <mat-card-subtitle id="mat-card-subtitle-medium">
          Contratos ativos
        </mat-card-subtitle>
        <ng-container>
          <mat-table thirdTable [dataSource]="dataSourceContractsAtivos">
            <ng-container matColumnDef="nomeCarro" class="colored">
              <mat-header-cell *matHeaderCellDef>
                Nome do carro
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.modelo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tempoContrato">
              <mat-header-cell *matHeaderCellDef>
                Tempo do Contrato
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.duracao }} meses</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="validade">
              <mat-header-cell *matHeaderCellDef> Validade </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.dataInicio | date : "dd/MM/yyyy" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="pdf">
              <mat-header-cell *matHeaderCellDef> Contrato </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="button"
                  style="width: auto"
                  (click)="downloadContract(element.base64contrato)"
                >
                  <mat-card-subtitle
                    class="buttonText"
                    fxLayoutAlign="flex-start"
                    href="element.urlPdfAssinado"
                  >
                    <mat-icon class="buttonIcon" matSuffix
                      >cloud_download</mat-icon
                    >Baixar PDF
                  </mat-card-subtitle>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumnsForContracts"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ colored: user.email == user.email }"
              *matRowDef="let user; columns: displayedColumnsForContracts"
            >
            </mat-row>
          </mat-table>
          <div
            *ngIf="!dataSourceContractsAtivos"
            class="emptyContractImgContainer"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <img
              class="emptyContractImg"
              src="../../../../assets/empty-contracts.png"
            />

            <mat-card-subtitle id="mat-card-subtitle-medium">
              Não foram encontrados contratos ativos vinculados a sua conta
            </mat-card-subtitle>
          </div>
        </ng-container>
        <br /><br />

        <mat-card-subtitle id="mat-card-subtitle-medium">
          Contratos pendentes
        </mat-card-subtitle>
        <ng-container>
          <mat-table thirdTable [dataSource]="dataSourceContractsPendentes">
            <ng-container matColumnDef="nomeCarro" class="colored">
              <mat-header-cell *matHeaderCellDef>
                Nome do carro
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.modelo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tempoContrato">
              <mat-header-cell *matHeaderCellDef>
                Tempo do Contrato
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.duracao }} meses</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="validade">
              <mat-header-cell *matHeaderCellDef> Validade </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.dataInicio | date : "dd/MM/yyyy" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="pdf">
              <mat-header-cell *matHeaderCellDef> Contrato </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="button"
                  style="width: auto"
                  (click)="downloadContract(element.urlPdfAssinado)"
                >
                  <mat-card-subtitle
                    class="buttonText"
                    fxLayoutAlign="flex-start"
                  >
                    <mat-icon class="buttonIcon" matSuffix
                      >cloud_download</mat-icon
                    >Baixar PDF
                  </mat-card-subtitle>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsForContracts"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ colored: user.email == user.email }"
              *matRowDef="let user; columns: displayedColumnsForContracts"
            >
            </mat-row>
          </mat-table>
          <div
            *ngIf="!dataSourceContractsPendentes"
            class="emptyContractImgContainer"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <img
              class="emptyContractImg"
              src="../../../../assets/empty-contracts.png"
            />

            <mat-card-subtitle id="mat-card-subtitle-medium">
              Não foram encontrados contratos pendentes vinculados a sua conta
            </mat-card-subtitle>
          </div>
        </ng-container>
        <br /><br />

        <mat-card-subtitle id="mat-card-subtitle-medium">
          Contratos encerrados
        </mat-card-subtitle>
        <ng-container>
          <mat-table thirdTable [dataSource]="dataSourceContractsCancelados">
            <ng-container matColumnDef="nomeCarro" class="colored">
              <mat-header-cell *matHeaderCellDef>
                Nome do carro
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.descricao }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tempoContrato">
              <mat-header-cell *matHeaderCellDef>
                Tempo do Contrato
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.duracao }} meses</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="validade">
              <mat-header-cell *matHeaderCellDef> Validade </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.dataInicio | date : "dd/MM/yyyy" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="pdf">
              <mat-header-cell *matHeaderCellDef> Contrato </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div
                  class="button"
                  style="width: auto"
                  (click)="downloadContract(element.urlPdfAssinado)"
                >
                  <mat-card-subtitle
                    class="buttonText"
                    fxLayoutAlign="flex-start"
                  >
                    <mat-icon class="buttonIcon" matSuffix
                      >cloud_download</mat-icon
                    >Baixar PDF
                  </mat-card-subtitle>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsForContracts"
            ></mat-header-row>
            <mat-row
              [ngClass]="{ colored: user.email == user.email }"
              *matRowDef="let user; columns: displayedColumnsForContracts"
            >
            </mat-row>
          </mat-table>
          <div
            *ngIf="!dataSourceContractsCancelados"
            class="emptyContractImgContainer"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <img
              class="emptyContractImg"
              src="../../../../assets/empty-contracts.png"
            />

            <mat-card-subtitle id="mat-card-subtitle-medium">
              Não foram encontrados contratos encerrados vinculados a sua conta
            </mat-card-subtitle>
          </div>
        </ng-container>
      </div>
      <br /><br />
    </div>

    <!-- Quinta opção de seleção do drawer -->

    <div *ngIf="step == 4">
      <mat-card-subtitle id="mat-card-subtitle-big">
        Pagamento
      </mat-card-subtitle>
      <br />

      <!-- Card para quando há um cartão registrado -->

      <mat-card
        *ngIf="userPaymentDataCard?.numeroCartao && !editCardDataBool"
        class="mat-elevation-z0"
        id="card"
        fxLayout="row"
        fxLayoutAlign="space-between"
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-radio-button
            value="5"
            checked="true"
            style="margin: 0; padding: 0"
          >
            <strong
              >Cartão final
              {{ userPaymentDataCard?.numeroCartao.slice(-4) }}</strong
            >
            |
            {{ userPaymentDataCard?.bandeiraCartao }}
          </mat-radio-button>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="button" (click)="editCardData()">
            <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
              <mat-icon class="buttonIcon" matSuffix>edit</mat-icon>Editar meus
              dados
            </mat-card-subtitle>
          </div>
          <div class="buttonRed" (click)="deleteCardData()">
            <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
              <mat-icon class="buttonIcon" matSuffix>delete_forever</mat-icon
              >Deletar cartão
            </mat-card-subtitle>
          </div>
        </div>
      </mat-card>

      <!-- Card para quando não há um cartão registrado -->

      <mat-card
        *ngIf="!userPaymentDataCard?.numeroCartao && !editCardDataBool"
        class="mat-elevation-z0"
        id="card"
        fxLayout="column"
        fxLayoutAlign="flex-start"
      >
        <div
          style="padding-top: 20px; padding-bottom: 0px"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <img class="emptyCardImg" src="../../../../assets/empty-data.png" />
          <mat-card-subtitle id="mat-card-subtitle-medium">
            Você ainda não tem nenhuma informação de pagamento salva
          </mat-card-subtitle>
          <div class="button" style="width: auto" (click)="editCardData()">
            <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
              Adicionar novo cartão
            </mat-card-subtitle>
          </div>
        </div>
      </mat-card>

      <!-- Card para adição/edição de um cartão -->

      <mat-card
        *ngIf="editCardDataBool"
        class="mat-elevation-z0"
        id="card"
        fxLayout="column"
        fxLayoutAlign="flex-start"
      >
        <form
          *ngIf="!editCardAddressBool"
          [formGroup]="userPaymentDataCardForm"
        >
          <div class="editDataMainContainer">
            <div
              class="editDataHeader"
              fxLayout="row"
              fxLayoutAlign="space-between"
            >
              <mat-card-subtile fxLayoutAlign="center center">
                <mat-icon class="editDataHeaderIcon" matSuffix
                  >payment_card</mat-icon
                >
                <strong class="editDataHeaderText"
                  >Informações do cartão</strong
                >
              </mat-card-subtile>
              <div
                class="button"
                style="width: auto"
                (click)="
                  userPaymentDataEndereco[0]?.codigoEndereco
                    ? patchCardData()
                    : postCardData()
                "
              >
                <mat-card-subtitle
                  class="buttonText"
                  fxLayoutAlign="flex-start"
                >
                  <mat-icon class="buttonIcon" matSuffix>save</mat-icon>Salvar
                  dados
                </mat-card-subtitle>
              </div>
            </div>
            <div>
              <mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxFlex="50"
                fxLayout="column"
              >
                <mat-radio-group fxLayout="column">
                  <mat-radio-button
                    [checked]="
                      userPaymentDataCard.bandeiraCartao === 'Visa'
                        ? true
                        : false
                    "
                    value="0"
                    style="margin: 0; padding: 0; padding-top: 12px"
                  >
                    Visa
                  </mat-radio-button>
                  <mat-radio-button
                    [checked]="
                      userPaymentDataCard.bandeiraCartao === 'MasterCard'
                        ? true
                        : false
                    "
                    value="1"
                    style="margin: 0; padding: 0; padding-top: 12px"
                  >
                    MasterCard
                  </mat-radio-button>
                  <mat-radio-button
                    [checked]="
                      userPaymentDataCard.bandeiraCartao === 'Elo'
                        ? true
                        : false
                    "
                    value="2"
                    style="margin: 0; padding: 0; padding-top: 12px"
                  >
                    Elo
                  </mat-radio-button>
                  <mat-radio-button
                    [checked]="
                      userPaymentDataCard.bandeiraCartao == 'HiperCard'
                        ? true
                        : false
                    "
                    value="3"
                    style="margin: 0; padding: 0; padding-top: 12px"
                  >
                    HiperCard
                  </mat-radio-button>
                  <mat-radio-button
                    *ngIf="
                      userPaymentDataCard.bandeiraCartao != 'Visa' &&
                      userPaymentDataCard.bandeiraCartao != 'MasterCard' &&
                      userPaymentDataCard.bandeiraCartao != 'Elo' &&
                      userPaymentDataCard.bandeiraCartao != 'HiperCard' &&
                      userPaymentDataEndereco[0]?.codigoEndereco
                    "
                    [checked]="true"
                    value="4"
                    style="margin: 0; padding: 0; padding-top: 12px"
                  >
                    {{
                      userPaymentDataCard.bandeiraCartao
                        ? userPaymentDataCard.bandeiraCartao
                        : "Cartão não reconhecido pelo sistema"
                    }}
                  </mat-radio-button>
                </mat-radio-group>
              </mat-card>
              <mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxFlex="50"
                fxLayout="column"
              >
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Número do cartão:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      mask="0000 0000 0000 0000"
                      value="{{ userPaymentDataCard?.numeroCartao }}"
                      formControlName="numeroCartao"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Data de validade:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      mask="00/00"
                      value="{{ userPaymentDataCard?.validadeCartao }}"
                      formControlName="validadeCartao"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40"
                    >Nome no cartão:</mat-card-subtitle
                  >
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPaymentDataCard?.nomeCompleto }}"
                      formControlName="nomeCompleto"
                    />
                  </mat-form-field>
                </mat-card>
                <mat-card
                  mat-card
                  class="mat-elevation-z0"
                  id="editDataContainer"
                  fxFlex="50"
                  fxLayoutAlign="flex-start flex-start"
                >
                  <mat-card-subtitle fxFlex="40">CPF:</mat-card-subtitle>
                  <mat-form-field
                    fxFlex="50"
                    appearance="legacy"
                    class="editDataFormField"
                  >
                    <input
                      matInput
                      type="text"
                      value="{{ userPaymentDataCard?.cpf }}"
                      formControlName="cpf"
                      mask="000.000.000-00"
                    />
                  </mat-form-field>
                </mat-card>
              </mat-card>
            </div>
          </div>
        </form>

        <form [formGroup]="userPaymentDataEnderecoForm">
          <div
            class="editDataHeader"
            fxLayout="row"
            fxLayoutAlign="space-between"
          >
            <mat-card-subtile fxLayoutAlign="center center">
              <mat-icon class="editDataHeaderIcon" matSuffix
                >location_on</mat-icon
              >
              <strong class="editDataHeaderText">Endereço</strong>
            </mat-card-subtile>
            <div
              *ngIf="!editCardAddressBool && userPaymentDataCard?.numeroCartao"
              class="button"
              style="width: auto"
              (click)="newCardAddress()"
            >
              <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
                <mat-icon class="buttonIcon" matSuffix>add_circle</mat-icon
                >Adicionar endereço
              </mat-card-subtitle>
            </div>
            <div
              *ngIf="editCardAddressBool"
              class="button"
              style="width: auto"
              (click)="postCardData()"
            >
              <mat-card-subtitle class="buttonText" fxLayoutAlign="flex-start">
                <mat-icon class="buttonIcon" matSuffix>save</mat-icon>Salvar
                dados
              </mat-card-subtitle>
            </div>
          </div>
          <div>
            <mat-card
              class="mat-elevation-z0"
              id="editDataContainer"
              fxLayout="column"
              fxFlex="50"
            >
              <div
                fxLayout="column"
                *ngFor="let enum of userPaymentDataEndereco; let i = index"
              >
                <ng-container *ngIf="enum.logradouro !== ''">
                  <mat-radio-button
                    *ngIf="!editCardAddressBool"
                    [checked]="i === indexAddress"
                    [value]="userPaymentDataEndereco"
                    (click)="selectRadioButton(i)"
                    style="margin: 0; padding: 0; padding-top: 12px"
                  >
                    {{ enum.logradouro }}
                  </mat-radio-button>
                  <mat-radio-button
                    *ngIf="editCardAddressBool"
                    [disabled]="true"
                    [value]="userPaymentDataEndereco"
                    style="margin: 0; padding: 0; padding-top: 12px"
                  >
                    {{ enum.logradouro }}
                  </mat-radio-button>
                </ng-container>
              </div>
            </mat-card>
            <mat-card
              class="mat-elevation-z0"
              id="editDataContainer"
              fxLayout="column"
              fxFlex="50"
            >
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40">CEP:</mat-card-subtitle>
                <mat-form-field
                  fxFlex="50"
                  appearance="legacy"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userPaymentDataEndereco?.cep }}"
                    (input)="
                      searchCEP(
                        userPaymentDataEnderecoForm.controls['cep'].value,
                        'paymentScreen'
                      )
                    "
                    formControlName="cep"
                    mask="00000-000"
                  />
                </mat-form-field>
              </mat-card>
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40">Logradouro:</mat-card-subtitle>
                <mat-form-field
                  fxFlex="50"
                  appearance="legacy"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userPaymentDataEndereco?.logradouro }}"
                    formControlName="logradouro"
                  />
                </mat-form-field>
              </mat-card>
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40">Número:</mat-card-subtitle>
                <mat-form-field
                  fxFlex="50"
                  appearance="legacy"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="number"
                    value="{{ userPaymentDataEndereco?.numero }}"
                    formControlName="numero"
                  />
                </mat-form-field>
              </mat-card>
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40">Complemento:</mat-card-subtitle>
                <mat-form-field
                  fxFlex="50"
                  appearance="legacy"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userPaymentDataEndereco?.complemento }}"
                    formControlName="complemento"
                  />
                </mat-form-field>
              </mat-card>
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40">Cidade:</mat-card-subtitle>
                <mat-form-field
                  fxFlex="50"
                  appearance="legacy"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userPaymentDataEndereco?.cidade }}"
                    formControlName="cidade"
                  />
                </mat-form-field>
              </mat-card>
              <mat-card
                mat-card
                class="mat-elevation-z0"
                id="editDataContainer"
                fxLayoutAlign="flex-start flex-start"
              >
                <mat-card-subtitle fxFlex="40">Bairro:</mat-card-subtitle>
                <mat-form-field
                  fxFlex="50"
                  appearance="legacy"
                  class="editDataFormField"
                >
                  <input
                    matInput
                    type="text"
                    value="{{ userPaymentDataEndereco?.bairro }}"
                    formControlName="bairro"
                  />
                </mat-form-field>
              </mat-card>
              <mat-slide-toggle
                *ngIf="
                  userPaymentDataCard?.numeroCartao && !editCardAddressBool
                "
                [checked]="checked"
                (change)="changed($event)"
              >
                Definir endereço como principal
              </mat-slide-toggle>
            </mat-card>
          </div>
        </form>
      </mat-card>
    </div>

    <!-- Sexta opção de seleção do drawer -->

    <div *ngIf="step == 5">
      <mat-card-subtitle id="mat-card-subtitle-big">
        Notificações
      </mat-card-subtitle>
      <br />
      <div>
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let data of userNotification; let ind = index"
            class="mat-elevation-z0"
            [ngStyle]="
              (panelOpenState == true && ind === panelOpenStateIndex) ||
              ind === panelOpenStateIndex - 1
                ? {
                    'border-bottom-width': '1px',
                    'border-bottom-left-radius': '12px',
                    'border-bottom-right-radius': '12px'
                  }
                : { 'border-bottom-width': '0px' }
            "
            [ngStyle]="
              panelOpenState == true && ind === panelOpenStateIndex + 1
                ? {
                    'border-bottom-width': '0px',
                    'border-top-left-radius': '12px',
                    'border-top-right-radius': '12px'
                  }
                : { 'border-bottom-width': '0px' }
            "
            (opened)="setPanelState(true, ind)"
            (closed)="setPanelState(false)"
            (click)="changeOpenedPanelState(data, ind)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                *ngIf="!data.isOpened"
                fxLayoutAlign="flex-start center"
              >
                <mat-icon
                  class="buttonIcon"
                  fxLayoutAlign="flex-start center"
                  matSuffix
                  >notifications
                </mat-icon>
                {{ data.title }}
              </mat-panel-title>
              <mat-panel-title
                *ngIf="data.isOpened"
                fxLayoutAlign="flex-start center"
              >
                <mat-icon
                  class="buttonIcon"
                  fxLayoutAlign="flex-start center"
                  matSuffix
                >
                  notifications_none</mat-icon
                >
                {{ data.title }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ data.description }}</p>
            <div fxLayoutAlign="flex-end flex-end">
              <p style="text-align: flex-end">
                Enviado dia: {{ data.sendDate | date : "dd/MM/yyyy" }}
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
