import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
  providedIn: 'root'
})
export class UserCardDataForPost {

  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
    private _snackBar: MatSnackBar,
  ) { }

  postUserCardData(body) {
    let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
    this.httpClient.post<any>(`${environment.api.base_url}/assinantes/endereco-cobranca`, body, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-API-Key": `${this.localStorageService.token}`,
      })
    }).subscribe(i => {
      return this._snackBar.open(i.data.message[0].toUpperCase() + i.data.message.toLowerCase().slice(1), '', { duration: 3000 });
    }
    );
  }
}
