<div style="height: 60px">
  <app-header></app-header>
</div>
<div id="div">
  <mat-card-subtitle id="title-big">
    Escolha um intervalo de mensalidade
  </mat-card-subtitle>
  <mat-card class="mat-elevation-z0" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">
    <mat-card class="mat-elevation-z0" fxFlex="25" fxLayoutAlign="center center">
    </mat-card>
    <mat-card class="mat-elevation-z0" fxFlex="50" fxLayout.xs="row" id="custom-slider">
      <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
    </mat-card>
    <mat-card class="mat-elevation-z0" fxFlex="25" style="padding-top: 140px;" fxLayoutAlign="center center">
      <div id="mat-icon" (click)="routeScreen()">
        <mat-icon style="width: 48px; height: 48px; font-size: 48px;" color="black">keyboard_arrow_right</mat-icon>
      </div>
    </mat-card>
  </mat-card>
  <br><br><br><br>
  <div fxLayoutAlign="center center">
    <div style="position: fixed; bottom: 0;" fxLayout="row">
      <app-powered-by></app-powered-by>
    </div>
  </div>
</div>