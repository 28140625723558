import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-page-connection',
  templateUrl: './page-connection.component.html',
  styleUrls: ['./page-connection.component.scss']
})
export class PageConnectionComponent implements OnInit {
  mode: string;

  constructor(
    private socialAuthService: SocialAuthService,
    public afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
    this.mode = (window.innerWidth <= 1400) ? "over" : "side";
  }

  onResize(event) {
    this.mode = (event.target.innerWidth <= 1400) ? "over" : "side";
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  public signOut(): void {
    this.socialAuthService.signOut().catch(error => console.log(error));
    this.afAuth.signOut().catch(error => console.log(error));
  }

}
