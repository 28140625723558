<div style="height: 60px">
  <app-header></app-header>
</div>
<form [formGroup]="form">
  <input formControlName="controlStepper" type="hidden" />
</form>
<div id="div" fxLayout="column" fxLayoutAlign="space-between">
  <div>
    <mat-card-subtitle id="mat-card-subtitle-big">
      Agora sim podemos começar! Qual grupo você prefere?
    </mat-card-subtitle>
    <div fxLayout="row wrap" style="justify-content: center">
      <div *ngFor="let group of groups">
        <mat-card class="card" (click)="choiceGroup(group)">
          <img class='img' mat-card-image [src]="group.imagem" [alt]="group.descricao" />
        </mat-card>
        <!-- <mat-card-content>
        <ul style="font-size: 10px">
          <li *ngFor="let item of group.itensSerie">
            {{ item }}
          </li>
        </ul>
      </mat-card-content> -->
        <mat-card-header class='text'>
          <mat-card-title class="custom-title">{{ group.descricao?.toUpperCase() }}</mat-card-title>
        </mat-card-header>
      </div>
    </div>
  </div>
  <div>
    <mat-card-subtitle id="mat-card-subtitle-medium">
      Nunca assinou um carro antes? <a (click)="routeSecondWay()" style="color: black; cursor: pointer;">Clique aqui</a>
      para uma opção mais simples.
    </mat-card-subtitle>
    <div fxLayout="row" fxLayoutAlign="center center">
      <app-powered-by></app-powered-by>
    </div>
  </div>
</div>
<br />