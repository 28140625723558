import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase';
import { ConfigFirebase } from '../main/services/get-config-firebase.service';
import { LocalStorageService } from '../main/services/localservice.service';

export enum Firebase {
  GOOGLE_CLIENT_ID = '9023138401474',
}

@Component({
  selector: 'app-initialization',
  templateUrl: './initialization.component.html',
  styleUrls: ['./initialization.component.scss'],
})
export class InitializationComponent implements OnInit {
  constructor(
    public localStorageService: LocalStorageService,
    private _routerActivate: ActivatedRoute,
    private getConfigFirebase: ConfigFirebase,
    private router: Router
  ) {}
  providerscoll: any = [];
  get displayText() {
    return this.localStorageService.messageAuthControl ?? false;
  }

  ngOnInit(): void {
    if (this._routerActivate.snapshot.params.nomeLocadora) {
      this.localStorageService.codigoIdentificador =
        this._routerActivate.snapshot.params.nomeLocadora;
    }
    if (this._routerActivate.snapshot.params.nomeLocadora) {
      this.getConfigFirebase.getConfigFirebase().subscribe((i: any) => {
        firebase.initializeApp(i.data.config);
        this.localStorageService.firebaseData = i.data;
        if (i.data.google) {
          localStorage.setItem(
            Firebase.GOOGLE_CLIENT_ID,
            JSON.stringify(i.data.google.clientId)
          );
        }
        this.router.navigateByUrl('/root/signature');
      });
    }
  }
}
