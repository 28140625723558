import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
  providedIn: 'root',
})
export class SubscribersService {
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private localStorageService: LocalStorageService
  ) {}

  post(body) {
    let params = new HttpParams().set(
      'codigoIdentificador',
      this.localStorageService.codigoIdentificador
    );
    return this.http.post<any>(`${environment.api.base_url}/assinantes`, body, {
      params: params,
    });
  }
}
