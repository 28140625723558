import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ContractsAtivos } from 'src/app/interfaces/user-contracts-ativos.interface';
import { ContractsCancelados } from 'src/app/interfaces/user-contracts-cancelados.interface';
import { ContractsPendentes } from 'src/app/interfaces/user-contracts-pendentes.interface';
import { Contracts } from 'src/app/interfaces/user-contracts.interface';
import {
  Address,
  Anexos,
  CNH,
  PersonalData,
  VehicleSelected,
} from 'src/app/interfaces/user-data.interface';
import { Notificacoes } from 'src/app/interfaces/user-notification.interface';
import {
  Cartao,
  EnderecosCobranca,
} from 'src/app/interfaces/user-payment-data.interface';
import { User } from 'src/app/interfaces/user.interface';
import { FirestoreAuthService } from '../../services/auth-firebase.service';
import { CEPData } from '../../services/get-cep.service';
import { UserContractsData } from '../../services/get-user-contracts.service';
import { UserNotificationData } from '../../services/get-user-notification.service';
import { UserPaymentData } from '../../services/get-user-payment-data.service';
import { UserData } from '../../services/get-user.service';
import { LocalStorageService } from '../../services/localservice.service';
import { MessagingService } from '../../services/messaging-push-firebase.service';
import { UserCardDataForPatch } from '../../services/patch-user-card.service';
import { UserNotificationDataForPatch } from '../../services/patch-user-notification-state.service';
import { UserDataForPatch } from '../../services/patch-user.service';
import { UserCardDataForPost } from '../../services/post-user-card.service';
import { ValidCPF } from '../../services/valid-cpf.service';
import { Mask } from 'src/app/utils/mask';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public user: User;
  public userPersonalData: PersonalData;
  public userVehicleData: VehicleSelected[] = [];
  public userAddress: Address;
  public userCnh: CNH;
  public userAnexos: Anexos = {
    imagem: { nome: '', extensao: '', base64: null },
    comprovanteRenda: { nome: '', extensao: '', base64: null },
    comprovanteResidencia: { nome: '', extensao: '', base64: null },
    cnh: { nome: '', extensao: '', base64: null },
  };
  public userPaymentDataCard: Cartao;
  public userPaymentDataEndereco: EnderecosCobranca[] = [];
  public userNotification: Notificacoes[] = [];
  public userContracts: Contracts;
  public userPersonalDataForm: FormGroup;
  public userAddressForm: FormGroup;
  public userCnhForm: FormGroup;
  public userPaymentDataCardForm: FormGroup;
  public userPaymentDataEnderecoForm: FormGroup;
  public step: number = 0;
  public showCarousel: boolean = false;
  public age: number;
  public universalId: string;
  public editDataFirstBool: boolean = false;
  public editDataSecondBool: boolean = false;
  public editDataThirdBool: boolean = false;
  public editCardDataBool: boolean = false;
  public editCardAddressBool: boolean = false;
  public panelOpenState: boolean = false;
  public panelOpenStateIndex: number = -2;
  public codigoEnderecoFiltered: number;
  public showNotificationRedCircle: boolean = false;
  displayedColumns = [
    'nomeCarro',
    'tempoContrato',
    'quilometragem',
    'cor',
    'modelo',
    'validade',
  ];
  displayedColumnsForContracts = [
    'nomeCarro',
    'tempoContrato',
    'validade',
    'pdf',
  ];
  dataSource: MatTableDataSource<VehicleSelected>;
  dataSourceContractsAtivos: MatTableDataSource<ContractsAtivos>;
  dataSourceContractsPendentes: MatTableDataSource<ContractsPendentes>;
  dataSourceContractsCancelados: MatTableDataSource<ContractsCancelados>;
  selectedFile: ImageSnippet;
  public mode = 'side';
  public proofHomeAddressFile = { name: '', base64: null };
  public cnhFile = { name: '', base64: '' };
  public proofIncomeFile = { name: '', base64: null };
  public proofHomeAddressFileValidateBool: boolean = false;
  public cnhFileValidateBool: boolean = false;
  public proofIncomeFileValidateBool: boolean = false;
  public profilePicture = { name: 'aa', base64: null };
  defaultValue = { foo: 'bar' };
  usAmount = 100;
  img: string;
  minAge: any;
  checked = false;
  indexAddress: number = 0;

  constructor(
    private socialAuthService: SocialAuthService,
    public afAuth: AngularFireAuth,
    private firestoreAuthService: FirestoreAuthService,
    public userData: UserData,
    public userPaymentData: UserPaymentData,
    public userNotificationData: UserNotificationData,
    public userContractsData: UserContractsData,
    public userNotificationDataForPatch: UserNotificationDataForPatch,
    public userDataForPatch: UserDataForPatch,
    public userCardDataForPost: UserCardDataForPost,
    public userCardDataForPatch: UserCardDataForPatch,
    public userCEPData: CEPData,
    public userCPFValidation: ValidCPF,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private router: Router,
    private msg: MessagingService,
    public ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.img = this.localStorageService.logoEmpresa;
    if (
      !this.localStorageService.token ||
      !this.localStorageService.fireToken
    ) {
      this.router.navigateByUrl('/');
      this._snackBar.open(
        'A sua sessão expirou. Por gentileza, autentique-se novamente.',
        '',
        { duration: 3000 }
      );
    }
    this.buildForm();
    this.getUserData();
    this.calculateMinAge();
    this.msg.requestPerm('uai');
  }

  getUserData(edit?: string): void {
    this.userVehicleData = [];
    this.socialAuthService.authState.subscribe((result) => {
      this.user = {
        provider: result?.provider,
        emailVerified: null,
        uid: null,
        id: result?.id,
        email: result?.email,
        name: result?.name,
        photoUrl: result?.photoUrl,
        firstName: result?.firstName,
        lastName: result?.lastName,
        authToken: result?.authToken,
        idToken: result?.idToken,
        authorizationCode: result?.authorizationCode,
        response: result?.response,
      };
    });
    if (this.localStorageService.fireToken) {
      this.userData
        .getUserData(this.localStorageService.fireToken)
        .subscribe((res: any) => {
          res = res.data;
          if (edit === 'first' || !edit) {
            this.userPersonalData = {
              nomeCompleto: res?.informacoesPessoais?.nomeCompleto,
              cpf: Mask.cpf(res?.informacoesPessoais?.cpf),
              rg: Mask.rg(res?.informacoesPessoais?.rg),
              codigoCliente: res?.informacoesPessoais?.codigoCliente,
              codigoFirebase: res?.informacoesPessoais?.codigoFirebase,
              dataEmissaoIdentidade:
                res?.informacoesPessoais?.dataEmissaoIdentidade,
              orgaoEmissorIdentidade:
                res?.informacoesPessoais?.orgaoEmissorIdentidade,
              dataNascimento: res?.informacoesPessoais?.dataNascimento,
              sexo: res?.informacoesPessoais?.sexo,
              estadoCivil: res?.informacoesPessoais?.estadoCivil,
              profissao: res?.informacoesPessoais?.profissao,
              renda: res?.informacoesPessoais?.renda,
              telefone: Mask.phoneNumber(res.informacoesPessoais?.telefone),
            };

            this.userPersonalDataForm.patchValue({
              nomeCompleto: res?.informacoesPessoais?.nomeCompleto,
              cpf: res?.informacoesPessoais?.cpf,
              rg: res?.informacoesPessoais?.rg,
              dataEmissaoIdentidade:
                res?.informacoesPessoais?.dataEmissaoIdentidade,
              orgaoEmissorIdentidade:
                res?.informacoesPessoais?.orgaoEmissorIdentidade,
              dataNascimento: res?.informacoesPessoais?.dataNascimento,
              sexo: res?.informacoesPessoais?.sexo,
              estadoCivil: res?.informacoesPessoais?.estadoCivil,
              profissao: res?.informacoesPessoais?.profissao,
              renda: res?.informacoesPessoais?.renda,
              telefone: res?.informacoesPessoais?.telefone,
            });

            this.userAnexos.comprovanteRenda = {
              nome: res?.anexos?.comprovanteRenda?.nome,
              extensao: res?.anexos?.comprovanteRenda?.extensao,
              base64: res?.anexos?.comprovanteRenda?.base64,
            };

            this.proofIncomeFile.base64 =
              this.userAnexos?.comprovanteRenda?.base64;
            this.proofIncomeFile.name = this.userAnexos?.comprovanteRenda?.nome;
          }
          res.propostas.forEach((res) => {
            const userVehicleData: VehicleSelected = {
              codigoProposta: res?.codigoProposta,
              modelo: res?.modelo,
              periodoTarifa: res?.periodoTarifa,
              franquiaKmRodado: res?.franquiaKmRodado,
              cor: res?.cor,
              urlImagemModelo: res?.urlImagemModelo,
              corHex: res?.corHex,
              validade: res?.validade,
              status: {
                letra: res?.status?.letra,
                descricao: res?.status?.descricao,
              },
            };
            this.userVehicleData.push(userVehicleData);
          });
          this.dataSource = new MatTableDataSource<VehicleSelected>(
            this.userVehicleData
          );
          if (edit === 'second' || !edit) {
            this.userAddress = {
              codigoMunicipio: res?.endereco?.codigoMunicipio,
              cep: Mask.cep(res?.endereco?.cep),
              logradouro: res?.endereco?.logradouro,
              numero: res?.endereco?.numero,
              complemento: res?.endereco?.complemento,
              bairro: res?.endereco?.bairro,
              cidade: res?.endereco?.cidade,
              estado: res?.endereco?.estado,
              codigoPais: res?.endereco?.codigoPais,
            };

            this.userAddressForm.patchValue({
              codigoMunicipio: res?.endereco?.codigoMunicipio,
              cep: res?.endereco?.cep,
              logradouro: res?.endereco?.logradouro,
              numero: res?.endereco?.numero,
              complemento: res?.endereco?.complemento,
              bairro: res?.endereco?.bairro,
              cidade: res?.endereco?.cidade,
              estado: res?.endereco?.estado,
              codigoPais: res?.endereco?.codigoPais,
            });

            this.userAnexos.comprovanteResidencia = {
              nome: res?.anexos?.comprovanteResidencia?.nome,
              extensao: res?.anexos?.comprovanteResidencia?.extensao,
              base64: res?.anexos?.comprovanteResidencia?.base64,
            };
            this.proofHomeAddressFile.base64 =
              this.userAnexos?.comprovanteResidencia?.base64;
            this.proofHomeAddressFile.name =
              this.userAnexos?.comprovanteResidencia?.nome;
          }

          if (edit === 'third' || !edit) {
            this.userCnh = {
              numero: res?.cnh?.numero,
              validade: res?.cnh?.validade,
              categoria: res?.cnh?.categoria,
              primeiraData: res?.cnh?.dataPrimeira,
            };

            this.userCnhForm.patchValue({
              numero: res?.cnh?.numero,
              validade: res?.cnh?.validade,
              categoria: res?.cnh?.categoria,
              dataPrimeira: res?.cnh?.dataPrimeira,
            });

            this.userAnexos.cnh = {
              nome: res?.anexos?.cnh?.nome,
              extensao: res?.anexos?.cnh?.extensao,
              base64: res?.anexos?.cnh?.base64,
            };

            this.cnhFile.base64 = this.userAnexos?.cnh?.base64;
            this.cnhFile.name = this.userAnexos?.cnh?.nome;
          }

          this.userAnexos.imagem = {
            nome: res?.anexos?.imagem?.nome,
            extensao: res?.anexos?.imagem?.extensao,
            base64: res?.anexos?.imagem?.base64,
          };

          this.CalculateAge(res?.informacoesPessoais.dataNascimento);
          this.getUserPaymentData(this.localStorageService.fireToken);
          this.getUserNotificationData(this.localStorageService.fireToken);
          this.getContracts(this.localStorageService.fireToken);
        });
    }
  }

  public getUserPaymentData(uid): void {
    this.userPaymentDataEndereco = [];
    this.userNotification = [];
    if (uid) {
      this.userPaymentData
        .getUserPaymentData(uid ? uid : this.localStorageService.fireToken)
        .subscribe((res: any) => {
          res = res.data;
          this.userPaymentDataCard = {
            numeroCartao: res?.cartao?.numeroCartao,
            validadeCartao: res?.cartao?.validadeCartao,
            nomeCompleto: res?.cartao?.nomeCompleto,
            cpf: res?.cartao?.cpf,
            bandeiraCartao: res?.cartao?.bandeiraCartao,
          };
          this.userPaymentDataCardForm.patchValue({
            numeroCartao: res?.cartao?.numeroCartao,
            validadeCartao: res?.cartao?.validadeCartao,
            nomeCompleto: res?.cartao?.nomeCompleto,
            cpf: res?.cartao?.cpf,
            bandeiraCartao: res?.cartao?.bandeiraCartao,
          });
          this.userPaymentDataEndereco = [];
          res.enderecosCobranca.forEach((res) => {
            const userPaymentDataEndereco: EnderecosCobranca = {
              cep: res?.cep,
              logradouro: res?.logradouro,
              numero: res?.numero,
              complemento: res?.complemento,
              bairro: res?.bairro,
              cidade: res?.cidade,
              estado: res?.estado,
              codigoEndereco: res?.codigoEndereco,
              enderecoPrincipal: res?.enderecoPrincipal,
            };
            if (
              userPaymentDataEndereco?.cep?.length > 1 &&
              userPaymentDataEndereco?.logradouro?.length > 1 &&
              userPaymentDataEndereco?.cidade?.length > 1
            )
              this.userPaymentDataEndereco.push(userPaymentDataEndereco);
          });
          let data = this.userPaymentDataEndereco.filter(
            (i: any) => i.enderecoPrincipal == 'S'
          );
          this.indexAddress = this.userPaymentDataEndereco.findIndex(
            (i: any) => i.enderecoPrincipal == 'S'
          );
          if (data[0]?.cep) {
            this.checked = true;
            this.userPaymentDataEnderecoForm.patchValue({
              cep: data[0]?.cep,
              logradouro: data[0]?.logradouro,
              numero: data[0]?.numero,
              complemento: data[0]?.complemento,
              bairro: data[0]?.bairro,
              cidade: data[0]?.cidade,
              estado: data[0]?.estado,
            });
          } else {
            this.checked = false;
            this.userPaymentDataEnderecoForm.patchValue({
              cep: this.userPaymentDataEndereco[0]?.cep,
              logradouro: this.userPaymentDataEndereco[0]?.logradouro,
              numero: this.userPaymentDataEndereco[0]?.numero,
              complemento: this.userPaymentDataEndereco[0]?.complemento,
              bairro: this.userPaymentDataEndereco[0]?.bairro,
              cidade: this.userPaymentDataEndereco[0]?.cidade,
              estado: this.userPaymentDataEndereco[0]?.estado,
            });
          }
        });
    }
  }

  getUserNotificationData(uid): void {
    if (uid) {
      this.userNotificationData
        .getUserNotificationData(uid)
        .subscribe((res: any) => {
          res = res.data;
          res.forEach((result) => {
            const userNotificationData: Notificacoes = {
              title: result?.title,
              notificationId: result?.notificationId,
              description: result?.description,
              isOpened: result?.isOpened,
              sendDate: result?.sendDate,
            };
            this.userNotification.push(userNotificationData);
          });
          if (
            this.userNotification.filter((i) => i.isOpened === false)?.length >
            0
          ) {
            this.showNotificationRedCircle = true;
          }
        });
    }
  }

  getContracts(uid): void {
    if (uid || this.localStorageService.fireToken) {
      this.userContractsData
        .getUserContractsData(
          this.localStorageService.codigoEmpresa,
          this.localStorageService.fireToken
        )
        .subscribe((i: any) => {
          i = i.data;
          let userContractsAtivos = [];
          let userContractsPendentes = [];
          let userContractsCancelados = [];
          i?.ativos?.forEach((res) => {
            const userContractsAtivosA = {
              codigoContrato: res?.codigoContrato,
              modelo: res?.modelo,
              dataInicio: res?.dataInicio,
              duracao: res?.duracao,
              urlPdfOriginal: res?.urlPdfOriginal,
              urlPdfAssinado: res?.urlPdfAssinado,
              base64contrato: res?.base64contrato,
            };
            userContractsAtivos.push(userContractsAtivosA);
          });
          i?.pendentes?.forEach((res) => {
            const userContractsPendentesA = {
              codigoContrato: res?.codigoContrato,
              modelo: res?.modelo,
              dataInicio: res?.dataInicio,
              duracao: res?.duracao,
              urlPdfOriginal: res?.urlPdfOriginal,
              urlPdfAssinado: res?.urlPdfAssinado,
              base64contrato: res?.base64contrato,
            };
            userContractsPendentes.push(userContractsPendentesA);
          });
          i?.cancelados?.forEach((res) => {
            const userContractsCanceladosA = {
              codigoContrato: res?.codigoContrato,
              modelo: res?.modelo,
              dataInicio: res?.dataInicio,
              duracao: res?.duracao,
              urlPdfOriginal: res?.urlPdfOriginal,
              urlPdfAssinado: res?.urlPdfAssinado,
              base64contrato: res?.base64contrato,
            };
            userContractsCancelados.push(userContractsCanceladosA);
          });
          const userDataContracts: Contracts = {
            ativos: userContractsAtivos,
            pendentes: userContractsPendentes,
            cancelados: userContractsCancelados,
          };
          this.userContracts = userDataContracts;
          this.dataSourceContractsAtivos = new MatTableDataSource(
            this.userContracts.ativos
          );
          this.dataSourceContractsPendentes = new MatTableDataSource(
            this.userContracts.pendentes
          );
          this.dataSourceContractsCancelados = new MatTableDataSource(
            this.userContracts.cancelados
          );
        });
    }
  }

  selectRadioButton(i) {
    this.userPaymentDataEnderecoForm.patchValue({
      cep: this.userPaymentDataEndereco[i]?.cep,
      logradouro: this.userPaymentDataEndereco[i]?.logradouro,
      numero: this.userPaymentDataEndereco[i]?.numero,
      complemento: this.userPaymentDataEndereco[i]?.complemento,
      bairro: this.userPaymentDataEndereco[i]?.bairro,
      cidade: this.userPaymentDataEndereco[i]?.cidade,
      estado: this.userPaymentDataEndereco[i]?.estado,
    });
    this.checked =
      this.userPaymentDataEndereco[i]?.enderecoPrincipal == 'S'
        ? true
        : this.userPaymentDataEndereco[i]?.enderecoPrincipal == 'N'
        ? false
        : false;
    this.codigoEnderecoFiltered =
      this.userPaymentDataEndereco[i]?.codigoEndereco;
  }

  newCardAddress() {
    this.editCardAddressBool = true;
    this.userPaymentDataEnderecoForm.patchValue({
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
    });
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  downloadContract(base64String) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${'contrato'}.pdf`;
    link.click();
  }

  onClickDownloadPdf(type) {
    if (type === 'renda') {
      let base64String = this.userAnexos.comprovanteRenda.base64;
      this.downloadPdf(base64String, this.userAnexos.comprovanteRenda.nome);
    } else if (type === 'residencia') {
      let base64String = this.userAnexos.comprovanteResidencia.base64;
      this.downloadPdf(
        base64String,
        this.userAnexos.comprovanteResidencia.nome
      );
    } else if (type === 'cnh') {
      let base64String = this.userAnexos.cnh.base64;
      this.downloadPdf(base64String, this.userAnexos.cnh.nome);
    }
  }

  downloadImage(base64String, fileName, fileExtension) {
    const source = `data:application/${fileExtension};base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}${fileExtension}`;
    link.click();
  }

  onClickDownloadImage(type) {
    if (type === 'renda') {
      let base64String = this.userAnexos.comprovanteRenda.base64;
      this.downloadImage(
        base64String,
        this.userAnexos.comprovanteRenda.nome,
        this.userAnexos.comprovanteRenda.extensao
      );
    } else if (type === 'residencia') {
      let base64String = this.userAnexos.comprovanteResidencia.base64;
      this.downloadImage(
        base64String,
        this.userAnexos.comprovanteResidencia.nome,
        this.userAnexos.comprovanteResidencia.extensao
      );
    } else if (type === 'cnh') {
      let base64String = this.userAnexos.cnh.base64;
      this.downloadImage(
        base64String,
        this.userAnexos.cnh.nome,
        this.userAnexos.cnh.extensao
      );
    }
  }

  calculateMinAge(): void {
    var today = new Date();
    var minAge = 18;
    this.minAge = new Date(
      today.getFullYear() - minAge,
      today.getMonth(),
      today.getDate()
    );
  }

  searchCEP(cep, type) {
    if (type === 'userDataScreen') {
      if (cep?.length >= 8) {
        this.userCEPData.getCEPData(cep).subscribe((i) => {
          this.userAddressForm.patchValue({
            logradouro: i.logradouro,
            cidade: i.localidade,
            complemento: i.complemento,
            bairro: i.bairro,
            estado: i.uf,
          });
        });
      }
    } else if (type === 'paymentScreen') {
      if (cep?.length >= 8) {
        this.userCEPData.getCEPData(cep).subscribe((i) => {
          this.userPaymentDataEnderecoForm.patchValue({
            logradouro: i.logradouro,
            cidade: i.localidade,
            complemento: i.complemento,
            bairro: i.bairro,
            estado: i.uf,
          });
        });
      }
    }
  }

  validCPF(cpf) {
    if (this.userCPFValidation.testCPF(cpf)) {
      this.userPersonalDataForm.get('cpf').setErrors(null);
    } else {
      this.userPersonalDataForm.controls['cpf'].setErrors({ isValidCPF: true });
    }
    if (this.userPersonalDataForm.controls['cpf']?.value?.length >= 11)
      this.userCPFValidation
        .get(this.userPersonalDataForm.controls['cpf']?.value)
        .subscribe((i: any) => {
          if (i?.isUnique === false) {
            this.userPersonalDataForm.controls['cpf'].setErrors({
              isNotUnique: true,
            });
          }
        });
  }

  validateInputsFirstSection() {
    let bool = false;
    if (!this.userPersonalDataForm.controls['nomeCompleto']?.value) {
      this.userPersonalDataForm.controls['nomeCompleto'].markAsTouched();
      bool = true;
    }
    if (!this.userPersonalDataForm.controls['cpf']?.value) {
      this.userPersonalDataForm.controls['cpf'].markAsTouched();
      bool = true;
    }
    if (!this.userPersonalDataForm.controls['rg']?.value) {
      this.userPersonalDataForm.controls['rg'].markAsTouched();
      bool = true;
    }
    if (!this.userPersonalDataForm.controls['dataNascimento']?.value) {
      this.userPersonalDataForm.controls['dataNascimento'].markAsTouched();
      bool = true;
    }
    if (!this.userPersonalDataForm.controls['profissao']?.value) {
      this.userPersonalDataForm.controls['profissao'].markAsTouched();
      bool = true;
    }
    if (this.userPersonalDataForm.controls['renda']?.value <= 0) {
      this.userPersonalDataForm.controls['renda'].markAsTouched();
      bool = true;
    }

    return bool;
  }

  validateInputsSecondSection() {
    let bool = false;
    if (!this.userAddressForm.controls['cep']?.value) {
      this.userAddressForm.controls['cep'].markAsTouched();
      bool = true;
    }
    if (!this.userAddressForm.controls['logradouro']?.value) {
      this.userAddressForm.controls['logradouro'].markAsTouched();
      bool = true;
    }
    if (!this.userAddressForm.controls['numero']?.value) {
      this.userAddressForm.controls['numero'].markAsTouched();
      bool = true;
    }
    if (!this.userAddressForm.controls['complemento']?.value) {
      this.userAddressForm.controls['complemento'].markAsTouched();
      bool = true;
    }
    if (!this.userAddressForm.controls['bairro']?.value) {
      this.userAddressForm.controls['bairro'].markAsTouched();
      bool = true;
    }
    if (!this.userAddressForm.controls['cidade']?.value) {
      this.userAddressForm.controls['cidade'].markAsTouched();
      bool = true;
    }
    if (!this.userAddressForm.controls['estado']?.value) {
      this.userAddressForm.controls['estado'].markAsTouched();
      bool = true;
    }

    return bool;
  }

  validateInputsThirdSection() {
    let bool = false;
    if (!this.userCnhForm.controls['numero']?.value) {
      this.userCnhForm.controls['numero'].markAsTouched();
      bool = true;
    }
    if (!this.userCnhForm.controls['validade']?.value) {
      this.userCnhForm.controls['validade'].markAsTouched();
      bool = true;
    }
    if (!this.userCnhForm.controls['categoria']?.value) {
      this.userCnhForm.controls['categoria'].markAsTouched();
      bool = true;
    }
    if (!this.userCnhForm.controls['dataPrimeira']?.value) {
      this.userCnhForm.controls['dataPrimeira'].markAsTouched();
      bool = true;
    }

    return bool;
  }

  updateUSAmount(event) {
    if (event) this.usAmount = event.target.value;
  }

  private buildForm(): void {
    this.userPersonalDataForm = this.formBuilder.group({
      nomeCompleto: new FormControl(null, Validators.required),
      cpf: new FormControl(null, Validators.required),
      rg: new FormControl(null, Validators.required),
      dataEmissaoIdentidade: new FormControl(null),
      orgaoEmissorIdentidade: new FormControl(null),
      dataNascimento: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
      ]),
      sexo: new FormControl(null),
      estadoCivil: new FormControl(null),
      profissao: new FormControl(null, Validators.required),
      renda: new FormControl(0, [Validators.required, Validators.min(1)]),
      proofIncomeFile: new FormControl(null),
      telefone: new FormControl(null)
    });
    this.userAddressForm = this.formBuilder.group({
      codigoMunicipio: new FormControl(null),
      cep: new FormControl(null, Validators.required),
      logradouro: new FormControl(null, Validators.required),
      numero: new FormControl(null, [
        Validators.maxLength(8),
        Validators.required,
      ]),
      complemento: new FormControl(null, Validators.required),
      bairro: new FormControl(null, Validators.required),
      cidade: new FormControl(null, Validators.required),
      estado: new FormControl(null, Validators.required),
      codigoPais: new FormControl(null),
      proofHomeAddress: new FormControl(null),
    });
    this.userCnhForm = this.formBuilder.group({
      numero: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
      ]),
      validade: new FormControl(null, Validators.required),
      categoria: new FormControl(null, Validators.required),
      dataPrimeira: new FormControl(null, Validators.required),
      cnhFile: new FormControl(null),
    });
    this.userPaymentDataCardForm = this.formBuilder.group({
      numeroCartao: new FormControl(null, Validators.required),
      validadeCartao: new FormControl(null, Validators.required),
      nomeCompleto: new FormControl(null, Validators.required),
      cpf: new FormControl(null, Validators.required),
      bandeiraCartao: new FormControl(null),
    });
    this.userPaymentDataEnderecoForm = this.formBuilder.group({
      cep: new FormControl(null, Validators.required),
      logradouro: new FormControl(null, Validators.required),
      numero: new FormControl(null, [
        Validators.maxLength(8),
        Validators.required,
      ]),
      complemento: new FormControl(null, Validators.required),
      bairro: new FormControl(null, Validators.required),
      cidade: new FormControl(null, Validators.required),
      estado: new FormControl(null),
    });
  }

  setStep(number): void {
    this.step = number;
    this.scrollTo();
    this.panelOpenStateIndex = -2;
    this.panelOpenState = false;
    this.editDataFirstBool = false;
    this.editDataSecondBool = false;
    this.editDataThirdBool = false;
    this.editCardDataBool = false;
    this.editCardAddressBool = false;
  }

  setPanelState(value, ind) {
    if (value === true) {
      setTimeout(() => {
        if (value == true) {
          this.panelOpenState = value;
        }
        this.panelOpenStateIndex = ind;
      }, 1);
    } else {
      if (value == true) {
        this.panelOpenState = value;
      }
      this.panelOpenStateIndex = ind;
    }
  }

  changeOpenedPanelState(data, ind) {
    if (!data.isOpened) {
      this.userNotification[ind].isOpened = true;
      this.userNotificationDataForPatch.patchUserNotificationData(
        data.notificationId
      );
    }
  }

  dispatch(input: HTMLInputElement) {
    if (input) {
      input.dispatchEvent(new Event('input'));
    }
  }

  onResize(event) {
    this.mode = event.target.innerWidth <= 960 ? 'over' : 'side';
  }

  applyFilter(value: string) {
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
  }

  setShowCarousel(value) {
    this.showCarousel = value;
  }

  CalculateAge(dataNascimento): void {
    if (dataNascimento) {
      var timeDiff = Math.abs(Date.now() - new Date(dataNascimento).getTime());
      this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    }
  }

  editDataFirst(): void {
    if (this.editDataFirstBool == false) {
      this.editDataFirstBool = true;
    } else {
      this.editDataFirstBool = false;
    }
  }

  editDataSecond(): void {
    if (this.editDataSecondBool == false) {
      this.editDataSecondBool = true;
    } else {
      this.editDataSecondBool = false;
    }
  }

  editDataThird(): void {
    if (this.editDataThirdBool == false) {
      this.editDataThirdBool = true;
    } else {
      this.editDataThirdBool = false;
    }
  }

  editDataFirstPatch(): void {
    if (this.validateInputsFirstSection() === false) {
      if (this.userPersonalDataForm.valid) {
        this.userDataForPatch.patchUserData({
          codigoFirebase: this.localStorageService.fireToken,
          assinante: {
            nome: this.userPersonalDataForm.controls['nomeCompleto']?.value,
            cpf: this.userPersonalDataForm.controls['cpf']?.value,
            rg: this.userPersonalDataForm.controls['rg']?.value,
            dataNascimento: new Date(
              this.userPersonalDataForm.controls['dataNascimento'].value
                ?.split('/')
                .reverse()
                .join('/')
            ).toISOString(),
            estadoCivil:
              this.userPersonalDataForm.controls['estadoCivil']?.value,
            profissao: this.userPersonalDataForm.controls['profissao']?.value,
            renda: parseInt(this.userPersonalDataForm.controls['renda']?.value),
            anexos: {
              comprovanteRenda: {
                base64: this.proofIncomeFile.base64
                  ? this.proofIncomeFile.base64
                  : this.userAnexos?.comprovanteRenda?.base64 ?? "",
                extensao: this.proofIncomeFile.name?.split('.')[
                  this.proofIncomeFile.name?.split('.')?.length - 1
                ]
                  ? this.proofIncomeFile.name.split('.')[
                      this.proofIncomeFile.name?.split('.')?.length - 1
                    ]
                  : this.userAnexos?.comprovanteRenda?.extensao ?? "",
              },
            },
            telefone: this.userPersonalDataForm.controls['telefone']?.value,
          },
        });
        var elmntToView = document.getElementById('changeProgramatic');
        setTimeout(() => {
          this.editDataFirstBool = false;
          this.getUserData('first');
        }, 1000);
      } 
    } else {
      this._snackBar.open('Preencha todos os campos', '', {
        duration: 3000,
      });
    }
  }

  editDataSecondPatch(): void {
    if (this.validateInputsSecondSection() === false) {
      if (this.userAddressForm.valid) {
        const anexos = {
          comprovanteResidencia: {
            base64: "",
            extensao: ""
          }
        };
        
        if (
          this.proofHomeAddressFile.base64 != undefined || 
          this.userAnexos.comprovanteResidencia.base64 != undefined
        ) {
          anexos.comprovanteResidencia = {
            base64: this.proofHomeAddressFile.base64
              ? this.proofHomeAddressFile.base64
              : this.userAnexos?.comprovanteResidencia?.base64,
            extensao: this.proofHomeAddressFile.name?.split('.')[
              this.proofHomeAddressFile?.name?.split('.')?.length - 1
            ]
              ? this.proofHomeAddressFile.name?.split('.')[
                  this.proofHomeAddressFile.name?.split('.')?.length - 1
                ]
              : this.userAnexos?.comprovanteResidencia?.extensao,
          }
        }

        this.userDataForPatch.patchUserData({
          codigoFirebase: this.localStorageService.fireToken,
          assinante: {
            cep: this.userAddressForm.controls['cep']?.value,
            endereco: this.userAddressForm.controls['logradouro']?.value,
            complemento: this.userAddressForm.controls['complemento']?.value,
            cidade: this.userAddressForm.controls['cidade']?.value,
            numero: parseInt(this.userAddressForm.controls['numero']?.value),
            bairro: this.userAddressForm.controls['bairro']?.value,
            uf: this.userAddressForm.controls['estado']?.value,
            anexos,
          },
        });
        setTimeout(() => {
          this.editDataSecondBool = false;
          this.getUserData('second');
        }, 1000);
      } 
    } else {
      this._snackBar.open('Verifique todos os campos', '', {
        duration: 3000,
      });
    }
  }

  editDataThirdPatch(): void {
    if (this.validateInputsThirdSection() === false) {
      if (
        this.userCnhForm.valid &&
        (this.userAnexos?.cnh?.base64 === null
          ? this.cnhFile.base64 !== null
          : this.userAnexos?.cnh?.base64 !== null)
      ) {
        this.userDataForPatch.patchUserData({
          codigoFirebase: this.localStorageService.fireToken,
          assinante: {
            cnh: {
              numero: this.userCnhForm.controls['numero'].value,
              validade: new Date(
                this.userCnhForm.controls['validade'].value
                  ?.split('/')
                  .reverse()
                  .join('/')
              ).toISOString(),
              primeiraData: new Date(
                this.userCnhForm.controls['dataPrimeira'].value
                  ?.split('/')
                  .reverse()
                  .join('/')
              ).toISOString(),
              categoria: this.userCnhForm.controls['categoria'].value,
            },
            anexos: {
              cnh: {
                base64: this.cnhFile.base64
                  ? this.cnhFile.base64
                  : this.userAnexos?.cnh?.base64,
                extensao: this.cnhFile.name?.split('.')[
                  this.cnhFile?.name?.split('.')?.length - 1
                ]
                  ? this.cnhFile.name?.split('.')[
                      this.cnhFile?.name?.split('.')?.length - 1
                    ]
                  : this.userAnexos?.cnh?.extensao,
              },
            },
          },
        });
        setTimeout(() => {
          this.editDataThirdBool = false;
          this.getUserData('third');
        }, 1000);
      } 
    } else {
      this._snackBar.open('Verifique todos os campos', '', {
        duration: 3000,
      });
    }
  }

  async changeProfilePicture(fileName, fileBase64) {
    if (fileName && fileBase64) {
      await this.userDataForPatch.patchUserData({
        codigoFirebase: this.localStorageService.fireToken,
        assinante: {
          anexos: {
            imagem: {
              base64: fileBase64,
              extensao: fileName?.split('.')[fileName?.split('.')?.length - 1],
            },
          },
        },
      });
      setTimeout(() => {
        this.getUserData();
      }, 3000);
    }
  }

  editCardData(): void {
    this.editCardDataBool = true;
  }

  changed(event) {
    this.checked = event.checked;
  }

  postCardData(): void {
    if (this.userPaymentDataEnderecoForm.valid) {
      this.userCardDataForPost.postUserCardData({
        codigoFirebase: this.localStorageService.fireToken,
        cep: this.userPaymentDataEnderecoForm.controls['cep']?.value,
        logradouro:
          this.userPaymentDataEnderecoForm.controls['logradouro']?.value,
        complemento:
          this.userPaymentDataEnderecoForm.controls['complemento']?.value,
        cidade: this.userPaymentDataEnderecoForm.controls['cidade']?.value,
        numero: parseInt(
          this.userPaymentDataEnderecoForm.controls['numero']?.value
        ),
        bairro: this.userPaymentDataEnderecoForm.controls['bairro']?.value,
      });
      let bodyData = {
        codigoFirebase: this.localStorageService.fireToken,
        assinante: {
          numeroCartao:
            this.userPaymentDataCardForm.controls['numeroCartao'].value,
          validadeCartao:
            this.userPaymentDataCardForm.controls['validadeCartao'].value,
        },
      };
      this.userDataForPatch.patchUserData(bodyData);
      this.selectRadioButton(0);
      setTimeout(() => {
        this.editCardAddressBool = false;
        this.getUserData('second');
        this.getUserPaymentData(this.localStorageService.fireToken);
      }, 4000);
      this.editCardDataBool = false;
    } else {
      this._snackBar.open('Verifique todos os campos', '', { duration: 3000 });
    }
  }

  patchCardData(): void {
    if (
      this.userPaymentDataEnderecoForm.valid &&
      this.userPaymentDataCardForm.valid
    ) {
      let bodyCard = {
        codigoFirebase: this.localStorageService.fireToken,
        codigoEndereco: this.codigoEnderecoFiltered
          ? this.codigoEnderecoFiltered
          : this.userPaymentDataEndereco[0]?.codigoEndereco,
        cep: this.userPaymentDataEnderecoForm.controls['cep']?.value,
        logradouro:
          this.userPaymentDataEnderecoForm.controls['logradouro']?.value,
        complemento:
          this.userPaymentDataEnderecoForm.controls['complemento']?.value,
        cidade: this.userPaymentDataEnderecoForm.controls['cidade']?.value,
        numero: parseInt(
          this.userPaymentDataEnderecoForm.controls['numero']?.value
        ),
        bairro: this.userPaymentDataEnderecoForm.controls['bairro']?.value,
        enderecoPrincipal: this.checked === true ? 'S' : 'N',
      };
      let bodyData = {
        codigoFirebase: this.localStorageService.fireToken,
        assinante: {
          numeroCartao:
            this.userPaymentDataCardForm.controls['numeroCartao'].value,
          validadeCartao:
            this.userPaymentDataCardForm.controls['validadeCartao'].value,
        },
      };
      this.userCardDataForPatch.patchUserCardData(bodyCard);
      this.userDataForPatch.patchUserData(bodyData);
      setTimeout(() => {
        this.getUserData('second');
        this.getUserPaymentData(this.localStorageService.fireToken);
      }, 4000);
      this.editCardDataBool = false;
    } else {
      this._snackBar.open('Verifique todos os campos', '', { duration: 3000 });
    }
  }

  deleteCardData(): void {
    this.userDataForPatch.patchUserData({
      codigoFirebase: this.localStorageService.fireToken,
      assinante: {
        numeroCartao: '',
        validadeCartao: '',
      },
    });
    this.userPaymentDataEndereco.forEach((i: any) => {
      this.userCardDataForPatch.patchUserCardData({
        codigoFirebase: this.localStorageService.fireToken,
        codigoEndereco: i.codigoEndereco,
        cep: '',
        logradouro: '',
        complemento: '',
        cidade: '',
        numero: 0,
        bairro: '',
      });
    });
    this.checked = false;
    this.editCardDataBool = false;
    setTimeout(() => {
      this.getUserData();
      this.getUserPaymentData(this.localStorageService.fireToken);
    }, 400);
  }

  processFile(imageInput: any): void {
    if (imageInput) {
      const file = imageInput?.target?.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = await reader.result.toString()?.split('base64,').pop();
        this.profilePicture.base64 = base64 ?? '';
        this.profilePicture.name = file.name;
        this.changeProfilePicture(file.name, base64);
      };
    }
  }

  public onFileSelected(event: any, type: string): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result.toString()?.split('base64,').pop();
      if (type === 'PROOF_HOME_ADDRESS_FILE') {
        this.proofHomeAddressFile.base64 = base64;
        this.proofHomeAddressFile.name = file.name;
        this.userAddressForm.controls['proofHomeAddress'].setValue(file.name);
      } else if (type === 'CNH_FILE') {
        this.cnhFile.base64 = base64;
        this.cnhFile.name = file.name;
        this.userCnhForm.controls['cnhFile'].setValue(file.name);
      } else if (type === 'PROOF_INCOME_FILE') {
        this.proofIncomeFile.base64 = base64;
        this.proofIncomeFile.name = file.name;
        this.userPersonalDataForm.controls['proofIncomeFile'].setValue(
          file.name
        );
      }
    };
  }

  scrollTo() {
    var elmntToView = document.getElementById('top');
    elmntToView.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }

  public signOut(): void {
    this.firestoreAuthService.SignOut();
    this.socialAuthService.signOut();
    this.afAuth.signOut();
    let codigoIdentificador = this.localStorageService.codigoIdentificador;
    this.localStorageService.clear();
    this.localStorageService.codigoIdentificador = codigoIdentificador;
    this.router.navigateByUrl('/root/signature');
  }
}
