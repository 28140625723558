import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardComponent } from '../views/dashboard/dashboard.component';

@Injectable({
    providedIn: 'root',
})
export class UserDataForPatch {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private _snackBar: MatSnackBar,
    ) { }

    async patchUserData(body) {
        let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
        this.http.patch<any>(`${environment.api.base_url}/assinantes`, body,
            {
                params: params,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    "X-API-Key": `${this.localStorageService.token}`,
                })
            }).subscribe(i => {
                this._snackBar.open(i.data.message[0].toUpperCase() + i.data.message.toLowerCase().slice(1), '', { duration: 3000 });
            }
            );
    }
}
