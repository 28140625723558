import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import IGroup from 'src/app/interfaces/group.interface';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  headers: HttpHeaders

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {
    this.headers = new HttpHeaders().set('X-API-Key', this.localStorageService.token);
  }

  getAll(): any {
    let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador)
      .set("codigoEmpresa", this.localStorageService.codigoEmpresa)
      .set("codigoUnidade", this.localStorageService.codigoUnidade);
    return this.http.get<[IGroup]>(`${environment.api.base_url}/modelos/grupos`, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-API-Key": `${this.localStorageService.token}`,
      })
    });
  }
}
