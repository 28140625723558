<!-- Primeira etapa do registro -->
<div style="height: 60px">
  <app-header></app-header>
</div>
<div
  id="div"
  *ngIf="step === 0"
  fxLayout="row wrap"
  fxLayout.xs="column wrap"
  fxLayoutAlign="flex-start"
>
  <mat-card
    class="mat-elevation-z0"
    id="titleContainer"
    fxFlex="60"
    fxLayout="column"
  >
    <mat-card-subtitle id="mat-card-subtitle-big">
      A primeira parte já foi! Agora é só criar uma conta, assim você poderá
      acompanhar sua assinatura em qualquer momento, em qualquer lugar.
    </mat-card-subtitle>
    <br /><br />
    <mat-card
      class="mat-elevation-z0"
      id="socialLogin"
      (click)="signInWithGoogle()"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div fxLayout="row" fxFlexAlign="center">
        <img
          src="../../../../assets/google.png"
          alt="google"
          height="30px"
          width="30px"
        />
        <span style="padding-left: 10px" fxFlexAlign="center"
          >Entrar com o Gmail</span
        >
      </div>
      <!-- <div class="card-block">
                <button class="btn btn-danger" (click)="signOut()">Sign out</button>
            </div>
            <button type="button" (click)="authService.GoogleAuth()">
                Log in with Google
              </button> -->
      <mat-icon id="mat-icon-small" color="black" fxFlexAlign="center"
        >keyboard_arrow_right</mat-icon
      >
    </mat-card>
  </mat-card>
  <mat-card
    class="mat-elevation-z0"
    id="formContainer"
    fxLayout="row"
    fxFlex="30"
  >
    <div>
      <form [formGroup]="form" fxLayout="column">
        <mat-form-field dense appearance="outline">
          <mat-label>Nome completo</mat-label>
          <input matInput type="text" formControlName="name" />
        </mat-form-field>
        <mat-error
          *ngIf="form.controls['name'].hasError('pattern')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Insira seu nome completo
          </p>
        </mat-error>

        <mat-form-field dense appearance="outline">
          <mat-label>Data de nascimento</mat-label>
          <input
            #birthDate
            id="hiddenInput"
            (focus)="maskedInput.focus()"
            matInput
            [matDatepicker]="picker"
            max="{{ minAge | date : 'yyyy-MM-dd' }}"
            (input)="dateValidation()"
            formControlName="birthDate1"
          />
          <input
            #maskedInput
            [ngClass]="{ empty: maskedInput.value == '__/__/____' }"
            [(ngModel)]="birthDate.value"
            (input)="dispatch(birthDate)"
            class="mat-input-element mat-datepicker-input"
            [dropSpecialCharacters]="false"
            [showMaskTyped]="true"
            mask="00/00/0000"
            formControlName="birthDate"
            (change)="dateValidation()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="form.controls['birthDate1'].hasError('isNotAdult')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Você deve possuir maioridade
          </p>
        </mat-error>

        <mat-form-field dense appearance="outline">
          <mat-label>E-mail</mat-label>
          <input matInput type="text" formControlName="email" />
        </mat-form-field>
        <mat-error
          *ngIf="form.controls['email'].hasError('email')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Insira um e-mail válido
          </p>
        </mat-error>
        <mat-form-field dense appearance="outline">
          <mat-label>CPF</mat-label>
          <input
            matInput
            type="text"
            formControlName="cpf"
            mask="000.000.000-00"
            (input)="validCPF(form.controls['cpf'].value)"
          />
        </mat-form-field>
        <mat-error
          *ngIf="form.controls['cpf'].hasError('incorrect')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Insira um CPF válido
          </p>
        </mat-error>
        <mat-error
          *ngIf="form.controls['cpf'].hasError('isNotUnique')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Esse CPF já está cadastrado
          </p>
        </mat-error>
        <mat-form-field dense appearance="outline">
          <mat-label>Telefone</mat-label>
          <input
            matInput
            type="text"
            formControlName="phone"
            [mask]="phoneMask"
          />
        </mat-form-field>
        <mat-error
          *ngIf="form.controls['phone'].hasError('minlength')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Insira um telefone válido
          </p>
        </mat-error>
        <!-- <mat-hint style="color: red" *ngIf="form.get('email').touched && form.get('email').errors?.required">
                    O email está incorreto
                </mat-hint> -->
      </form>
    </div>
  </mat-card>
  <div fxLayoutAlign="center center">
    <a (click)="nextStep()">
      <mat-icon id="buttonNext" color="primary">keyboard_arrow_right</mat-icon>
    </a>
  </div>
  <div *ngIf="isMobile">
    <br />
    <br />
  </div>
</div>

<!-- Segunda etapa do registro -->

<div
  id="div"
  [hidden]="step !== 1"
  fxLayout="row wrap"
  fxLayout.xs="column wrap"
  fxLayoutAlign="flex-start"
>
  <mat-card
    class="mat-elevation-z0"
    id="titleContainer"
    fxFlex="60"
    fxLayout="column"
  >
    <mat-card-subtitle id="mat-card-subtitle-big">
      Crie uma senha, com ela você terá acesso a todas informações necessárias
      sobre seu carro.
    </mat-card-subtitle>
    <br /><br />
    <mat-card
      class="mat-elevation-z0"
      id="socialLogin"
      (click)="signInWithGoogle()"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <div fxLayout="row" fxFlexAlign="center">
        <img
          src="../../../../assets/google.png"
          alt="google"
          height="30px"
          width="30px"
        />
        <span style="padding-left: 10px" fxFlexAlign="center"
          >Entrar com o Gmail</span
        >
      </div>
      <mat-icon id="mat-icon-small" color="black" fxFlexAlign="center"
        >keyboard_arrow_right</mat-icon
      >
    </mat-card>
  </mat-card>
  <div *ngIf="step == 1 && isMobile == false" fxLayoutAlign="center center">
    <a (click)="backStep()">
      <mat-icon id="buttonNext" color="primary">keyboard_arrow_left</mat-icon>
    </a>
  </div>
  <mat-card
    class="mat-elevation-z0"
    id="formContainer"
    fxLayout="row"
    fxFlex="30"
  >
    <div>
      <form [formGroup]="secondForm" fxLayout="column">
        <mat-form-field dense appearance="outline">
          <mat-label>Senha</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
          />
          <mat-icon matSuffix (click)="viewPassword()" class="viewPassword">
            remove_red_eye</mat-icon
          >
        </mat-form-field>
        <mat-error
          *ngIf="secondForm.controls['password'].hasError('pattern')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Insira letras e números
          </p>
        </mat-error>
        <mat-form-field dense appearance="outline">
          <mat-label>Confirmar senha</mat-label>
          <input
            matInput
            [type]="hide2 ? 'password' : 'text'"
            formControlName="confirmPassword"
          />
          <mat-icon matSuffix (click)="viewPassword2()" class="viewPassword">
            remove_red_eye</mat-icon
          >
        </mat-form-field>
        <mat-error
          *ngIf="secondForm.controls['confirmPassword'].hasError('pattern')"
          style="margin-top: -18px; margin-bottom: -8px"
        >
          <p style="font-size: 12px" fxLayoutAlign="flex-start center">
            <mat-icon fxLayoutAlign="center center" style="font-size: 18px"
              >error</mat-icon
            >Insira letras e números
          </p>
        </mat-error>
      </form>
    </div>
  </mat-card>
  <div [fxLayoutAlign]="isMobile ? 'space-between' : 'center center'">
    <a *ngIf="isMobile" (click)="backStep()">
      <mat-icon id="buttonNext" color="primary">keyboard_arrow_left</mat-icon>
    </a>
    <a (click)="registerNewUser()">
      <mat-icon id="buttonNext" color="primary">keyboard_arrow_right</mat-icon>
    </a>
  </div>
  <div *ngIf="isMobile">
    <br />
    <br />
  </div>
</div>

<!-- Terceira etapa do registro -->
<!-- 
<div id="div" *ngIf="step === 2" fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutAlign="flex-start">
    <mat-card class="mat-elevation-z0" id="titleContainer" fxFlex="60" fxLayout="column">
        <mat-card-subtitle id="mat-card-subtitle-big">
            Para sua segurança, autentifique sua conta. Enviamos um código por SMS.
        </mat-card-subtitle>
        <br><br>
        <mat-card class="mat-elevation-z0" id="socialLogin" (click)="signInWithFacebook()" fxLayout="row"
            fxLayoutAlign="space-between">
            <div fxLayout="row" fxFlexAlign="center">
                <img src="../../../../assets/facebook.png" alt="facebook" height="30px" width="30px">
                <span style="padding-left: 10px;" fxFlexAlign="center">Entrar com
                    o Facebook</span>
            </div>
            <mat-icon id="mat-icon-small" color="black" fxFlexAlign="center">keyboard_arrow_right</mat-icon>
        </mat-card>
        <mat-card class="mat-elevation-z0" id="socialLogin" (click)="signInWithGoogle()" fxLayout="row"
            fxLayoutAlign="space-between">
            <div fxLayout="row" fxFlexAlign="center">
                <img src="../../../../assets/google.png" alt="google" height="30px" width="30px">
                <span style="padding-left: 10px;" fxFlexAlign="center">Entrar com
                    o Gmail</span>
            </div>
            <mat-icon id="mat-icon-small" color="black" fxFlexAlign="center">keyboard_arrow_right</mat-icon>
        </mat-card>
    </mat-card>
    <mat-card class="mat-elevation-z0" id="formContainer" fxLayout="column" fxLayoutAlign="center center" fxFlex="30">
        <form [formGroup]="thirdForm" fxLayout="column">
            <mat-form-field dense appearance="outline">
                <mat-label>Código</mat-label>
                <input matInput [type]="hide3 ? 'password' : 'text'" formControlName="pinCode" />
                <mat-icon matSuffix (click)="viewPassword3()" class="viewPassword"> remove_red_eye</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="isValidPin === false" style="margin-top: -18px; margin-bottom: -8px;">
                <p style="font-size: 12px; " fxLayoutAlign="flex-start center">
                    <mat-icon fxLayoutAlign="center center" style="font-size: 18px">error</mat-icon>Verifique o código e
                    tente novamente
                </p>
            </mat-error>
        </form>
        <div *ngIf="count !== 0" style="color: rgba(0, 0, 0, 0.6);"> {{ count }} segundos para reenviar o código </div>
        <div *ngIf="count === 0">
            <div *ngIf="sent2 === false">
                <div id="recaptcha-container2"></div>
            </div>
            <div *ngIf="sent2 === false && hideResentButton === false" id="resentSMS"
                (click)="this.onSubmitAgain(this.form.controls['phone'].value)">
                Reenviar código </div>
        </div> -->
<!-- <div class="row align-items-center">
            <div>
                <p class="lead">
                    Complete form below to verify phone number
                </p>
                <hr class="my-4">
                <div id="recaptcha-container"></div>
                <form class="" #formData='ngForm' (ngSubmit)="onSubmit(formData.value)">
                    <div class="uk-margin">
                        <input type="number" name="phone_number" class="uk-input uk-form-large" [ngModel]="phone_number"
                            placeholder="e.g +2330269201707" required autofocus>
                    </div>
                    <button type="submit" class="uk-button uk-button-primary" [disabled]="formData.invalid || busy">
                        <span *ngIf="busy">Hold on a second</span>
                        <span *ngIf="!busy">Continue</span>
                    </button>
                    <a [routerLink]="['/']" class="uk-button uk-button-default uk-button-small">Cancel</a>
                </form>
            </div>
        </div> -->
<!-- </mat-card>
    <div fxLayoutAlign="center center">
        <a (click)="verifyLoginCode()">
            <mat-icon id="buttonNext" color="primary">keyboard_arrow_right</mat-icon>
        </a>
    </div>
</div> -->
<br />
<div id="footer">
  <!-- <div fxLayout="row" fxFlexAlign="center center">
        <span style="cursor: pointer;
        cursor: pointer;
        font-weight: bold;
        font-size: 11px;" matTooltip="Abrir termo" matTooltipPosition="left" (click)="openTermGeneralCondition()">
            Ver Condições Gerais de Locação
        </span>
    </div>
    <div>
        <mat-checkbox style="font-size: 11px; font-weight: bold;" [(ngModel)]="checkTerm">
            Estou ciente e de acordo com as condições apresentadas na minha solicitação de locação.
        </mat-checkbox>
    </div>
    <br> -->
  <div fxLayoutAlign="center center">
    <div style="position: fixed; bottom: 0" fxLayout="row">
      <app-powered-by></app-powered-by>
    </div>
  </div>
</div>
