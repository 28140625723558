import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/development.env';
import { LocalStorageService } from './localservice.service';

interface INewLead {
  nome: string,
  email: string,
  cpf: string,
  telefone: string,
  marca: string,
  modelo: string,
  versao: string,
  codigoEmpresa: number,
  codigoUnidade: number,
}

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  headers: HttpHeaders

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) { }

  post(body: INewLead): Observable<any> {
    let params = new HttpParams().set("codigoIdentificador", this.localStorageService.codigoIdentificador);
    return this.http.post(`${environment.api.base_url}/leads`, body, {
      params: params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-API-Key": `${this.localStorageService.token}`,
      })
    });
  }
}
