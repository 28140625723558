<div style="height: 60px" id="top">
  <app-header></app-header>
</div>
<div>
  <div id="div" fxLayout="column" fxLayoutAlign="center center">
    <form [formGroup]="form">
      <mat-card-subtitle id="title-big" style="padding-bottom: 20px;">
        Seu carro te espera
      </mat-card-subtitle>
      <mat-card style="margin-left: 12.5px; margin-right: 12.5px;">
        <div fxLayout="row wrap" fxLayout.xs="column wrap">
          <mat-card class="mat-elevation-z0" fxFlex="50" fxLayoutAlign="space-between">
            <div fxLayout="column">
              <mat-card-subtitle style="font-size: 24px; line-height: 28px;">
                {{vehicle?.model}}
              </mat-card-subtitle>
              <!-- <mat-card-subtitle>
                {{vehicle?.version}}
              </mat-card-subtitle> -->
              <div fxFlex="50" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div style="padding-bottom: 10px;">
                    <span>Custo Mensal:</span>
                    <p>
                      <span id="vehiclePrice">{{ ((priceVehicle + diversosPrice) | currency)?.replace("R$", "")
                        }}</span>
                    </p>
                  </div>
                  <mat-icon style="cursor: context-menu" matTooltip="{{vehicle.descricaoTarifaAssinatura}}">info
                  </mat-icon>
                </div>
                <mat-form-field appearance="legacy">
                  <mat-label>Duração do Contrato</mat-label>
                  <mat-select formControlName="contractDuration">
                    <mat-option *ngFor="let duration of contractDurationMonths" [value]="duration">{{duration}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="legacy">
                  <mat-label>Franquia de KM Mensal</mat-label>
                  <mat-select formControlName="monthKmFranchise">
                    <mat-option *ngFor="let franchise of franchises" [value]="franchise">{{franchise.descricaoTarifa}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <p>
                  <mat-card-subtitle>
                    Cor
                  </mat-card-subtitle>
                  <mat-button-toggle-group #color formControlName="color">
                    <div *ngFor="let item of vehicle?.colors" class="mat-colors-container">
                      <mat-button-toggle 
                        id="selectColor"                         
                        [ngStyle]="{'background-color': '#'+item.hex}" 
                        [value]="item"
                      ></mat-button-toggle>
                      <span>{{ item?.name }}</span>
                    </div>
                  </mat-button-toggle-group>

                  <mat-error *ngIf="!form.controls['color'].valid && form.controls['color'].touched">
                    Selecione uma cor
                  </mat-error>
                </p>

                <mat-card class="mat-elevation-z0"
                  style="border: black 1px solid; margin-bottom: 20px; border-radius: 12px; max-width: 100vw;">
                  <p *ngIf="vehicle?.protections?.length > 0">Proteções: {{vehicle?.protections?.join(', ')}}</p>
                  <!-- <p *ngIf="vehicle?.optionals?.length > 0">Opcionais: {{vehicle?.optionals?.join(', ')}}</p> -->
                  <p *ngIf="vehicle?.serialItems?.length > 0">Séries: {{vehicle?.serialItems?.join(', ')}}</p>
                </mat-card>
                <h3 class="addDiversos" (click)="addDiversosFunction()" *ngIf="vehicle?.diversos?.length > 0">
                  Serviços adicionais
                </h3>

              </div>
            </div>
          </mat-card>


          <mat-card class="mat-elevation-z0" fxLayout="column" fxFlex="50" fxLayout.xs="column wrap"
            fxLayoutAlign="flex-end center">
            <div>
              <img class="carImg" fxLayoutAlign="flex-end center" [src]="vehicle?.picture" [alt]="vehicle?.model">
            </div>
            <mat-card-actions fxLayoutAlign="flex-end flex-end">
              <button fxLayoutAlign="flex-end flex-end" mat-raised-button color="primary" id="buttonCarSelected"
                (click)="continue()">
                <span>
                  Quero esse carro
                </span>
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-card>

      <br>

      <mat-card *ngIf="addDiversos" style="margin-left: 12.5px; margin-right: 12.5px; padding: 30px">
        <h3>Selecione as opções que lhe agradam</h3>
        <div fxLayout="row">
          <div fxLayout="column" fxFlex="50">
            <p>Adicionado à tarifa</p>
            <span>
              <div *ngFor="let item of vehicleDiversosPorDiaria; let ind = index">
                <mat-checkbox (change)="addDiversoInObject(item, ind)">
                  {{item.descricao}}
                </mat-checkbox>
              </div>
            </span>
          </div>
          <div fxLayout="column" fxFlex="50">
            <p>Taxa única (Junto a primeira mensalidade)</p>
            <span>
              <div *ngFor="let item of vehicleDiversosTaxaUnica; let ind = index">
                <mat-checkbox (change)="addDiversoInObject(item, ind)">
                  {{item.descricao}}
                </mat-checkbox>
              </div>
            </span>
          </div>
        </div>
        <br>
        <p>Valor: {{ (diversosPrice | currency) }}</p>
      </mat-card>

      <br>

      <mat-card-subtitle id="title-big" style="padding-top: 80px;">
        Você também vai gostar dessas opções
      </mat-card-subtitle>
    </form>

    <br>

  </div>
  <div id="vehiclesContainer" fxLayout="row wrap" fxLayoutGap="20px" fxLayout.xs="column wrap">
    <mat-card fxFlex="20" class="card" *ngFor="let vehicle of vehiclesFiltered" (click)="choiceVehicle(vehicle)">
      <mat-card-header id="cardTitle">
        <mat-card-title>{{ vehicle.model }}</mat-card-title>
        <mat-card-subtitle>{{vehicle.version}}</mat-card-subtitle>
      </mat-card-header>

      <img mat-card-image [src]="vehicle.picture" [alt]="vehicle.model" />

      <mat-card-actions fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="primary" id="button" (click)="choiceVehicle(vehicle)">
          <span>
            <strong style="font-size: 18px;">
              {{ vehicle.price | currency: "BRL" }}</strong>
            <span style="font-size: 11px;">
              /mês
            </span>
          </span>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div id="div">
    <div fxLayout="row" fxLayoutAlign="center center">
      <app-powered-by></app-powered-by>
    </div>
    <br>
  </div>
</div>