function cpf(cpf: string) {
  if (!cpf) return ''
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
}

function rg(rg: string) {
  if (!rg) return ''
  return rg.length > 8
    ? rg.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/g,"\$1.\$2.\$3\-\$4")
      : rg.replace(/(\d{2})(\d{3})(\d{3})/g,"\$1.\$2.\$3")
}

function phoneNumber(phoneNumber: string) {
  if (!phoneNumber) return ''
  return phoneNumber.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g,"(\$1) \$2 \$3\-\$4");
}

function cep(cep: string) {
  if (!cep) return ''
  return cep.replace(/(\d{5})(\d{3})/g,"\$1\-\$2");
}

export const Mask = {
  cpf,
  rg,
  phoneNumber,
  cep
}